import {
  createFollowStart,
  createFollowSuccess,
  createFollowFailure,
  deleteFollowStart,
  deleteFollowSuccess,
  deleteFollowFailure,
} from "../reducers/followReducer";
import { fetchUser } from "./userActions";
import Cookies from "js-cookie";
import axios from "axios";

// Create like
export const createFollow = (followData) => (dispatch) => {
  // Dispatch the start action
  dispatch(createFollowStart());

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // API call
  axios
    .post(`${process.env.REACT_APP_API_HOSTNAME}/api/follows`, followData, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
    .then((response) => {
      // Dispatch the success action
      dispatch(createFollowSuccess(response.data.data));
      // Fetch user data to sync the user likes
      dispatch(fetchUser());
    })
    .catch((error) => {
      // Dispatch the error action
      dispatch(createFollowFailure(error.message));
    });
};

// Delete like
export const deleteFollow = (followId) => (dispatch) => {
  // Dispatch the start action
  dispatch(deleteFollowStart());

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // API call
  axios
    .delete(`${process.env.REACT_APP_API_HOSTNAME}/api/follows/${followId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
    .then((response) => {
      // Dispatch the success action
      dispatch(deleteFollowSuccess(response.data.data));
      // Fetch user data to sync the user likes
      dispatch(fetchUser());
    })
    .catch((error) => {
      // Dispatch the error action
      dispatch(deleteFollowFailure(error.message));
    });
};
