// React
import React from "react";
// State
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Cookies from "js-cookie";
// Libraries
import { Helmet } from "react-helmet";
// UI
import { Container, Grid, Card, CardContent, List, ListItem, ListItemText, Divider, Typography, Link } from "@mui/material";

export default function Sitemap() {
  // Get user token
  const userToken = Cookies.get("session-cookie");

  // Get user state
  const user = useSelector((state) => state.user);
  return (
    <>
      <Helmet>
        <title>{`Sitemap - Dishly`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Use the sitemap to find your way around Dishly." />
        <meta property="og:title" content="Dishly • Sitemap" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Use the sitemap to find your way around Dishly." />
      </Helmet>
      <Container maxWidth="xs" sx={{ flex: 1, marginY: 4 }}>
        <Typography variant="h4" marginBottom={4}>
          Sitemap
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent sx={{ p: 0 }}>
                <List>
                  <ListItem sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                    <ListItemText primary={<Typography variant="h6">Account</Typography>} />
                    <ListItemText
                      primary={
                        <>
                          <Link component={RouterLink} to="/account">
                            My Account
                          </Link>
                        </>
                      }
                    />

                    <ListItemText
                      primary={
                        <>
                          <Link component={RouterLink} to="/security">
                            Security
                          </Link>
                        </>
                      }
                    />
                    <ListItemText
                      primary={
                        <>
                          <Link component={RouterLink} to="/saved">
                            Saved Items
                          </Link>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider />
                  <ListItem sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                    <ListItemText primary={<Typography variant="h6">Profile</Typography>} />
                    {userToken && (
                      <ListItemText
                        primary={
                          <>
                            <Link component={RouterLink} to={`/user/${user.data.username}`}>
                              My Profile
                            </Link>
                          </>
                        }
                      />
                    )}
                    <ListItemText
                      primary={
                        <>
                          <Link component={RouterLink} to="/notifications">
                            Notifications
                          </Link>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider />
                  <ListItem sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                    <ListItemText primary={<Typography variant="h6">Recipes</Typography>} />
                    <ListItemText
                      primary={
                        <>
                          <Link component={RouterLink} to="/recipes">
                            All recipes
                          </Link>
                        </>
                      }
                    />
                    <ListItemText
                      primary={
                        <>
                          <Link component={RouterLink} to="/edit-recipe">
                            Create Recipe
                          </Link>
                        </>
                      }
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
