// React
import React, { useEffect } from "react";
// State
import { useSelector, useDispatch } from "react-redux";
import { fetchUser } from "../../actions/userActions";
import { Link as RouterLink } from "react-router-dom";
import Cookies from "js-cookie";
// Libraries
import { Helmet } from "react-helmet";
// Components
import Filters from "./Filters";
// UI
import { Box, LinearProgress, Grid, Container, Card, CardActionArea, CardContent, Typography, Dialog, DialogTitle, DialogContent, IconButton, Link } from "@mui/material";
import SlideUp from "../ui/Transitions";
import TuneIcon from "@mui/icons-material/Tune";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined";
import CloseIcon from "@mui/icons-material/Close";

export default function Account() {
  // Set dispathch
  const dispatch = useDispatch();

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // Authorization
  useEffect(() => {
    if (!userToken) {
      // Redirect to "/signin"
      window.location.href = "/signin";
    }
  }, [userToken]);

  // Get user data
  const user = useSelector((state) => state.user);

  // Filters
  const [openFilters, setOpenFilters] = React.useState(false);
  const handleOpenFilters = () => {
    setOpenFilters(true);
  };
  const closeFilters = () => {
    setOpenFilters(false);
  };

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  // Progress bar
  if (!user) {
    return (
      <Box sx={{ flex: 1 }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`Account - Dishly`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Manage your Dishly account" />
        <meta property="og:title" content="Dishly • Account" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Manage your Dishly account" />
      </Helmet>
      {user && (
        <Container maxWidth="lg" sx={{ flex: 1, marginY: 4 }}>
          <Typography variant="h4">Account</Typography>
          <Link component={RouterLink} to={`/user/${user.data.username}`}>
            <Typography variant="h6" marginBottom={4}>
              @{user.data.username}
            </Typography>
          </Link>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Card variant="outlined" sx={{ height: "100%" }}>
                <CardActionArea onClick={handleOpenFilters} sx={{ height: "100%" }}>
                  <CardContent>
                    <TuneIcon sx={{ marginBottom: 2 }} />
                    <Typography variant="h6">Food Filters</Typography>
                    <Typography variant="body2" sx={{ color: "#616161" }}>
                      Update your food allergies, dietary requirements or favorite cuisines.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card variant="outlined" sx={{ height: "100%" }}>
                <CardActionArea
                  component={RouterLink}
                  to="/saved"
                  sx={{
                    textDecoration: "none",
                    color: "initial",
                  }}>
                  <CardContent>
                    <BookmarkBorderOutlinedIcon sx={{ marginBottom: 2 }} />
                    <Typography variant="h6">Saved Items</Typography>
                    <Typography variant="body2" sx={{ color: "#616161" }}>
                      Access your saved items in one click - explore your collection now!
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card variant="outlined" sx={{ height: "100%" }}>
                <CardActionArea
                  component={RouterLink}
                  to="/security"
                  sx={{
                    textDecoration: "none",
                    color: "initial",
                  }}>
                  <CardContent>
                    <ShieldOutlinedIcon sx={{ marginBottom: 2 }} />
                    <Typography variant="h6">Security</Typography>
                    <Typography variant="body2" sx={{ color: "#616161" }}>
                      Manage your account security, update your email and password.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
          <Dialog open={openFilters} TransitionComponent={SlideUp} keepMounted onClose={closeFilters} aria-describedby="alert-dialog-food-filters">
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}>
              Food Filters
              <IconButton aria-label="close filters" onClick={closeFilters}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Filters closeFilters={closeFilters} />
            </DialogContent>
          </Dialog>
        </Container>
      )}
    </>
  );
}
