import { createSlice } from "@reduxjs/toolkit";

const allergiesSlice = createSlice({
  name: "allergies",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    fetchAllergiesStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchAllergiesSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.id = action.payload.id;
    },
    fetchAllergiesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchAllergiesStart, fetchAllergiesSuccess, fetchAllergiesFailure } = allergiesSlice.actions;

export default allergiesSlice.reducer;
