import React, { useEffect, useState } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { fetchNotifications } from "../../actions/notificationsActions";
// Libraries
import io from "socket.io-client";
// UI
import { IconButton, Avatar, Chip } from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { teal, red } from "@mui/material/colors";

export default function NotificationsButton() {
  // Set dispatch
  const dispatch = useDispatch();

  // Get user state
  const user = useSelector((state) => state.user);

  // Get notifications state
  const notifications = useSelector((state) => state.notifications);

  // Set newNotifications
  const [newNotifications, setNewNotifications] = useState([]);

  // Set notificationsLabel (set to "99+" if newNotifications is greater than 99)
  const notificationsLabel = newNotifications.length > 99 ? "99+" : newNotifications.length;

  // Fetch notifications when the screen loads
  useEffect(() => {
    const pagination = { start: 0, limit: 100 };
    dispatch(fetchNotifications(pagination));
  }, [dispatch]);

  // WebSocket - pushed notification events from Strapi
  useEffect(() => {
    // Define socket using backend (Strapi) hostname/url
    const socket = io(process.env.REACT_APP_API_HOSTNAME);
    // Listen to newNotification from backend server
    socket.on("syncNotifications", () => {
      // Update Redux store with the new notification
      const pagination = { start: 0, limit: 100 };
      dispatch(fetchNotifications(pagination));
    });
    // Disconnect when component is unmounted
    return () => {
      socket.disconnect();
    };
  }, [dispatch]);

  // Check for unread notifications
  useEffect(() => {
    if (user && user.data && user.data.notificationsTime && notifications && notifications.data) {
      const newNotificationsData = notifications.data.filter(
        (notification) => new Date(notification.attributes.createdAt) > new Date(user.data.notificationsTime)
      );
      setNewNotifications(newNotificationsData);
    }
  }, [user, notifications]);

  return (
    <div style={{ position: "relative" }}>
      <IconButton component={RouterLink} to="/notifications" aria-label="notifications" sx={{ marginLeft: 1 }}>
        <Avatar sx={{ width: 32, height: 32, background: teal[50] }}>
          <NotificationsOutlinedIcon color="primary" />
        </Avatar>
      </IconButton>
      {newNotifications.length > 0 && (
        <Chip
          label={notificationsLabel}
          size="small"
          color="secondary"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: red[500],
          }}
        />
      )}
    </div>
  );
}
