// React
import React, { useEffect, useState, useRef } from "react";
// State
import { useSelector, useDispatch } from "react-redux";
import { fetchRecipe } from "../../actions/recipeActions";
import { createLike, deleteLike } from "../../actions/likesActions";
import { postComments, deleteComment } from "../../actions/commentsActions";
import { postReply, deleteReply } from "../../actions/replyActions";
import { Link as RouterLink } from "react-router-dom";
// Libraries
import { formatDate } from "../../custom/utilities";
// UI
import {
  Avatar,
  Typography,
  Link,
  Button,
  IconButton,
  Box,
  Stack,
  Divider,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { Favorite, FavoriteBorder, Flag, MoreHoriz, ExpandMoreOutlined, ExpandLessOutlined } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export default function Comments() {
  // Set dispatch
  const dispatch = useDispatch();

  // User state
  const user = useSelector((state) => state.user);

  const userID = user?.data?.id;
  const likedComments = (user?.data.likes ?? []).filter((like) => like.comment);
  const [localLikedComments, setLocalLikedComments] = useState(likedComments || []);
  const likedReplies = (user?.data.likes ?? []).filter((like) => like.reply);
  const [localLikedReplies, setLocalLikedReplies] = useState(likedReplies || []);
  const recipe = useSelector((state) => state.recipe.data);
  const recipeID = recipe?.id;
  const comments = recipe?.attributes?.comments?.data;
  const replies = comments?.map((comment) => comment.attributes.replies?.data);
  const [commentText, setCommentText] = useState(""); // Add this state variable
  const [commentID, setCommentID] = useState(null); // Add this state variable

  // Comment more menu
  const [commentMenu, setCommentMenu] = useState(null);
  const openCommentMenu = Boolean(commentMenu);
  const [commentUserId, setCommentUserId] = useState(null);
  const [commentItemId, setCommentItemId] = useState(null);
  const handleCommentClick = (event, comment) => {
    setCommentMenu(event.currentTarget);
    setCommentUserId(comment?.attributes?.user?.data.id);
    setCommentItemId(comment?.id);
  };
  const closeCommentMenu = () => {
    setCommentMenu(null);
  };

  // Delete comment
  const removeComment = async () => {
    try {
      // Delete comment
      dispatch(deleteComment(commentItemId));
      // Delay updating the frontend.
      // Note: This needs a better approach, we can't rely on setTimeout
      setTimeout(() => {
        dispatch(fetchRecipe(recipe.attributes.slug));
      }, "500");
      // Reset menu
      setCommentMenu(null);
    } catch (error) {
      console.error("Error deleting comment:", error);
      alert("Error deleting comment. Please try again later.");
    }
  };

  // Reply more menu
  const [replyMenu, setReplyMenu] = useState(null);
  const openReplyMenu = Boolean(replyMenu);
  const [replyUserId, setReplyUserId] = useState(null);
  const [replyItemId, setReplyItemId] = useState(null);
  const handleReplyClick = (event, reply) => {
    setReplyMenu(event.currentTarget);
    setReplyUserId(reply?.attributes?.user?.data.id);
    setReplyItemId(reply?.id);
  };
  const closeReplyMenu = () => {
    setReplyMenu(null);
  };

  // Delete reply
  const removeReply = async () => {
    try {
      dispatch(deleteReply(replyItemId));
      // Delay updating the frontend.
      // Note: This needs a better approach, we can't rely on setTimeout
      setTimeout(() => {
        dispatch(fetchRecipe(recipe.attributes.slug));
      }, "500");
      // Reset menu
      setReplyMenu(null);
    } catch (error) {
      console.error("Error deleting reply:", error);
      alert("Error deleting reply. Please try again later.");
    }
  };

  const [replyLikesCount, setReplyLikesCount] = useState({});

  useEffect(() => {
    if (replies?.length > 0) {
      const initialReplyLikesCount = {};
      replies?.forEach((commentReplies) => {
        commentReplies?.forEach((reply) => {
          initialReplyLikesCount[reply.id] = reply.attributes?.likes?.data.length;
        });
      });
      setReplyLikesCount(initialReplyLikesCount);
    }
  }, [replies]);

  // Prevent multiple reply likes or unlikes
  const [isReplyLikeProcessing, setIsReplyLikeProcessing] = useState(false);

  const handleReplyLike = (reply) => {
    // Check if reply like function is already in process
    if (!isReplyLikeProcessing) {
      // Disable reply like function while one is already in process
      setIsReplyLikeProcessing(true);

      const currentlyLikedReply = isReplyLiked(reply);

      const updateReplyLikes = currentlyLikedReply
        ? localLikedReplies.filter((likedReply) => likedReply.reply.id !== reply.id)
        : [...localLikedReplies, reply];

      setLocalLikedReplies(updateReplyLikes);

      // Update back-end likes
      if (currentlyLikedReply) {
        // If already liked, find the corresponding likeId
        const likeId = localLikedReplies.find((likedReply) => likedReply.reply.id === reply.id)?.id;
        // Delete the like
        dispatch(deleteLike(likeId));
      } else {
        // If not liked, create a new like
        const likeData = {
          data: {
            user: user.data.id,
            reply: reply.id,
          },
        };
        dispatch(createLike(likeData));
      }

      // Dispatch the action that fetches the recipe data (including comments) again
      dispatch(fetchRecipe(recipe.attributes.slug));

      // Update the like count for the specific comment
      setReplyLikesCount((prevReplyLikesCount) => ({
        ...prevReplyLikesCount,
        [reply.id]: currentlyLikedReply ? prevReplyLikesCount[reply.id] - 1 : prevReplyLikesCount[reply.id] + 1,
      }));

      // Enable reply like function again
      // Note: setTimeout prevents multiple like operations (e.g. tapping like button twice quicly)
      // This is a temporary solution, we shouldn't rely on setTimeout to prevent multiple likes
      setTimeout(() => {
        setIsReplyLikeProcessing(false);
      }, 500);
    }
  };

  const isReplyLiked = (reply) => {
    if (localLikedReplies) {
      return localLikedReplies.some((e) => e.reply?.id === reply.id);
    }
  };

  // Initialize commentLikesCount as an object to store like counts for each comment
  const [commentLikesCount, setCommentLikesCount] = useState({});

  // Use useEffect to update commentLikesCount when recipe changes
  useEffect(() => {
    if (comments?.length > 0) {
      const initialCommentLikesCount = {};
      comments?.forEach((comment) => {
        initialCommentLikesCount[comment.id] = comment.attributes?.likes?.data.length;
      });
      setCommentLikesCount(initialCommentLikesCount);
    }
  }, [comments]);

  // Prevent multiple reply likes or unlikes
  const [isCommentLikeProcessing, setIsCommentLikeProcessing] = useState(false);

  const handleCommentLike = (comment) => {
    // Check if comment like function is already in process
    if (!isCommentLikeProcessing) {
      // Disable comment like function while one is already in process
      setIsCommentLikeProcessing(true);

      const currentlyLikedComment = isCommentLiked(comment);

      const newLikedComment = currentlyLikedComment
        ? localLikedComments.filter((likedComment) => likedComment.comment.id !== comment.id)
        : [...localLikedComments, comment];

      setLocalLikedComments(newLikedComment);

      // Update back-end likes
      if (currentlyLikedComment) {
        // If already liked, find the corresponding likeId
        const likeId = localLikedComments.find((likedComment) => likedComment.comment.id === comment.id)?.id;
        // Delete the like
        dispatch(deleteLike(likeId));
      } else {
        // If not liked, create a new like
        const likeData = {
          data: {
            user: user.data.id,
            comment: comment.id,
          },
        };
        dispatch(createLike(likeData));
      }

      // Update the like count for the specific comment
      setCommentLikesCount((prevCommentLikeCounts) => ({
        ...prevCommentLikeCounts,
        [comment.id]: currentlyLikedComment
          ? prevCommentLikeCounts[comment.id] - 1
          : prevCommentLikeCounts[comment.id] + 1,
      }));

      // Enable comment like function again
      // Note: setTimeout prevents multiple like operations (e.g. tapping like button twice quicly)
      // This is a temporary solution, we shouldn't rely on setTimeout to prevent multiple likes
      setTimeout(() => {
        setIsCommentLikeProcessing(false);
      }, 500);
    }
  };

  const isCommentLiked = (comment) => {
    if (localLikedComments) {
      return localLikedComments.some((e) => e.comment?.id === comment.id);
    }
  };

  const commentTextFieldRef = useRef(null);

  const handlePostComment = async (e) => {
    // Prevent the default form submission behavior
    e.preventDefault();
    if (commentText.trim() !== "") {
      if (commentID === null) {
        try {
          // Post comment and fetch data
          dispatch(postComments(userID, recipeID, commentText));
          // Delay updating the frontend.
          // Note: This needs a better approach, we can't rely on setTimeout
          setTimeout(() => {
            dispatch(fetchRecipe(recipe.attributes.slug));
          }, "500");
          // Clear comment field
          setCommentText("");
        } catch (error) {
          console.error("Error posting comment:", error);
          alert("Error posting comment. Please try again later.");
        }
      } else {
        try {
          // Post reply and fetch data
          dispatch(postReply(userID, commentID, commentText));
          // Delay updating the frontend.
          // Note: This needs a better approach, we can't rely on setTimeout
          setTimeout(() => {
            dispatch(fetchRecipe(recipe.attributes.slug));
          }, "500");
          // Clear comment field
          setCommentText("");
        } catch (error) {
          console.error("Error posting reply:", error);
          alert("Error posting reply. Please try again later.");
        }
      }
    }
  };

  // Show/Hide Replies

  const [showReplies, setShowReplies] = useState(new Array(comments?.length).fill(false));
  const [repliesToShow, setRepliesToShow] = useState(9);

  const toggleReplies = (index) => {
    const newShowReplies = [...showReplies];
    newShowReplies[index] = !newShowReplies[index];
    setShowReplies(newShowReplies);
  };

  const loadMoreReplies = () => {
    setRepliesToShow((prev) => prev + 9);
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    const recipeName = pathname.replace("/recipes/", "");
    dispatch(fetchRecipe(recipeName));
  }, [dispatch]);

  // Like comments
  useEffect(() => {
    setLocalLikedComments(likedComments);
  }, [likedComments]);

  // Like comments
  useEffect(() => {
    setLocalLikedReplies(likedReplies);
  }, [likedReplies]);

  // Go to comments if URL contains "#comments"
  useEffect(() => {
    const hash = window.location.hash;
    // Check if the hash exists and is equal to "comments"
    if (hash && hash.substring(1) === "comments") {
      const commentsElement = document.getElementById("comments");

      if (commentsElement) {
        commentsElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <Box id="comments" sx={{ display: "flex", flex: "1", flexDirection: "column" }}>
      <Typography variant="h6" padding={2}>
        Comments
      </Typography>
      <Box sx={{ flex: 1, overflow: "auto" }}>
        {comments?.map((comment, index) => {
          const commentUsername = comment?.attributes?.user?.data?.attributes?.username;
          const commentAvatar =
            comment?.attributes?.user?.data?.attributes?.avatar?.data?.attributes?.formats?.thumbnail?.url;
          const commentText = comment?.attributes?.comment;
          const commentReplies = comment?.attributes?.replies?.data;
          const commentDate = comment?.attributes?.createdAt;
          return (
            <Box key={index}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="start"
                p={2}
                pb={commentReplies?.length > 0 ? 0 : undefined}>
                <IconButton component={RouterLink} to={`/user/${commentUsername}`} aria-label="more options">
                  <Avatar
                    alt={commentUsername}
                    src={commentAvatar}
                    aria-label="more options"
                    sx={{ width: 32, height: 32 }}
                  />
                </IconButton>
                <Box sx={{ flex: 1 }}>
                  <Link component={RouterLink} to={`/user/${commentUsername}`} underline="hover">
                    <Typography variant="subtitle2" fontWeight="bold">
                      {commentUsername}
                    </Typography>
                  </Link>
                  <Typography>{commentText}</Typography>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="caption" color="grey">
                      {formatDate(commentDate)}
                    </Typography>
                    <Button
                      onClick={() => {
                        // Set comment ID
                        setCommentID(comment.id);
                        // Add mention
                        const mention = `@${commentUsername} `;
                        setCommentText((prevText) => prevText + mention);
                        // Focus comment field
                        commentTextFieldRef.current.focus();
                      }}
                      size="small"
                      variant="text">
                      Reply
                    </Button>
                    <IconButton id="basic-button" onClick={(event) => handleCommentClick(event, comment)}>
                      <MoreHoriz />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={commentMenu}
                      open={openCommentMenu}
                      onClose={closeCommentMenu}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}>
                      {user.id && user.id === commentUserId ? (
                        <MenuItem
                          onClick={removeComment}
                          sx={{
                            color: "grey",
                          }}>
                          <DeleteOutlineIcon
                            sx={{
                              marginRight: "8px",
                            }}
                          />
                          <Typography variant="body2">Delete</Typography>
                        </MenuItem>
                      ) : null}
                      <MenuItem
                        onClick={closeCommentMenu}
                        sx={{
                          color: "grey",
                        }}>
                        <Flag
                          sx={{
                            marginRight: "8px",
                          }}
                        />
                        <Typography variant="body2">Report</Typography>
                      </MenuItem>
                    </Menu>
                  </Stack>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  <IconButton onClick={() => handleCommentLike(comment)}>
                    {isCommentLiked(comment) ? <Favorite color="primary" /> : <FavoriteBorder color="primary" />}
                  </IconButton>
                  <Typography variant="caption" color="grey">
                    {commentLikesCount[comment.id]}
                  </Typography>
                </Box>
              </Stack>
              {commentReplies?.length > 0 && (
                <Stack pt={0} pr={2} pb={2} pl={8}>
                  <Box>
                    <Button onClick={() => toggleReplies(index)}>
                      {showReplies[index] ? (
                        <>
                          Hide Replies <ExpandLessOutlined />
                        </>
                      ) : commentReplies?.length === 1 ? (
                        <>
                          1 Reply <ExpandMoreOutlined />
                        </>
                      ) : (
                        <>
                          <ExpandMoreOutlined /> {commentReplies?.length} Replies
                        </>
                      )}
                    </Button>
                  </Box>
                  {showReplies[index] && (
                    <>
                      {commentReplies.slice(0, repliesToShow).map((reply, index) => {
                        const replyUsername = reply.attributes.user.data.attributes.username;
                        const replyAvatar =
                          reply?.attributes?.user?.data?.attributes?.avatar?.data?.attributes?.formats?.thumbnail?.url;
                        const replyComment = reply.attributes.reply;
                        const replyDate = reply.attributes.createdAt;

                        return (
                          <Stack direction="row" spacing={1} alignItems="start" key={index}>
                            <IconButton
                              component={RouterLink}
                              to={`/user/${replyUsername}`}
                              aria-label="more options">
                              <Avatar
                                alt={replyUsername}
                                src={replyAvatar}
                                aria-label="more options"
                                sx={{
                                  width: 32,
                                  height: 32,
                                }}
                              />
                            </IconButton>
                            <Box sx={{ flex: 1 }}>
                              <Link component={RouterLink} to={`/user/${replyUsername}`} underline="hover">
                                <Typography variant="subtitle2" fontWeight="bold">
                                  {replyUsername}
                                </Typography>
                              </Link>
                              <Typography>{replyComment}</Typography>
                              <Stack direction="row" spacing={1} alignItems="center">
                                <Typography variant="caption" color="grey">
                                  {formatDate(replyDate)}
                                </Typography>
                                <Button
                                  onClick={() => {
                                    // Set comment ID
                                    setCommentID(comment.id);
                                    // Add mention
                                    const mention = `@${replyUsername} `;
                                    setCommentText((prevText) => prevText + mention);
                                    // Focus comment field
                                    commentTextFieldRef.current.focus();
                                  }}
                                  variant="text"
                                  size="small">
                                  Reply
                                </Button>
                                <IconButton id="basic-button" onClick={(event) => handleReplyClick(event, reply)}>
                                  <MoreHoriz />
                                </IconButton>
                                <Menu
                                  id="basic-menu"
                                  anchorEl={replyMenu}
                                  open={openReplyMenu}
                                  onClose={closeReplyMenu}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}>
                                  {user.id && user.id === replyUserId ? (
                                    <MenuItem
                                      onClick={removeReply}
                                      sx={{
                                        color: "grey",
                                      }}>
                                      <DeleteOutlineIcon
                                        sx={{
                                          marginRight: "8px",
                                        }}
                                      />
                                      <Typography variant="body2">Delete</Typography>
                                    </MenuItem>
                                  ) : null}
                                  <MenuItem
                                    onClick={closeReplyMenu}
                                    sx={{
                                      color: "grey",
                                    }}>
                                    <Flag
                                      sx={{
                                        marginRight: "8px",
                                      }}
                                    />
                                    <Typography variant="body2">Report</Typography>
                                  </MenuItem>
                                </Menu>
                              </Stack>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}>
                              <IconButton onClick={() => handleReplyLike(reply)}>
                                {isReplyLiked(reply) ? (
                                  <Favorite color="primary" />
                                ) : (
                                  <FavoriteBorder color="primary" />
                                )}
                              </IconButton>
                              <Typography variant="caption" color="grey">
                                {replyLikesCount[reply.id]}
                              </Typography>
                            </Box>
                          </Stack>
                        );
                      })}

                      {commentReplies?.length > repliesToShow && <Button onClick={loadMoreReplies}>Show more</Button>}
                    </>
                  )}
                </Stack>
              )}
            </Box>
          );
        })}
      </Box>
      <Divider />
      <form onSubmit={handlePostComment}>
        <TextField type="hidden" value={commentID ? commentID : ""} sx={{ display: "none" }} />
        <Stack direction="row" spacing={2} alignItems="center" p={2}>
          <TextField
            placeholder="Add comment"
            variant="outlined"
            size="small"
            sx={{ flex: 1 }}
            value={commentText} // Bind the value to the state
            onChange={(e) => setCommentText(e.target.value)} // Handle changes
            inputRef={commentTextFieldRef}
          />
          <Button type="submit" variant="contained" size="large" disableElevation>
            Post
          </Button>
        </Stack>
      </form>
    </Box>
  );
}
