import { fetchUsersStart, fetchUsersSuccess, fetchUsersFailure } from "../reducers/usersReducer";
import axios from "axios";

// Get users
export const fetchUsers = () => (dispatch) => {
  // Dispatch the start action
  dispatch(fetchUsersStart());

  // API call
  axios
    .get(`${process.env.REACT_APP_API_HOSTNAME}/api/users`)
    .then((response) => {
      // Dispatch the success action
      dispatch(fetchUsersSuccess(response.data));
    })
    .catch((error) => {
      // Dispatch the error action
      dispatch(fetchUsersFailure(error.message));
    });
};
