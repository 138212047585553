// React
import React from "react";
import { Link as RouterLink } from "react-router-dom";
// Libraries
import { Helmet } from "react-helmet";
// UI
import { Box, Typography, Link } from "@mui/material";

export default function PageNotFound() {
  return (
    <>
      <Helmet>
        <title>Page not found - Dishly</title>
        <meta name="prerender-status-code" content="404"></meta>
      </Helmet>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="80vh" textAlign="center">
        <Typography variant="h1" color="primary">
          404
        </Typography>
        <Typography variant="h5" color="textSecondary" marginBottom={2}>
          Page Not Found
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Use the search field or{" "}
          <Link component={RouterLink} to="/sitemap">
            see our site map.
          </Link>
        </Typography>
      </Box>
    </>
  );
}
