// Router
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

// UI
import TopBar from "./components/ui/TopBar";
import BottomNav from "./components/ui/BottomNav";

// Account
import SignIn from "./components/account/SignIn";
import SignUp from "./components/account/SignUp";
import ForgotPassword from "./components/account/ForgotPassword";
import ResetPassword from "./components/account/ResetPassword";
import Account from "./components/account/Account";
import Profile from "./components/account/Profile";
import Notifications from "./components/account/Notifications";
import Saved from "./components/account/Saved";
import Security from "./components/account/Security";

// Recipes
import Recipes from "./components/recipes/Recipes";
import Recipe from "./components/recipes/Recipe";
import EditRecipe from "./components/recipes/EditRecipe";

// Errors
import PageNotFound from "./components/errors/404";

// Index
import Sitemap from "./components/index.html/Sitemap";

// Custom MUI
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { teal } from "@mui/material/colors";

// Create Theme
let theme = createTheme({
  palette: {
    background: {
      //default: grey[100],
    },
    primary: {
      // light: "string",
      main: teal[500],
      // dark: "string",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
    h1: {
      // fontFamily: "Open Sans, sans-serif",
      fontWeight: "500",
    },
    h2: {
      // fontFamily: "Open Sans, sans-serif",
      fontWeight: "500",
    },
    h3: {
      // fontFamily: "Open Sans, sans-serif",
      fontWeight: "500",
    },
    h4: {
      // fontFamily: "Open Sans, sans-serif",
      fontWeight: "500",
    },
    h5: {
      // fontFamily: "Open Sans, sans-serif",
      fontWeight: "500",
    },
    h6: {
      // fontFamily: "Open Sans, sans-serif",
      fontWeight: "500",
    },
    button: {
      textTransform: "none",
      letterSpacing: "none",
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: "100vh",
        },
        body: {
          display: "flex",
          flexDirection: "column",
          height: "100%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        startIcon: {
          marginRight: "4px",
        },
        root: {
          borderRadius: 24,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
      },
    },
  },
});
// Adapts typography to smaller devices
theme = responsiveFontSizes(theme);

// Create routes
const router = createBrowserRouter([
  { path: "/", element: <Navigate to="/recipes" /> },
  { path: "/signin", element: <SignIn /> },
  { path: "/signup", element: <SignUp /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/reset-password", element: <ResetPassword /> },
  {
    path: "/account",
    element: (
      <>
        <TopBar />
        <Account />
        <BottomNav />
      </>
    ),
  },
  {
    path: "user/:userName",
    element: (
      <>
        <TopBar />
        <Profile />
        <BottomNav />
      </>
    ),
  },
  {
    path: "/notifications",
    element: (
      <>
        <TopBar />
        <Notifications />
        <BottomNav />
      </>
    ),
  },
  {
    path: "/security",
    element: (
      <>
        <TopBar />
        <Security />
        <BottomNav />
      </>
    ),
  },
  {
    path: "/recipes",
    element: (
      <>
        <TopBar />
        <Recipes />
        <BottomNav />
      </>
    ),
  },
  {
    path: "/recipes/:recipeName",
    element: (
      <>
        <TopBar />
        <Recipe />
        <BottomNav />
      </>
    ),
  },
  {
    path: "/edit-recipe",
    element: (
      <>
        <TopBar />
        <EditRecipe />
        <BottomNav />
      </>
    ),
  },
  {
    path: "/saved",
    element: (
      <>
        <TopBar />
        <Saved />
        <BottomNav />
      </>
    ),
  },
  {
    path: "*",
    element: (
      <>
        <TopBar />
        <PageNotFound />
        <BottomNav />
      </>
    ),
  },
  {
    path: "/sitemap",
    element: (
      <>
        <TopBar />
        <Sitemap />
        <BottomNav />
      </>
    ),
  },
]);

export default function App() {
  const fullHeight = {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  };
  return (
    <div className="App" style={fullHeight}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </div>
  );
}
