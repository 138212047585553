import { createSlice } from "@reduxjs/toolkit";

const recipeSlice = createSlice({
  name: "recipe",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    fetchRecipeStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchRecipeSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.id = action.payload.id;
    },
    fetchRecipeFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    clearRecipe: (state) => {
      state.data = null;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const { fetchRecipeStart, fetchRecipeSuccess, fetchRecipeFailure, clearRecipe } = recipeSlice.actions;

export default recipeSlice.reducer;
