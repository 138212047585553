// React
import React from "react";
// UI
import { Button } from "@mui/material";
import { Reply } from "@mui/icons-material";

export default function ShareButton({ title, text, url }) {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          text: text,
          title: title,
          url: url,
        });
      } catch (error) {
        // Fallback to copying to clipboard if sharing fails
        copyToClipboard();
      }
    } else {
      // Web Share API not supported, fallback to copying to clipboard
      copyToClipboard();
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(url);
      // Display a message that URL has been copied to the clipboard
      alert("URL has been copied to your clipboard");
    } catch (error) {
      // Handle the error or provide a fallback mechanism
      console.error("Error copying to clipboard:", error);
    }
  };

  return (
    <Button
      onClick={handleShare}
      startIcon={
        <Reply
          sx={{
            transform: "scaleX(-1)",
          }}
        />
      }
      sx={{
        color: "grey",
      }}>
      Share
    </Button>
  );
}
