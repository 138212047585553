import { createSlice } from "@reduxjs/toolkit";

const passwordSlice = createSlice({
  name: "password",
  initialState: {
    data: {},
    isLoading: false,
    error: null, // Initialize error as null
    success: false,
  },
  reducers: {
    resetPasswordStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    resetPasswordSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.success = true;
      state.error = null;
    },
    resetPasswordFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    changePasswordStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    changePasswordSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.success = true;
      state.error = null;
    },
    changePasswordFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
} = passwordSlice.actions;

export default passwordSlice.reducer;
