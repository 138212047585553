// React
import React, { useState, useEffect, useMemo } from "react";
// State
import { useSelector, useDispatch } from "react-redux";
import { fetchUser, updateUser } from "../../actions/userActions";
import Cookies from "js-cookie";
import axios from "axios";
// UI
import { Box, Typography, Autocomplete, TextField, Button } from "@mui/material";

export default function Filters({ closeFilters }) {
  // Set dispatch
  const dispatch = useDispatch();

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // Get user
  const user = useSelector((state) => state.user);

  // User allergies
  const userAllergies = useMemo(() => {
    return (
      user.data.allergies?.map((allergy) => ({
        id: allergy.id,
        name: allergy.name,
      })) || []
    );
  }, [user.data.allergies]);
  const [allergies, setAllergies] = useState([]);
  const [selectedAllergies, setSelectedAllergies] = useState(
    userAllergies.map((allergy) => ({ id: allergy.id, name: allergy.name }))
  );

  // User dietaries
  const userDietaries = useMemo(() => {
    return (
      user.data.dietaries?.map((dietary) => ({
        id: dietary.id,
        name: dietary.name,
      })) || []
    );
  }, [user.data.dietaries]);
  const [dietaries, setDietaries] = useState([]);
  const [selectedDietaries, setSelectedDietaries] = useState(
    userDietaries.map((dietary) => ({ id: dietary.id, name: dietary.name }))
  );

  // User favorite cuisines
  const userFavoriteCuisines = useMemo(() => {
    return (
      user.data.favorite_cuisines?.map((cuisine) => ({
        id: cuisine.id,
        name: cuisine.name,
      })) || []
    );
  }, [user.data.favorite_cuisines]);
  const [cuisines, setCuisines] = useState([]);
  const [selectedCuisines, setSelectedCuisines] = useState(
    userFavoriteCuisines.map((cuisine) => ({
      id: cuisine.id,
      name: cuisine.name,
    }))
  );

  // Update fields
  const [updatedFields, setUpdatedFields] = useState({});

  // Get allergies collection-type and use it for the autocomplete options
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_HOSTNAME}/api/allergies?pagination[start]=0&pagination[limit]=100`)
      .then((response) => {
        const allergies = response.data.data.map((item) => ({
          id: item.id,
          name: item.attributes.name,
        }));
        setAllergies(allergies);
        setSelectedAllergies(
          userAllergies.map((allergy) => ({
            id: allergy.id,
            name: allergy.name,
          })) || []
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userToken, userAllergies]);

  // Get dietaries collection-type and use it for the autocomplete options
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_HOSTNAME}/api/dietaries?pagination[start]=0&pagination[limit]=100`)
      .then((response) => {
        const dietaries = response.data.data.map((item) => ({
          id: item.id,
          name: item.attributes.name,
        }));
        setDietaries(dietaries);
        setSelectedDietaries(
          userDietaries.map((dietary) => ({
            id: dietary.id,
            name: dietary.name,
          })) || []
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userToken, userDietaries]);

  // Get cuisines collection-type and use it for the autocomplete options
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_HOSTNAME}/api/cuisines?pagination[start]=0&pagination[limit]=100`)
      .then((response) => {
        const cuisines = response.data.data.map((item) => ({
          id: item.id,
          name: item.attributes.name,
        }));
        setCuisines(cuisines);
        setSelectedCuisines(
          userFavoriteCuisines.map((cuisine) => ({
            id: cuisine.id,
            name: cuisine.name,
          })) || []
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userToken, userFavoriteCuisines]);

  const handleAllergyChange = (event, values) => {
    setSelectedAllergies(values);
    setUpdatedFields({ ...updatedFields, allergies: values });
  };

  const handleDietaryChange = (event, values) => {
    setSelectedDietaries(values);
    setUpdatedFields({ ...updatedFields, dietaries: values });
  };

  const handleCuisineChange = (event, values) => {
    setSelectedCuisines(values);
    setUpdatedFields({ ...updatedFields, favorite_cuisines: values });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Update user data
    dispatch(
      updateUser({
        ...updatedFields,
        allergies: selectedAllergies,
        dietaries: selectedDietaries,
        favorite_cuisines: selectedCuisines,
      })
    );
    // Reset fields
    setUpdatedFields({});
    // Close filters
    closeFilters();
    // Reload page
    window.location.reload();
  };

  // Clear filters
  const clearFilters = () => {
    setSelectedAllergies([]);
    setSelectedDietaries([]);
    setSelectedCuisines([]);
  };

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  if (user.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {user.data && userAllergies ? (
        <div>
          <Typography variant="body2" color="text.secondary" marginBottom={3}>
            Set these filters to see all our content based on your preferences.
          </Typography>
          <form id="filters" onSubmit={handleSubmit}>
            <Typography variant="subtitle2" gutterBottom>
              Food Allergies
            </Typography>
            <Autocomplete
              multiple
              options={allergies}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={selectedAllergies.filter((option) => allergies.some((allergy) => allergy.id === option.id))}
              onChange={handleAllergyChange}
              renderInput={(params) => <TextField {...params} placeholder="Search allergies" />}
              sx={{ marginBottom: 4 }}
            />

            <Typography variant="subtitle2" gutterBottom>
              Dietary Restrictions
            </Typography>
            <Autocomplete
              multiple
              options={dietaries}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={selectedDietaries.filter((option) => dietaries.some((dietary) => dietary.id === option.id))}
              onChange={handleDietaryChange}
              renderInput={(params) => <TextField {...params} placeholder="Search dietaries" />}
              sx={{ marginBottom: 4 }}
            />
            <Typography variant="subtitle2" gutterBottom>
              Favorite Cuisines
            </Typography>
            <Autocomplete
              multiple
              options={cuisines}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={selectedCuisines.filter((option) => cuisines.some((cuisine) => cuisine.id === option.id))}
              onChange={handleCuisineChange}
              renderInput={(params) => <TextField {...params} placeholder="Search cuisines" />}
              sx={{ marginBottom: 4 }}
            />
            <Box display="flex" justifyContent="end">
              <Button onClick={clearFilters} variant="outlined" size="large" disableElevation sx={{ marginRight: 2 }}>
                Clear Filters
              </Button>
              <Button type="submit" variant="contained" size="large" disableElevation>
                Save
              </Button>
            </Box>
          </form>
        </div>
      ) : (
        <div>User not found</div>
      )}
    </div>
  );
}
