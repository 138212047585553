import { createSlice } from "@reduxjs/toolkit";

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    fetchNotificationsStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchNotificationsSuccess: (state, action) => {
      // Set loading to false
      state.isLoading = false;
      // API reponse
      const newNotifications = action.payload;
      // Check if any of the new notifications already exist in the data array
      const existingIds = state.data.map((notification) => notification.id);
      const existingItems = newNotifications.filter((notification) => existingIds.includes(notification.id));
      if (existingItems.length > 0) {
        // Repeated pagination bulk - replace existing data with the new data
        state.data = newNotifications;
      } else {
        // New pagination bulk - simply add new items to the data array
        state.data.push(...newNotifications);
      }
    },

    fetchNotificationsFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchNotificationsStart, fetchNotificationsSuccess, fetchNotificationsFailure } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
