import { createSlice } from "@reduxjs/toolkit";

const likesSlice = createSlice({
  name: "likes",
  initialState: {
    data: [],
    isLoading: false,
    success: false,
    error: null,
  },
  reducers: {
    createLikeStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createLikeSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.id = action.payload.id;
      state.success = true;
      state.error = null;
    },
    createLikeFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    deleteLikeStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    deleteLikeSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.id = action.payload.id;
      state.success = true;
      state.error = null;
    },
    deleteLikeFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  createLikeStart,
  createLikeSuccess,
  createLikeFailure,
  deleteLikeStart,
  deleteLikeSuccess,
  deleteLikeFailure,
} = likesSlice.actions;

export default likesSlice.reducer;
