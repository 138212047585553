// React
import React, { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { fetchRelatedRecipes } from "../../actions/relatedRecipesActions";
import { Link as RouterLink } from "react-router-dom";
// UI
import { Grid, Container, Card, CardActionArea, CardContent, Box, Stack, Typography } from "@mui/material";
import { Star } from "@mui/icons-material";

export default function RelatedRecipes() {
  const dispatch = useDispatch();
  const currentRecipe = useSelector((state) => state.recipe.data);
  const recipes = useSelector((state) => state.recipes.data);

  // Fetch recipes
  useEffect(() => {
    const recipeId = currentRecipe.id;
    const recipeName = currentRecipe.attributes.title;
    const recipeAuthor = currentRecipe.attributes.author.data.attributes.username;
    const recipeCuisines = currentRecipe.attributes.cuisines.data.map((item) => item.id) || [];
    dispatch(fetchRelatedRecipes(recipeId, recipeName, recipeAuthor, recipeCuisines));
  }, [currentRecipe, dispatch]);

  return (
    <>
      {recipes?.length > 0 && (
        <Grid item xs={12} sm={12} md={4}>
          <Container sx={{ px: "0 !important" }}>
            <Grid container mb={2}>
              <Grid item xs={12}>
                <Typography variant="h6">You might also like</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              {recipes.map((recipe) => {
                // Is the recipe new?
                const publishDate = new Date(recipe?.attributes?.createdAt);
                const currentDate = new Date();
                // Calculate the difference in days between the current date and the publish date
                const daysDifference = Math.floor((currentDate - publishDate) / (1000 * 3600 * 24));
                // Recipe is new if it has less than 30 days
                const isNew = daysDifference <= 30;
                return (
                  <Grid key={recipe.id} item xs={12} sm={6} md={12}>
                    <Card variant="outlined" sx={{ height: "100%" }}>
                      <CardActionArea component={RouterLink} to={`/recipes/${recipe?.attributes?.slug}`}>
                        <CardContent sx={{ flex: 1 }}>
                          <Stack direction="row" justifyContent="space-between" spacing={1}>
                            <Stack spacing={1} sx={{ flex: 1 }}>
                              {isNew && (
                                <Box
                                  pt={0.7}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    fontSize: 14,
                                    color: "primary.main",
                                  }}>
                                  <Star
                                    fontSize="small"
                                    sx={{
                                      marginRight: 0.4,
                                    }}
                                  />
                                  New
                                </Box>
                              )}
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight: "500",
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 2,
                                }}>
                                {recipe?.attributes?.title}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}>
                                {recipe?.attributes?.author?.data?.attributes?.username}
                              </Typography>
                            </Stack>
                            {recipe?.attributes?.cover &&
                              recipe.attributes.cover.data &&
                              recipe.attributes.cover.data.attributes && (
                                <Box
                                  component="img"
                                  src={recipe?.attributes?.cover.data.attributes.url}
                                  alt={recipe?.attributes?.title}
                                  sx={{
                                    width: "33%",
                                    minWidth: "33%",
                                    height: "100%",
                                    aspectRatio: "1/1",
                                    objectFit: "cover",
                                    borderRadius: 1,
                                  }}></Box>
                              )}
                          </Stack>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </Grid>
      )}
    </>
  );
}
