import {
  fetchProfileStart,
  fetchProfileSuccess,
  fetchProfileFailure,
  updateProfileStart,
  updateProfileSuccess,
  updateProfileFailure,
} from "../reducers/profileReducer";
import Cookies from "js-cookie";
import axios from "axios";

// Get profile
export const fetchProfile = (userName) => (dispatch) => {
  // Dispatch the start action
  dispatch(fetchProfileStart());

  // API call
  axios
    .get(`${process.env.REACT_APP_API_HOSTNAME}/api/users?populate=*&filters[$and][0][username][$eq]=${userName}`)
    .then((response) => {
      dispatch(fetchProfileSuccess(response));
    })
    .catch((error) => {
      dispatch(fetchProfileFailure(error.message));
    });
};

// Edit profile
export const updateProfile = (userData, files) => (dispatch, getState) => {
  // Dispatch the start action
  dispatch(updateProfileStart());

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // Get user ID from Redux store
  const userId = getState().user.data.id;

  // If there's an image
  if (files) {
    // Prepare files
    const formData = new FormData();
    formData.append("files", files);

    // Upload files first
    axios
      .post(`${process.env.REACT_APP_API_HOSTNAME}/api/upload`, formData, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        // Get media ID
        const mediaId = response.data[0].id;

        // Set data for API
        let dataForApi = [];

        if (mediaId) {
          // Prepare data for API
          dataForApi = {
            avatar: mediaId,
            username: userData.username,
            name: userData.name,
            bio: userData.bio,
          };
        }
        // Add data to user profile
        axios
          .put(`${process.env.REACT_APP_API_HOSTNAME}/api/users/${userId}?populate=*`, dataForApi, {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          })
          .then((response) => {
            // Dispatch the success action
            dispatch(updateProfileSuccess(response.data.data));
            // Redirect to new username's URL
            var currentUrl = window.location.href;
            var isProfile = currentUrl.includes("/user");
            if (isProfile) {
              window.location.href = `/user/${userData.username}`;
            }
          })
          .catch((error) => {
            // Dispatch the error action
            dispatch(updateProfileFailure(error.message));
          });
      })
      // File upload error
      .catch((error) => {
        // Dispatch the error action
        dispatch(updateProfileFailure(error.message));
      });
  } else {
    // Add data to user profile
    axios
      .put(`${process.env.REACT_APP_API_HOSTNAME}/api/users/${userId}?populate=*`, userData, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        // dispatch the success action
        dispatch(updateProfileSuccess(response.data.data));
        // Redirect to new username's URL
        var currentUrl = window.location.href;
        var isProfile = currentUrl.includes("/user");
        if (isProfile) {
          window.location.href = `/user/${userData.username}`;
        }
      })
      .catch((error) => {
        // dispatch the error action
        dispatch(updateProfileFailure(error.message));
      });
  }
};
