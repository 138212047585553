import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: {},
    isLoading: false,
    error: null,
    success: false,
  },
  reducers: {
    fetchUserStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchUserSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.success = true;
      state.id = action.payload.id;
    },
    fetchUserFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateUserStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    updateUserSuccess: (state, action) => {
      state.isLoading = false;
      state.data = { ...state.data, ...action.payload };
      state.success = true;
    },
    updateUserFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    clearUserStart: (state) => {
      state.isLoading = true;
      state.data = {};
      state.error = null;
    },
    clearUserSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
    },
    clearUserFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      console.error("Clear user failed:", action.payload);
    },
  },
});

export const {
  fetchUserStart,
  fetchUserSuccess,
  fetchUserFailure,
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
  clearUserStart,
  clearUserSuccess,
  clearUserFailure,
} = userSlice.actions;

export default userSlice.reducer;
