// React
import React, { useEffect, useState } from "react";
// Router
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { fetchRecipes } from "../../actions/recipesActions";
import { clearRecipes } from "../../reducers/recipesReducer";
// Libraries
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
// Components
import IndexCardY from "../ui/IndexCardY";
// UI
import { LinearProgress, CircularProgress, Grid, Container, Card, CardContent, Box, Typography, Button, Tabs, Tab } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import TapasOutlinedIcon from "@mui/icons-material/TapasOutlined";
import FreeBreakfastOutlinedIcon from "@mui/icons-material/FreeBreakfastOutlined";
import BrunchDiningOutlinedIcon from "@mui/icons-material/BrunchDiningOutlined";
import IcecreamOutlinedIcon from "@mui/icons-material/IcecreamOutlined";
import LiquorOutlinedIcon from "@mui/icons-material/LiquorOutlined";
import LunchDiningOutlinedIcon from "@mui/icons-material/LunchDiningOutlined";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";
import RiceBowlOutlinedIcon from "@mui/icons-material/RiceBowlOutlined";
import CookieOutlinedIcon from "@mui/icons-material/CookieOutlined";
import BreakfastDiningOutlinedIcon from "@mui/icons-material/BreakfastDiningOutlined";
import SoupKitchenOutlinedIcon from "@mui/icons-material/SoupKitchenOutlined";

export default function Recipes() {
  // Set dispathch
  const dispatch = useDispatch();

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // Get user state
  const recipes = useSelector((state) => state.recipes.data);

  // Pagination
  const [pagination, setPagination] = useState({ start: 0, limit: 25 });

  // Category tabs
  const [category, setCategory] = useState("discover");

  const handleChange = (event, newValue) => {
    // Clear recipes cache
    dispatch(clearRecipes());
    // Reset pagination when changing categories
    setPagination({ start: 0, limit: 25 });
    // Set category
    setCategory(newValue);
  };

  // Loading
  const [loading, setLoading] = useState(false);

  // Get search queries from the URL
  const location = useLocation();

  // Fetch recipes
  useEffect(() => {
    async function fetchData() {
      if (!loading) {
        try {
          // Show loading spinner while fetching more recipes
          setLoading(true);
          const search = new URLSearchParams(location.search).get("search");
          dispatch(fetchRecipes(search, category, pagination));
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
    }
    fetchData();
  }, [dispatch, loading, category, location, pagination]);

  // Infinite scrolling
  const handleScroll = () => {
    const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
    // Check if the user has reached the bottom (with a small buffer)
    if (scrollHeight - (scrollTop + clientHeight) < 100) {
      // Load more notifications
      setPagination((prev) => ({ ...prev, start: prev.start + prev.limit }));
    }
  };

  useEffect(() => {
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Check if recipe is null or the data property is not available yet
  if (!recipes) {
    return (
      <Box sx={{ flex: 1 }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`Food recipes - Dishly`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Endless dishes for all dietary restrictions from different cuisines" />
        <meta property="og:title" content="Dishly • Food recipes" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Endless dishes for all dietary restrictions from different cuisines" />
      </Helmet>
      <Container maxWidth="xxl" sx={{ flex: 1, marginBottom: 4 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Tabs value={category} onChange={handleChange} aria-label="recipe categories" variant="scrollable" sx={{ marginBottom: 4 }}>
            <Tab icon={<ExploreOutlinedIcon />} iconPosition="start" label="Discover" value="discover" />
            <Tab icon={<TapasOutlinedIcon />} iconPosition="start" label="Appetizers" value="appetizer" />
            <Tab icon={<FreeBreakfastOutlinedIcon />} iconPosition="start" label="Breakfast" value="breakfast" />
            <Tab icon={<BrunchDiningOutlinedIcon />} iconPosition="start" label="Brunch" value="brunch" />
            <Tab icon={<IcecreamOutlinedIcon />} iconPosition="start" label="Desserts" value="dessert" />
            <Tab icon={<LiquorOutlinedIcon />} iconPosition="start" label="Drinks" value="drink" />
            <Tab icon={<LunchDiningOutlinedIcon />} iconPosition="start" label="Mains" value="main" />
            <Tab icon={<SpaOutlinedIcon />} iconPosition="start" label="Salads" value="salad" />
            <Tab icon={<RiceBowlOutlinedIcon />} iconPosition="start" label="Sides" value="side" />
            <Tab icon={<CookieOutlinedIcon />} iconPosition="start" label="Snacks" value="snack" />
            <Tab icon={<BreakfastDiningOutlinedIcon />} iconPosition="start" label="Tapas" value="tapas" />
            <Tab icon={<SoupKitchenOutlinedIcon />} iconPosition="start" label="Toppings & Sauces" value="topping-sauce" />
          </Tabs>
        </Box>

        <Grid container spacing={4}>
          {!userToken && (
            <Grid
              item
              xs={12}
              sx={{
                display: { xs: "block", sm: "none" },
              }}>
              <Card variant="outlined">
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  <Box>
                    <svg height="48" width="144" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.2171 32">
                      <g id="D">
                        <g>
                          <path
                            d="m13.16,0H4.27C1.69,0,0,1.02,0,4.27v23.47c0,3.24,1.69,4.27,4.27,4.27h8.76c9.73,0,15.38-5.87,15.38-16C28.4,3.47,21.11,0,13.16,0Zm1.01,25.95c-5.5,0-9.96-4.46-9.96-9.96S8.66,6.04,14.16,6.04s9.96,4.46,9.96,9.96-4.46,9.96-9.96,9.96Z"
                            fill="#178675"
                          />
                          <path d="m14.12,9.36c7.73,1.54,7.79,11.69,0,13.27.87.17,1.8.12,2.69-.14,1.82-.52,3.37-1.92,4.14-3.66.79-1.77.79-3.89,0-5.66-1.11-2.58-4.05-4.36-6.83-3.81Z" fill="#178675" />
                        </g>
                        <g>
                          <path
                            d="m36.43,8.74c0-2.01,1.05-2.64,2.64-2.64h5.5c4.92,0,9.44,2.15,9.44,9.9,0,6.27-3.49,9.9-9.52,9.9h-5.42c-1.6,0-2.64-.63-2.64-2.64v-14.53Zm4.51,13.53h3.8c3.6,0,4.76-3.22,4.76-6.27,0-3.91-1.49-6.27-4.79-6.27h-3.77v12.54Z"
                            fill="#178675"
                          />
                          <path
                            d="m58.38,5.63c1.16,0,2.09.94,2.09,2.09s-.94,2.09-2.09,2.09-2.09-.94-2.09-2.09.94-2.09,2.09-2.09Zm-2.01,7.54c0-1.07.44-2.01,2.01-2.01s2.01.94,2.01,2.01v11.09c0,1.07-.44,2.01-2.01,2.01s-2.01-.94-2.01-2.01v-11.09Z"
                            fill="#178675"
                          />
                          <path
                            d="m68.53,11.16c3.05,0,6.13,1.21,6.13,3.05,0,.94-.63,1.76-1.68,1.76-1.57,0-1.62-1.84-4.15-1.84-1.4,0-2.31.39-2.31,1.24,0,.94.91,1.16,2.15,1.43l1.68.39c2.28.52,5.01,1.49,5.01,4.29,0,3.27-3.16,4.79-6.19,4.79-3.63,0-6.66-1.62-6.66-3.6,0-.88.5-1.68,1.62-1.68,1.73,0,1.9,2.48,4.87,2.48,1.43,0,2.34-.63,2.34-1.46,0-1.02-.88-1.18-2.31-1.54l-2.37-.58c-2.34-.58-4.15-1.54-4.15-4.24,0-3.27,3.25-4.48,6.02-4.48Z"
                            fill="#178675"
                          />
                          <path
                            d="m77.25,7.75c0-1.32.8-2.01,2.01-2.01s2.01.69,2.01,2.01v5.53h.06c.8-1.05,2.2-2.12,4.48-2.12,2.42,0,5.06,1.21,5.06,5.28v7.81c0,1.07-.44,2.01-2.01,2.01s-2.01-.94-2.01-2.01v-7.02c0-1.62-.8-2.78-2.53-2.78-1.4,0-3.05,1.16-3.05,3.16v6.63c0,1.07-.44,2.01-2.01,2.01s-2.01-.94-2.01-2.01V7.75Z"
                            fill="#178675"
                          />
                          <path d="m93.62,7.75c0-1.32.8-2.01,2.01-2.01s2.01.69,2.01,2.01v16.51c0,1.32-.8,2.01-2.01,2.01s-2.01-.69-2.01-2.01V7.75Z" fill="#178675" />
                          <path
                            d="m105.72,22.05h.06l2.45-9.11c.28-1.02.83-1.79,1.93-1.79,1.6,0,2.06,1.32,2.06,1.82,0,.33-.11.74-.3,1.27l-4.65,13.18c-.96,2.75-2.06,4.18-4.59,4.18-1.79,0-2.53-.88-2.53-1.84,0-1.07.77-1.62,1.84-1.62h.41c.52,0,1.1-.03,1.4-1.27l-4.18-11.91c-.33-.96-.5-1.4-.5-1.87,0-1.1.88-1.93,1.95-1.93s1.82.61,2.06,1.51l2.59,9.38Z"
                            fill="#178675"
                          />
                        </g>
                      </g>
                    </svg>
                  </Box>
                  <Box
                    marginBottom={1}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <AutoAwesomeIcon color="primary" sx={{ marginRight: 1 }} />
                    <Typography variant="h6" sx={{ fontSize: { xs: 18 } }}>
                      Free account. Endless dishes.
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    marginBottom={2}
                    sx={{
                      color: "#616161",
                      textAlign: "center",
                    }}>
                    Discover and share delicious recipes.
                    <br /> Create your own food filters.
                  </Typography>
                  <Button component={RouterLink} to="/signup" variant="contained" disableElevation sx={{ whiteSpace: "nowrap" }}>
                    Join the community
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          )}
          <IndexCardY data={recipes} />
          {recipes.length > 0 && loading && (
            <Box sx={{ display: "flex", width: "100%", justifyContent: "center", marginY: 2 }}>
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </Container>
    </>
  );
}
