import { fetchDietariesStart, fetchDietariesSuccess, fetchDietariesFailure } from "../reducers/dietariesReducer";
import axios from "axios";

// Get dietaries
export const fetchDietaries = () => (dispatch) => {
  // Dispatch the start action
  dispatch(fetchDietariesStart());

  // API call
  axios
    .get(`${process.env.REACT_APP_API_HOSTNAME}/api/dietaries?pagination[start]=0&pagination[limit]=100`)
    .then((response) => {
      // Dispatch the success action
      dispatch(fetchDietariesSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch the error action
      dispatch(fetchDietariesFailure(error.message));
    });
};
