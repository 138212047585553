import { fetchRecipeStart, fetchRecipeSuccess, fetchRecipeFailure } from "../reducers/recipeReducer";
import Cookies from "js-cookie";
import axios from "axios";

// Get single recipe
export const fetchRecipe = (recipeName) => (dispatch) => {
  // Dispatch the start action
  dispatch(fetchRecipeStart());

  // Get the user token
  const userToken = Cookies.get("session-cookie");

  // API call
  axios
    .get(`${process.env.REACT_APP_API_HOSTNAME}/api/recipes/${recipeName}`, {
      headers: userToken ? { Authorization: `Bearer ${userToken}` } : {},
    })
    .then((response) => {
      // Dispatch the success action
      dispatch(fetchRecipeSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch the error action
      dispatch(fetchRecipeFailure(error.message));
    });
};
