// React
import React, { useState, useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { fetchUser, updateUser } from "../../actions/userActions";
import { Link as RouterLink } from "react-router-dom";
import Cookies from "js-cookie";
// UI
import { formatTime } from "../../custom/utilities";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  Box,
  Stack,
} from "@mui/material";
import { Star, BookmarkBorder, Bookmark, MoreHoriz, Verified, Schedule } from "@mui/icons-material";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import ShareButton from "./ShareButton";
import FollowMenuItem from "./FollowMenuItem";
import DeleteMenuItem from "./DeleteMenuItem";

export default function IndexCardY({ data }) {
  // Set dispathch
  const dispatch = useDispatch();

  // Auth Token
  const userToken = Cookies.get("session-cookie");

  // Get data from parent
  const recipes = data;

  // Prepare user data
  const user = useSelector((state) => state.user);
  const savedRecipes = user.data.saved_recipes;

  const [localSavedRecipes, setLocalSavedRecipes] = useState(savedRecipes) || "";

  const handleSaveRecipe = (recipe) => {
    if (userToken) {
      const newSavedRecipes = localSavedRecipes.some((e) => e.id === recipe?.id)
        ? localSavedRecipes.filter((savedRecipe) => savedRecipe.id !== recipe?.id)
        : [...localSavedRecipes, recipe];
      setLocalSavedRecipes(newSavedRecipes);
      dispatch(updateUser({ saved_recipes: newSavedRecipes }));
    } else {
      // Redirect to "/signin"
      window.location.href = "/signin";
    }
  };

  const isSaved = (recipe) => {
    if (localSavedRecipes) {
      return localSavedRecipes.some((e) => e.id === recipe?.id);
    }
  };

  // More menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [recipeId, setRecipeId] = React.useState(null);
  const [author, setAuthor] = React.useState(null);
  const [recipeSlug, setRecipeSlug] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, recipe) => {
    setAnchorEl(event.currentTarget);
    setRecipeId(recipe?.id);
    setAuthor(recipe.attributes.author.data);
    setRecipeSlug(recipe.attributes.slug);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Fetch user
  useEffect(() => {
    if (userToken) {
      dispatch(fetchUser());
    }
  }, [userToken, dispatch]);

  // Save recipes
  useEffect(() => {
    if (userToken) {
      setLocalSavedRecipes(savedRecipes);
    } else {
      setLocalSavedRecipes("");
    }
  }, [userToken, savedRecipes, setLocalSavedRecipes]);

  return (
    <>
      {recipes?.map((recipe) => {
        // Is the recipre new?
        const publishDate = new Date(recipe?.attributes?.createdAt);
        const currentDate = new Date();
        // Calculate the difference in days between the current date and the publish date
        const daysDifference = Math.floor((currentDate - publishDate) / (1000 * 3600 * 24));
        // Recipe is new if it has less than 30 days
        const isNew = daysDifference <= 30;

        // Preparation and cooking time
        const prepTime = recipe?.attributes?.prep_time_hrs * 60 + recipe?.attributes?.prep_time_mins;
        const cookTime = recipe?.attributes?.cook_time_hrs * 60 + recipe?.attributes?.cook_time_mins;
        const totalTime = prepTime + cookTime;

        const formattedTotalTime = formatTime(totalTime);

        return (
          <Grid key={recipe?.id} item xs={12} sm={6} md={4} lg={3} sx={{ display: "flex" }}>
            <Card
              variant="outlined"
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}>
              <CardActionArea
                component={RouterLink}
                to={`/recipes/${recipe?.attributes?.slug}`}
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 0,
                  textDecoration: "none",
                  color: "initial",
                }}>
                {recipe?.attributes?.cover &&
                  recipe.attributes.cover.data &&
                  recipe.attributes.cover.data.attributes && (
                    <CardMedia
                      sx={{
                        width: "100%",
                        aspectRatio: "2/1",
                      }}
                      image={recipe.attributes.cover.data.attributes.url}
                      title={recipe?.attributes?.title}>
                      {recipe?.attributes?.premium && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingTop: 2,
                            paddingRight: 2,
                          }}>
                          <Chip
                            icon={<Verified />}
                            label="Premium"
                            color="primary"
                            variant="outlined"
                            size="small"
                            sx={{
                              backgroundColor: "rgba(255, 255, 255, 0.9)",
                              border: "none",
                            }}
                          />
                        </Box>
                      )}
                    </CardMedia>
                  )}
                <CardContent sx={{ width: "100%" }}>
                  <Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2} mb={0.5}>
                    <Typography
                      variant="h6"
                      sx={{
                        flex: 1,
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}>
                      {recipe?.attributes?.title}
                    </Typography>
                    {isNew && (
                      <Box
                        pt={0.7}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: 14,
                          color: "primary.main",
                        }}>
                        <Star fontSize="small" sx={{ marginRight: 0.4 }} />
                        New
                      </Box>
                    )}
                  </Stack>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <Schedule fontSize="small" sx={{ marginRight: 0.5 }} /> {formattedTotalTime} •{" "}
                    {recipe?.attributes?.difficulty}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions
                sx={{
                  justifyContent: "space-between",
                  borderTop: 1,
                  borderColor: "grey.200",
                }}>
                {recipe?.attributes?.author?.data && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flex: "1 1 auto", // Added flex property to make the username shrink
                      minWidth: 0, // Allow the container to shrink
                      overflow: "hidden", // Hide overflowing content
                    }}>
                    <Box
                      as={RouterLink}
                      to={"/user/" + recipe.attributes.author.data.attributes.username}
                      sx={{ textDecoration: "none" }}>
                      <Avatar
                        alt={recipe?.attributes?.author?.data?.attributes?.username}
                        src={
                          recipe?.attributes?.author?.data?.attributes?.avatar?.data?.attributes?.formats?.thumbnail
                            ?.url
                        }
                        sx={{
                          width: 24,
                          height: 24,
                          marginRight: 1,
                        }}
                      />
                    </Box>
                    <Box
                      as={RouterLink}
                      to={"/user/" + recipe.attributes.author.data.attributes.username}
                      sx={{
                        flex: 1,
                        textDecoration: "none",
                      }}>
                      <Typography
                        variant="subtitle2"
                        color="primary"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}>
                        {recipe.attributes.author.data.attributes.username}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Stack direction="row" sx={{ flexShrink: 0 }}>
                  <ShareButton
                    title={recipe?.attributes?.title}
                    text={recipe?.attributes?.intro}
                    url={`${process.env.REACT_APP_WEB_HOSTNAME}/recipes/${recipe?.attributes?.slug}`}
                  />
                  <Button
                    onClick={() => handleSaveRecipe(recipe)}
                    startIcon={isSaved(recipe) ? <Bookmark /> : <BookmarkBorder />}
                    sx={{
                      color: isSaved(recipe) ? "teal.500" : "grey",
                    }}>
                    {isSaved(recipe) ? "Saved" : "Save"}
                  </Button>
                  <IconButton
                    color="primary"
                    aria-label="more options"
                    onClick={(event) => handleClick(event, recipe)}
                    sx={{
                      color: "grey",
                    }}>
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}>
                    <FollowMenuItem account={author} closeMenu={handleClose} />
                    {user?.id && user.id === author?.id ? (
                      <Box as={RouterLink} to={`/edit-recipe?title=${recipeSlug}`} sx={{ textDecoration: "none" }}>
                        <MenuItem
                          sx={{
                            color: "grey",
                          }}>
                          <ModeEditOutlinedIcon
                            sx={{
                              marginRight: "8px",
                            }}
                          />
                          <Typography variant="body2">Edit</Typography>
                        </MenuItem>
                      </Box>
                    ) : null}
                    <DeleteMenuItem recipeId={recipeId} author={author?.attributes?.username} closeMenu={handleClose} />
                    {/* <MenuItem
                                            onClick={handleClose}
                                            sx={{
                                                color: "grey",
                                            }}
                                        >
                                            <FlagOutlinedIcon
                                                sx={{
                                                    marginRight: "8px",
                                                }}
                                            />
                                            <Typography variant="body2">
                                                Report
                                            </Typography>
                                        </MenuItem> */}
                  </Menu>
                </Stack>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </>
  );
}
