// React
import React, { useState, useEffect } from "react";
// State
import { useSelector, useDispatch } from "react-redux";
import { fetchUser } from "../../actions/userActions";
import { createFollow, deleteFollow } from "../../actions/followActions";
import Cookies from "js-cookie";
// UI
import SlideUp from "../ui/Transitions";
import { Stack, Typography, Button, Dialog, DialogTitle, DialogContent, MenuItem } from "@mui/material";
import { Add, Check } from "@mui/icons-material";

export default function FollowMenuItem({ account, closeMenu }) {
  // Set dispatch
  const dispatch = useDispatch();

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // Prepare user data
  const user = useSelector((state) => state.user);

  const isFollowing = user.data.following;

  const [localFollowing, setLocalFollowing] = useState(isFollowing) || "";

  const closeParentMenu = () => {
    // Call the closeMenu prop
    closeMenu();
  };

  const handleFollow = (account) => {
    if (userToken) {
      const newFollow = localFollowing.some((e) => e.following.id === account.id)
        ? localFollowing.filter((e) => e.following.id !== account.id)
        : [...localFollowing, account];
      setLocalFollowing(newFollow);

      // Update back-end
      if (localFollowing.some((e) => e.following.id === account.id)) {
        // If already liked, find the corresponding likeId
        const followId = localFollowing.find((followObj) => followObj.following.id === account.id)?.id;
        // Delete the like
        dispatch(deleteFollow(followId));
      } else {
        // If not liked, create a new like
        const followData = {
          data: {
            follower: user.data.id,
            following: account.id,
          },
        };
        dispatch(createFollow(followData));
      }
      closeParentMenu();
    } else {
      // Redirect to "/signin"
      window.location.href = "/signin";
    }
  };

  // Confirm unfollow
  const [openConfirm, setOpen] = React.useState(false);

  const handleOpenConfirm = () => {
    setOpen(true);
  };

  const closeConfirm = () => {
    setOpen(false);
  };

  const checkFollow = (account) => {
    if (localFollowing) {
      return localFollowing.some((e) => e.following?.id === account.id);
    }
  };

  // Fetch user
  useEffect(() => {
    if (userToken) {
      dispatch(fetchUser());
    }
  }, [userToken, dispatch]);

  // Save follow
  useEffect(() => {
    if (userToken) {
      setLocalFollowing(isFollowing);
    } else {
      setLocalFollowing("");
    }
  }, [userToken, isFollowing, setLocalFollowing]);

  // Don't follow yourself
  const userName = user?.data?.username;
  if (user.id && (userName === account?.username || userName === account?.attributes?.username)) {
    return;
  }

  return (
    <div>
      <MenuItem onClick={checkFollow(account) ? handleOpenConfirm : () => handleFollow(account)} sx={{ color: "grey" }}>
        {checkFollow(account) ? <Check sx={{ marginRight: "8px" }} /> : <Add sx={{ marginRight: "8px" }} />}
        <Typography variant="body2">
          {checkFollow(account) ? "Following" : "Follow"} {account?.attributes?.username}
        </Typography>
      </MenuItem>

      <Dialog
        open={openConfirm}
        TransitionComponent={SlideUp}
        keepMounted
        onClose={closeConfirm}
        maxWidth="xs"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Unfollow {account?.attributes?.username || account.username}
        </DialogTitle>
        <DialogContent>
          <Typography marginBottom={4}>
            You'll no longer receive notifications from this account, and you won't see its updates in your feed.
          </Typography>
          <Stack direction="row" spacing={2} sx={{ justifyContent: "end" }}>
            <Button onClick={closeConfirm} variant="outlined" sx={{ whiteSpace: "nowrap" }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleFollow(account);
                closeConfirm();
              }}
              variant="contained"
              disableElevation
              sx={{ whiteSpace: "nowrap" }}>
              Unfollow
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
