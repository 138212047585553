import {
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
} from "../reducers/passwordReducer";
import Cookies from "js-cookie";
import axios from "axios";

// Reset password
export const resetPassword = (passwordFields) => (dispatch) => {
  // Dispatch the start action
  dispatch(resetPasswordStart());

  // API call
  axios
    .post(`${process.env.REACT_APP_API_HOSTNAME}/api/auth/reset-password`, passwordFields)
    .then((response) => {
      // Dispatch the success action
      dispatch(resetPasswordSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch the error action
      dispatch(resetPasswordFailure(error.response.data.error));
    });
};

// Change password
export const changePassword = (passwordFields) => (dispatch) => {
  // Dispatch the start action
  dispatch(changePasswordStart());

  // Get user token
  const userToken = Cookies.get("session-cookie");

  //API call
  axios
    .post(`${process.env.REACT_APP_API_HOSTNAME}/api/auth/change-password`, passwordFields, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
    .then((response) => {
      // Dispatch the success action
      dispatch(changePasswordSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch the error action
      dispatch(changePasswordFailure(error.response.data.error));
    });
};
