// Redux
import { fetchRecipesStart, fetchRecipesSuccess, fetchRecipesFailure } from "../reducers/recipesReducer";
// Libraries
import Cookies from "js-cookie";
import axios from "axios";

export const fetchAuthorRecipes = (author, pagination) => (dispatch) => {
  // Dispatch the start action
  dispatch(fetchRecipesStart());

  // Get the user token
  const userToken = Cookies.get("session-cookie");

  // API's endpoint
  let apiUrl = `${process.env.REACT_APP_API_HOSTNAME}/api/recipes?populate=*`;

  // API's author filters
  if (author) {
    apiUrl += `&filters[$and][0][author][username][$eq]=${author}`;
  }

  // API's sort and pagination
  if (pagination) {
    apiUrl += `&pagination[start]=${pagination.start}&pagination[limit]=${pagination.limit}&sort=createdAt:desc`;
  }

  // Axios call
  axios
    .get(apiUrl, {
      headers: userToken ? { Authorization: `Bearer ${userToken}` } : {},
    })
    .then((response) => {
      dispatch(fetchRecipesSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(fetchRecipesFailure(error.message));
    });
};
