import { createSlice } from "@reduxjs/toolkit";

const followSlice = createSlice({
  name: "follow",
  initialState: {
    data: [],
    isLoading: false,
    success: false,
    error: null,
  },
  reducers: {
    createFollowStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createFollowSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.id = action.payload.id;
      state.success = true;
      state.error = null;
    },
    createFollowFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    deleteFollowStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    deleteFollowSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.id = action.payload.id;
      state.success = true;
      state.error = null;
    },
    deleteFollowFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  createFollowStart,
  createFollowSuccess,
  createFollowFailure,
  deleteFollowStart,
  deleteFollowSuccess,
  deleteFollowFailure,
} = followSlice.actions;

export default followSlice.reducer;
