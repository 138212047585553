import { createSlice } from "@reduxjs/toolkit";

const replySlice = createSlice({
  name: "reply",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    addReplyStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    addReplySuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.id = action.payload.id;
    },
    addReplyFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteReplyStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    deleteReplySuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.id = action.payload.id;
    },
    deleteReplyFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  addReplyStart,
  addReplySuccess,
  addReplyFailure,
  deleteReplyStart,
  deleteReplySuccess,
  deleteReplyFailure,
} = replySlice.actions;

export default replySlice.reducer;
