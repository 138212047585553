import {
  addReplyStart,
  addReplySuccess,
  addReplyFailure,
  deleteReplyStart,
  deleteReplySuccess,
  deleteReplyFailure,
} from "../reducers/replyReducer";
import Cookies from "js-cookie";
import axios from "axios";

// Post reply
export const postReply = (userID, commentID, commentText) => (dispatch) => {
  // Dispatch the start action
  dispatch(addReplyStart());

  // Get the user toekn
  const userToken = Cookies.get("session-cookie");

  // Prepare data
  const replyData = {
    data: {
      user: userID,
      comment: commentID,
      reply: commentText,
    },
  };

  // API call
  axios
    .post(`${process.env.REACT_APP_API_HOSTNAME}/api/replies`, replyData, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
    .then((response) => {
      // Dispatch the success action
      dispatch(addReplySuccess(response.data));
    })
    .catch((error) => {
      // Dispatch the error action
      dispatch(addReplyFailure(error.message));
    });
};

// Remove reply
export const deleteReply = (replyItemId) => (dispatch) => {
  // Dispatch the start action
  dispatch(deleteReplyStart());

  // Get the user userToken
  const userToken = Cookies.get("session-cookie");

  axios
    .delete(`${process.env.REACT_APP_API_HOSTNAME}/api/replies/${replyItemId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
    .then((response) => {
      // Dispatch success
      dispatch(deleteReplySuccess(response.data));
    })
    .catch((error) => {
      // Dispatch error
      dispatch(deleteReplyFailure(error));
    });
};
