import { fetchCuisinesStart, fetchCuisinesSuccess, fetchCuisinesFailure } from "../reducers/cuisinesReducer";
import axios from "axios";

// Get cuisines
export const fetchCuisines = () => (dispatch) => {
  // Dispatch the start action
  dispatch(fetchCuisinesStart());

  // API call
  axios
    .get(`${process.env.REACT_APP_API_HOSTNAME}/api/cuisines?pagination[start]=0&pagination[limit]=100`)
    .then((response) => {
      // Dispatch the success action
      dispatch(fetchCuisinesSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch the error action
      dispatch(fetchCuisinesFailure(error.message));
    });
};
