import {
  addCommentStart,
  addCommentSuccess,
  addCommentFailure,
  deleteCommentStart,
  deleteCommentSuccess,
  deleteCommentFailure,
} from "../reducers/commentsReducer";
import Cookies from "js-cookie";
import axios from "axios";

// Post comment
export const postComments = (userID, recipeID, commentText) => (dispatch) => {
  // Dispatch the start action
  dispatch(addCommentStart());

  // Get the user token
  const userToken = Cookies.get("session-cookie");

  // Prepare data
  const commentsData = {
    data: {
      user: userID,
      recipe: recipeID,
      comment: commentText,
    },
  };

  // API call
  axios
    .post(`${process.env.REACT_APP_API_HOSTNAME}/api/comments`, commentsData, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
    .then((response) => {
      // Dispatch the success action
      dispatch(addCommentSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch the error action
      dispatch(addCommentFailure(error.message));
    });
};

// Delete comment
export const deleteComment = (commentItemId) => (dispatch) => {
  // Dispatch the start action
  dispatch(deleteCommentStart());

  // Get the user token
  const userToken = Cookies.get("session-cookie");

  // API call
  axios
    .delete(`${process.env.REACT_APP_API_HOSTNAME}/api/comments/${commentItemId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
    .then((response) => {
      // Dispatch the success action
      dispatch(deleteCommentSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch the error action
      dispatch(deleteCommentFailure(error));
    });
};
