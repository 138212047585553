// React
import React, { useEffect, useState } from "react";
// State
import { useSelector, useDispatch } from "react-redux";
import { fetchRecipe } from "../../actions/recipeActions";
import { createLike, deleteLike } from "../../actions/likesActions";
import { updateUser } from "../../actions/userActions";
import { Link as RouterLink } from "react-router-dom";
import Cookies from "js-cookie";
// Components
import ShareButton from "./ShareButton";
import DeleteMenuItem from "./DeleteMenuItem";
// UI
import { Box, LinearProgress, Button, IconButton, Stack, Menu, MenuItem, Typography } from "@mui/material";
import { Favorite, FavoriteBorder, Bookmark, BookmarkBorder, CommentOutlined, MoreHoriz } from "@mui/icons-material";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";

export default function SocialBar({ comments }) {
  // Set dispatch
  const dispatch = useDispatch();

  // Auth Token
  const userToken = Cookies.get("session-cookie");

  // User state
  const user = useSelector((state) => state.user);

  // Recipe state
  const recipe = useSelector((state) => state.recipe.data);

  // Set variables
  const likedRecipes = (user.data.likes ?? []).filter((like) => like.recipe);
  const savedRecipes = user.data.saved_recipes;
  const [localLikedRecipes, setLocalLikedRecipes] = useState(likedRecipes);
  const [localSavedRecipes, setLocalSavedRecipes] = useState(savedRecipes);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth >= 768);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // Likes
  const [likeCount, setLikeCount] = useState(0);

  // Use useEffect to update likeCount when recipe changes
  useEffect(() => {
    const initialLikeCount = recipe.attributes && recipe.attributes.likes?.data.length;
    if (typeof initialLikeCount === "number") {
      setLikeCount(initialLikeCount);
    }
  }, [recipe]);

  // Prevent multiple likes or unlikes
  const [isLikeProcessing, setIsLikeProcessing] = useState(false);

  // Handle like
  const handleLikeRecipe = async (recipe) => {
    // Like feature is for authenticated users only
    if (userToken) {
      // Check if like function is already in process
      if (!isLikeProcessing) {
        // Disable like function while one is already in process
        setIsLikeProcessing(true);

        // Update local likes
        const isRecipeLiked = localLikedRecipes.some((e) => e.recipe && e.recipe.id === recipe.id);
        const newLikedRecipes = isRecipeLiked
          ? localLikedRecipes.filter((likedRecipe) => likedRecipe.id !== recipe.id)
          : [...localLikedRecipes, recipe];
        setLocalLikedRecipes(newLikedRecipes);

        // Update backend likes
        if (isRecipeLiked) {
          // If already liked, find the corresponding likeId
          const likeId = localLikedRecipes.find((likedRecipe) => likedRecipe.recipe?.id === recipe.id)?.id;
          // Delete the like
          dispatch(deleteLike(likeId));
        } else {
          // If not liked, create a new like
          const likeData = {
            data: {
              user: user.data.id,
              recipe: recipe.id,
            },
          };
          dispatch(createLike(likeData));
        }

        // Update like count
        setLikeCount(isRecipeLiked ? likeCount - 1 : likeCount + 1);

        // Enable like function again
        // Note: setTimeout prevents multiple like operations (e.g. tapping like button twice quicly)
        // This is a temporary solution, we shouldn't rely on setTimeout to prevent multiple likes
        setTimeout(() => {
          setIsLikeProcessing(false);
        }, 500);
      }
    } else {
      // Redirect to "/signin"
      window.location.href = "/signin";
    }
  };

  const isLiked = (recipe) => {
    if (localLikedRecipes) {
      return localLikedRecipes.some((e) => e.recipe?.id === recipe.id);
    }
  };

  const commentsProps = () => {
    if (userToken) {
      // Open the comments dialog in the parent component using props
      comments();
    } else {
      // Redirect to "/signin"
      window.location.href = "/signin";
    }
  };

  const handleSaveRecipe = (recipe) => {
    if (userToken) {
      const newSavedRecipes = localSavedRecipes.some((e) => e.id === recipe.id)
        ? localSavedRecipes.filter((savedRecipe) => savedRecipe.id !== recipe.id)
        : [...localSavedRecipes, recipe];
      setLocalSavedRecipes(newSavedRecipes);
      dispatch(updateUser({ saved_recipes: newSavedRecipes }));
    } else {
      // Redirect to "/signin"
      window.location.href = "/signin";
    }
  };

  const isSaved = (recipe) => {
    if (localSavedRecipes) {
      return localSavedRecipes.some((e) => e.id === recipe.id);
    }
  };

  const handleClick = (event, recipe) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    const recipeName = pathname.replace("/recipes/", "");
    dispatch(fetchRecipe(recipeName));
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Save like
  useEffect(() => {
    // Check if the arrays are different
    if (
      likedRecipes.length !== localLikedRecipes.length ||
      !likedRecipes.every((like, index) => like.recipe.id === localLikedRecipes[index].recipe?.id)
    ) {
      setLocalLikedRecipes(likedRecipes);
    }
  }, [likedRecipes, localLikedRecipes]);

  // Save recipe
  useEffect(() => {
    setLocalSavedRecipes(savedRecipes);
  }, [savedRecipes]);

  // Check if recipe is null or the data property is not available yet
  if (!recipe || !recipe.attributes) {
    return <LinearProgress />;
  }

  return (
    <Stack direction="row" spacing={{ md: 4 }} justifyContent="space-between" padding={2}>
      <Button
        onClick={() => handleLikeRecipe(recipe)}
        startIcon={isLiked(recipe) ? <Favorite /> : <FavoriteBorder />}
        sx={{
          color: isLiked(recipe) ? "teal.500" : "grey",
          flex: 1,
        }}>
        {smallScreen
          ? likeCount === 0
            ? "Be the first like!"
            : `${likeCount} ${likeCount === 1 ? "Like" : "Likes"}`
          : likeCount === 0
          ? null
          : `${likeCount}`}
      </Button>
      <Button
        onClick={commentsProps}
        startIcon={<CommentOutlined />}
        sx={{
          color: "grey",
          flex: 1,
        }}>
        {smallScreen
          ? recipe.attributes.comments?.data.length === 0
            ? "Start the chat"
            : `${recipe.attributes.comments?.data.length} ${
                recipe.attributes.comments?.data.length === 1 ? "Comment" : "Comments"
              }`
          : likeCount === 0
          ? null
          : `${recipe.attributes.comments?.data.length}`}
      </Button>
      <Button
        onClick={() => handleSaveRecipe(recipe)}
        startIcon={isSaved(recipe) ? <Bookmark /> : <BookmarkBorder />}
        sx={{
          color: isSaved(recipe) ? "teal.500" : "grey",
          flex: 1,
        }}>
        {smallScreen ? (isSaved(recipe) ? "Saved" : "Save") : ""}
      </Button>
      <ShareButton
        title={recipe?.attributes?.title}
        text={recipe?.attributes?.intro}
        url={`${process.env.REACT_APP_WEB_HOSTNAME}/recipes/${recipe?.attributes?.slug}`}
      />
      <IconButton id="basic-button" onClick={handleClick}>
        <MoreHoriz />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        {user?.id && user?.id === recipe.attributes.author.data.id ? (
          <Box as={RouterLink} to={`/edit-recipe?title=${recipe.attributes.slug}`} sx={{ textDecoration: "none" }}>
            <MenuItem
              sx={{
                color: "grey",
              }}>
              <ModeEditOutlinedIcon
                sx={{
                  marginRight: "8px",
                }}
              />
              <Typography variant="body2">Edit</Typography>
            </MenuItem>
          </Box>
        ) : null}
        <DeleteMenuItem
          recipeId={recipe.id}
          author={recipe.attributes.author.data.attributes.username}
          closeMenu={handleClose}
        />
        <MenuItem
          onClick={handleClose}
          sx={{
            color: "grey",
          }}>
          <FlagOutlinedIcon
            sx={{
              marginRight: "8px",
            }}
          />
          <Typography variant="body2">Report</Typography>
        </MenuItem>
      </Menu>
    </Stack>
  );
}
