// React
import React, { useEffect } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { fetchUser } from "../../actions/userActions";
import { deletePost } from "../../actions/postActions";
// UI
import { Stack, Typography, Button, Dialog, DialogTitle, DialogContent, MenuItem } from "@mui/material";
import SlideUp from "../ui/Transitions";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

export default function DeleteMenuItem({ recipeId, author, closeMenu }) {
  // Set dispatch
  const dispatch = useDispatch();

  // Prepare user data
  const user = useSelector((state) => state.user);

  const closeParentMenu = () => {
    // Call the closeMenu prop
    closeMenu();
  };

  const handleDelete = (recipeId) => {
    dispatch(deletePost(recipeId));
    closeParentMenu();
  };

  // Confirm unfollow
  const [openConfirm, setOpen] = React.useState(false);

  const handleOpenConfirm = () => {
    setOpen(true);
  };

  const closeConfirm = () => {
    setOpen(false);
  };

  // Fetch user
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  // Remove your own posts only
  if (user?.data?.username !== author) {
    return;
  }

  return (
    <>
      <MenuItem onClick={handleOpenConfirm} sx={{ color: "grey" }}>
        <DeleteOutlinedIcon sx={{ marginRight: "8px" }} />
        <Typography variant="body2">Delete</Typography>
      </MenuItem>

      <Dialog
        open={openConfirm}
        TransitionComponent={SlideUp}
        keepMounted
        onClose={closeConfirm}
        maxWidth="xs"
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>Remove post</DialogTitle>
        <DialogContent>
          <Typography marginBottom={4}>This post will be removed competely and cannot be recovered.</Typography>
          <Stack direction="row" spacing={2} sx={{ justifyContent: "end" }}>
            <Button onClick={closeConfirm} variant="outlined" sx={{ whiteSpace: "nowrap" }}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleDelete(recipeId);
                closeConfirm();
              }}
              variant="contained"
              disableElevation
              sx={{ whiteSpace: "nowrap" }}>
              Delete
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
