import {
  createLikeStart,
  createLikeSuccess,
  createLikeFailure,
  deleteLikeStart,
  deleteLikeSuccess,
  deleteLikeFailure,
} from "../reducers/likesReducer";
import { fetchUser } from "./userActions";
import Cookies from "js-cookie";
import axios from "axios";

// Create like
export const createLike = (likeData) => (dispatch) => {
  // Dispatch the start action
  dispatch(createLikeStart());

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // API call
  axios
    .post(`${process.env.REACT_APP_API_HOSTNAME}/api/likes`, likeData, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
    .then((response) => {
      // Dispatch the success action
      dispatch(createLikeSuccess(response.data.data));
      // Fetch user data to sync the user likes
      dispatch(fetchUser());
    })
    .catch((error) => {
      // Dispatch the error action
      dispatch(createLikeFailure(error.message));
    });
};

// Delete like
export const deleteLike = (likeId) => (dispatch) => {
  // Dispatch the start action
  dispatch(deleteLikeStart());

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // API call
  axios
    .delete(`${process.env.REACT_APP_API_HOSTNAME}/api/likes/${likeId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
    .then((response) => {
      // Dispatch the success action
      dispatch(deleteLikeSuccess(response.data.data));
      // Fetch user data to sync the user likes
      dispatch(fetchUser());
    })
    .catch((error) => {
      // Dispatch the error action
      dispatch(deleteLikeFailure(error.message));
    });
};
