import { fetchAllergiesStart, fetchAllergiesSuccess, fetchAllergiesFailure } from "../reducers/allergiesReducer";
import axios from "axios";

// Get allergies
export const fetchAllergies = () => (dispatch) => {
  // Dispatch the start action
  dispatch(fetchAllergiesStart());

  // API call
  axios
    .get(`${process.env.REACT_APP_API_HOSTNAME}/api/allergies?pagination[start]=0&pagination[limit]=100`)
    .then((response) => {
      // Dispatch the success action
      dispatch(fetchAllergiesSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch the error action
      dispatch(fetchAllergiesFailure(error.message));
    });
};
