// React
import React, { useState, useEffect } from "react";
// State
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { fetchRecipe } from "../../actions/recipeActions";
import { fetchUser } from "../../actions/userActions";
import { fetchAllergies } from "../../actions/allergiesActions";
import { fetchDietaries } from "../../actions/dietariesActions";
import { fetchCuisines } from "../../actions/cuisinesActions";
import { createPost, editPost } from "../../actions/postActions";
import { useNavigate } from "react-router-dom";
// Libraries
import { Helmet } from "react-helmet";
import heic2any from "heic2any";
// UI
import { teal } from "@mui/material/colors";
import { Avatar, Container, Grid, Card, CardContent, Stack, Box, Typography, Button, IconButton, TextField, Select, MenuItem, Autocomplete, Divider, Alert, CircularProgress } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Remove } from "@mui/icons-material";

export default function EditRecipe() {
  // Set dispathch
  const dispatch = useDispatch();

  // Auth Token
  const userToken = Cookies.get("session-cookie");

  // Prepare user data
  const user = useSelector((state) => state.user);

  // Router nav (we also pass it as prop to use in Redux)
  const navigate = useNavigate();

  // Allergies
  const allergiesObj = useSelector((state) => state.allergies.data) || [];
  const allergies = allergiesObj.map((item) => ({
    id: item.id,
    name: item.attributes.name,
  }));

  // Dietaries
  const dietariesObj = useSelector((state) => state.dietaries.data) || [];
  const dietaries = dietariesObj.map((item) => ({
    id: item.id,
    name: item.attributes.name,
  }));

  // Cuisines
  const cuisinesObj = useSelector((state) => state.cuisines.data) || [];
  const cuisines = cuisinesObj.map((item) => ({
    id: item.id,
    name: item.attributes.name,
  }));

  // Post files
  const [files, setFiles] = useState();
  const [filePreview, setFilePreview] = useState(null);
  const [previewLoading, setPreviewLoading] = useState(false);

  // Async function to handle file change
  const handleFileChange = async (event) => {
    // Remove preview
    setFilePreview(null);

    // Set loading state to true
    setPreviewLoading(true);

    // Get the selected file
    const selectedFile = event.target.files[0];

    // Set postFile to null by default
    let postFile = null;

    // Check if the selected file is HEIC
    if (selectedFile?.type === "image/heic") {
      // Get image as blob URL
      const blobURL = URL.createObjectURL(selectedFile);

      // Convert "fetch" the new blob URL
      const blobRes = await fetch(blobURL);

      // Convert response to blob
      const blob = await blobRes.blob();

      // Convert HEIC to JPG
      postFile = await heic2any({
        blob,
        toType: "image/jpeg",
        quality: 0.5,
      });
    } else {
      // Default to the original file
      postFile = selectedFile;
    }

    // Set loading state to false
    setPreviewLoading(false);

    // Set file
    setFiles(postFile);

    // Set preview
    setFilePreview(URL.createObjectURL(postFile));
  };

  // Post fields
  const [fieldValues, setFieldValues] = useState({
    author: "",
    title: "",
    intro: "",
    prep_time_hours: 0,
    prep_time_mins: 0,
    cook_time_hours: 0,
    cook_time_mins: 0,
    difficulty: "",
    calories: 0,
    allergies: [],
    dietaries: [],
    cuisines: [],
    ingredients: [],
    cooking_steps: [],
    cooking_tips: "",
    authors_note: "",
  });

  // Handle post fields
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFieldValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Ingredients
  const [ingredients, setIngredients] = useState([]);

  const addIngredientField = () => {
    setIngredients([...ingredients, {}]);
    setFieldValues((prevValues) => ({
      ...prevValues,
      ingredients: [...prevValues.ingredients, { quantity: "", unit: "", ingredient: "" }],
    }));
  };

  const removeIngredientField = (index) => {
    const updatedIngredients = [...ingredients];
    updatedIngredients.splice(index, 1);
    setIngredients(updatedIngredients);

    setFieldValues((prevValues) => {
      const newIngredients = [...prevValues.ingredients];
      newIngredients.splice(index, 1);
      return {
        ...prevValues,
        ingredients: newIngredients,
      };
    });
  };

  const handleIngredientChange = (index, fieldName, value) => {
    setFieldValues((prevValues) => {
      const newIngredients = [...prevValues.ingredients];
      newIngredients[index] = {
        ...newIngredients[index],
        [fieldName]: value,
      };
      return {
        ...prevValues,
        ingredients: newIngredients,
      };
    });
  };

  // Cooking steps
  const [steps, setSteps] = useState([]);

  const addStepField = () => {
    setSteps([...steps, {}]);
    setFieldValues((prevValues) => ({
      ...prevValues,
      cooking_steps: [...prevValues.cooking_steps, { step: "" }],
    }));
  };

  const removeStepField = (index) => {
    const updatedSteps = [...steps];
    updatedSteps.splice(index, 1);
    setSteps(updatedSteps);

    setFieldValues((prevValues) => {
      const updatedCookingSteps = [...prevValues.cooking_steps];
      updatedCookingSteps.splice(index, 1);
      return {
        ...prevValues,
        cooking_steps: updatedCookingSteps,
      };
    });
  };

  const handleStepChange = (index, value) => {
    setFieldValues((prevValues) => {
      const updatedCookingSteps = [...prevValues.cooking_steps];
      updatedCookingSteps[index] = { step: value };
      return {
        ...prevValues,
        cooking_steps: updatedCookingSteps,
      };
    });
  };

  // Get exisitng recipe
  const urlParams = new URLSearchParams(window.location.search);
  const recipeName = urlParams.get("title");
  const existingRecipe = useSelector((state) => state.recipe?.data);

  // Prepopulate existing fields
  useEffect(() => {
    if (recipeName && existingRecipe?.attributes?.author?.data?.id === user.id) {
      setFieldValues((prevValues) => ({
        ...prevValues,
        title: existingRecipe?.attributes?.title,
        intro: existingRecipe?.attributes?.intro,
        prep_time_hours: existingRecipe?.attributes?.prep_time_hours,
        prep_time_mins: existingRecipe?.attributes?.prep_time_mins,
        cook_time_hours: existingRecipe?.attributes?.cook_time_hours,
        cook_time_mins: existingRecipe?.attributes?.cook_time_mins,
        difficulty: existingRecipe?.attributes?.difficulty,
        calories: existingRecipe?.attributes?.calories,
        allergies: (existingRecipe?.attributes?.allergies?.data || []).map((allergy) => ({
          id: allergy.id,
          name: allergy.attributes.name,
        })),
        dietaries: (existingRecipe?.attributes?.dietaries?.data || []).map((dietary) => ({
          id: dietary.id,
          name: dietary.attributes.name,
        })),
        cuisines: (existingRecipe?.attributes?.cuisines?.data || []).map((cuisine) => ({
          id: cuisine.id,
          name: cuisine.attributes.name,
        })),
        cooking_tips: existingRecipe?.attributes?.cooking_tips,
        authors_note: existingRecipe?.attributes?.authors_note,
      }));
      // Add ingredient fields
      if (existingRecipe?.attributes?.ingredients && existingRecipe.attributes.ingredients.length > 0) {
        // Create an array to hold the prepopulated ingredients
        const prepopulatedIngredients = existingRecipe.attributes.ingredients.map((ingredient) => ({
          quantity: ingredient.quantity || "",
          unit: ingredient.unit || "",
          ingredient: ingredient.ingredient || "",
        }));
        // Set the prepopulated ingredients in one go
        setFieldValues((prevValues) => ({
          ...prevValues,
          ingredients: prepopulatedIngredients,
        }));
        // Unhide existing fields
        setIngredients(() => [...prepopulatedIngredients]);
      }

      // Add cooking steps fields
      if (existingRecipe?.attributes?.cooking_steps && existingRecipe.attributes.cooking_steps.length > 0) {
        // Create an array to hold the prepopulated cooking steps
        const prepopulatedCookingSteps = existingRecipe.attributes.cooking_steps.map((step) => ({
          id: step.id || "",
          step: step.step || "",
        }));

        // Set the prepopulated cooking steps in one go
        setFieldValues((prevValues) => ({
          ...prevValues,
          cooking_steps: prepopulatedCookingSteps,
        }));
        // Unhide existing fields
        setSteps(() => [...prepopulatedCookingSteps]);
      }
    }
  }, [recipeName, existingRecipe, user, setFieldValues]);

  // Validation errors
  const [validationErrors, setValidationErrors] = useState([]);

  // Form validation
  const handleFormValidation = (event) => {
    event.preventDefault();

    // Required fields
    const requiredFields = [
      { name: "Title", value: fieldValues.title },
      { name: "Cooking Time (Mins)", value: fieldValues.cook_time_mins },
      { name: "Difficulty", value: fieldValues.difficulty },
      { name: "Ingredient", value: fieldValues.ingredients },
      { name: "Cooking Step", value: fieldValues.cooking_steps },
    ];

    // Image is required only if the file preview is empty
    if (!filePreview) {
      requiredFields.unshift({ name: "Image", value: document.querySelector("[name=files]").files[0] });
    }

    // Preprare errors array
    const errors = [];

    // Validate form
    requiredFields.forEach(({ name, value }) => {
      if (!value || (typeof value === "string" && !value.trim())) {
        // Single fields
        errors.push(`${name} is required`);
      } else if (name === "Ingredient" && Array.isArray(value) && value.length === 0) {
        // Ingredients is empty
        errors.push(`${name} is required`);
      } else if (Array.isArray(value) && value.length > 0) {
        // Ingredient field is incomplete
        value.forEach((ingredient, index) => {
          if (
            !ingredient ||
            Object.values(ingredient).some((field) => {
              // Check if the field is a string before calling trim
              return typeof field === "string" && !field.trim();
            })
          ) {
            // At least one field inside the nested object is empty
            errors.push(`${name} ${index + 1} is incomplete`);
          }
        });
      } else if (name === "Cooking Step" && Array.isArray(value) && value.length === 0) {
        // Cooking steps is empty
        errors.push(`${name} is required`);
      } else if (Array.isArray(value) && value.length > 0) {
        // Cooking step is incomplete
        value.forEach((ingredient, index) => {
          if (!ingredient || Object.values(ingredient).some((field) => !field.trim())) {
            // At least one field inside the nested object is empty
            errors.push(`${name} ${index + 1} is incomplete`);
          }
        });
      }
    });

    // Set validation errors for UX
    setValidationErrors(errors);

    // Submit the form if there are 0 erros
    if (errors.length === 0) {
      saveRecipe();
    }
  };

  // Handle post submission
  const saveRecipe = async () => {
    // Create a copy of fieldValues
    const postData = { ...fieldValues };

    // Assign author ID
    postData.author = `${user?.data?.id}`;

    // Send post data to API
    if (!recipeName) {
      // New post
      await dispatch(createPost(postData, files, navigate));
    } else {
      // Existing post
      const recipeId = existingRecipe.id;
      await dispatch(editPost(recipeId, postData, files, navigate));
    }
  };

  // Get post state
  const post = useSelector((state) => state.post);

  // Post progress
  const [postProgress, setPostProgress] = useState(false);

  // API Status
  useEffect(() => {
    if (post.isLoading) {
      // Loading
      setPostProgress(true);
    } else if (post.success) {
      // Success
      setPostProgress(false);
      setFieldValues(
        // Reset fields only if response is successful
        Object.fromEntries(Object.keys(fieldValues).map((key) => [key, ""]))
      );
    } else if (post.error) {
      // Error
      setPostProgress(false);
    }
  }, [post, setPostProgress, setFieldValues, fieldValues]);

  // Fetch user
  useEffect(() => {
    if (userToken) {
      dispatch(fetchUser());
    } else {
      navigate("/signin");
    }
  }, [userToken, dispatch, navigate]);

  // Set the initial image preview if it exists
  useEffect(() => {
    if (recipeName && existingRecipe?.attributes?.author?.data?.id === user.id) {
      if (existingRecipe?.attributes?.cover?.data?.attributes?.formats?.medium) {
        setFilePreview(existingRecipe?.attributes?.cover?.data?.attributes?.formats?.medium?.url);
      } else if (existingRecipe?.attributes?.cover?.data?.attributes?.formats?.small) {
        setFilePreview(existingRecipe?.attributes?.cover?.data?.attributes?.formats?.small?.url);
      }
    }
  }, [recipeName, existingRecipe, user]);

  // Fetch Filters
  useEffect(() => {
    dispatch(fetchAllergies());
    dispatch(fetchDietaries());
    dispatch(fetchCuisines());
  }, [dispatch]);

  // Fetch existing recipe
  useEffect(() => {
    if (recipeName) {
      dispatch(fetchRecipe(recipeName));
    }
  }, [recipeName, dispatch]);

  return (
    <>
      <Helmet>
        <title>{`${!recipeName ? "Create a new recipe" : "Edit recipe"} - Dishly`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Create and edit your Dishly recipe." />
        <meta property="og:title" content={!recipeName ? "Create a new recipe" : "Edit recipe"} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Create and edit your Dishly recipe." />
      </Helmet>
      <Container maxWidth="sm" sx={{ flex: 1, marginY: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4" marginBottom={4}>
              {!recipeName ? "Create a new recipe" : "Edit recipe"}
            </Typography>
            <Card variant="outlined">
              <CardContent>
                <form onSubmit={handleFormValidation} noValidate>
                  <Stack spacing={3}>
                    <Stack spacing={1}>
                      <Typography variant="subtitle2">Cover Image</Typography>
                      {previewLoading && (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            aspectRatio: "2/1",
                            objectFit: "cover", // Hide excess content
                          }}>
                          <CircularProgress />
                        </Box>
                      )}
                      {filePreview && (
                        <Box
                          component="img"
                          src={filePreview}
                          style={{
                            width: "100%",
                            aspectRatio: "2/1",
                            objectFit: "cover", // Hide excess content
                          }}
                        />
                      )}
                      <input type="file" name="files" accept="image/*, .HEIC" onChange={handleFileChange} data-label="Image" />
                    </Stack>
                    <Box>
                      <Typography variant="subtitle2" marginBottom={1}>
                        Title
                      </Typography>
                      <TextField name="title" value={fieldValues.title} onChange={handleChange} variant="outlined" fullWidth data-label="Title" />
                    </Box>
                    <Box>
                      <Stack direction="row" spacing={0.5} marginBottom={1} sx={{ alignItems: "center" }}>
                        <Typography variant="subtitle2">Intro</Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: "grey",
                          }}>
                          optional
                        </Typography>
                      </Stack>
                      <TextField name="intro" value={fieldValues.intro} onChange={handleChange} variant="outlined" fullWidth />
                    </Box>
                    <Divider />
                    <Stack direction="row" spacing={3}>
                      <Box width="50%">
                        <Stack
                          direction="row"
                          spacing={0.5}
                          marginBottom={1}
                          sx={{
                            alignItems: "center",
                          }}>
                          <Typography variant="subtitle2">Preparation Time (Hrs)</Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "grey",
                            }}>
                            optional
                          </Typography>
                        </Stack>
                        <TextField type="number" name="prep_time_hours" value={fieldValues.prep_time_hours} onChange={handleChange} variant="outlined" fullWidth />
                      </Box>
                      <Box width="50%">
                        <Stack
                          direction="row"
                          spacing={0.5}
                          marginBottom={1}
                          sx={{
                            alignItems: "center",
                          }}>
                          <Typography variant="subtitle2">Preparation Time (Mins)</Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "grey",
                            }}>
                            optional
                          </Typography>
                        </Stack>
                        <TextField type="number" name="prep_time_mins" value={fieldValues.prep_time_mins} onChange={handleChange} variant="outlined" fullWidth />
                      </Box>
                    </Stack>
                    <Stack direction="row" spacing={3}>
                      <Box width="50%">
                        <Stack
                          direction="row"
                          spacing={0.5}
                          marginBottom={1}
                          sx={{
                            alignItems: "center",
                          }}>
                          <Typography variant="subtitle2">Cooking Time (Hrs)</Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "grey",
                            }}>
                            optional
                          </Typography>
                        </Stack>
                        <TextField type="number" name="cook_time_hours" value={fieldValues.cook_time_hours} onChange={handleChange} variant="outlined" fullWidth />
                      </Box>
                      <Box width="50%">
                        <Typography variant="subtitle2" marginBottom={1}>
                          Cooking Time (Mins)
                        </Typography>
                        <TextField type="number" name="cook_time_mins" value={fieldValues.cook_time_mins} onChange={handleChange} variant="outlined" fullWidth data-label="Cooking time mins" />
                      </Box>
                    </Stack>
                    <Stack direction="row" spacing={3}>
                      <Box width="50%">
                        <Typography variant="subtitle2" marginBottom={1}>
                          Difficulty
                        </Typography>
                        <Select name="difficulty" value={fieldValues.difficulty} onChange={handleChange} fullWidth width="100%" data-label="Difficulty">
                          <MenuItem value="Easy peasy">Easy peasy</MenuItem>
                          <MenuItem value="Midway magic">Midway magic</MenuItem>
                          <MenuItem value="Master challenge">Master challenge</MenuItem>
                        </Select>
                      </Box>
                      <Box width="50%">
                        <Stack
                          direction="row"
                          spacing={0.5}
                          marginBottom={1}
                          sx={{
                            alignItems: "center",
                          }}>
                          <Typography variant="subtitle2">Calories (per serving)</Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "grey",
                            }}>
                            optional
                          </Typography>
                        </Stack>
                        <TextField name="calories" type="number" value={fieldValues.calories} onChange={handleChange} variant="outlined" fullWidth />
                      </Box>
                    </Stack>
                    <Divider />
                    <Box>
                      <Stack
                        direction="row"
                        spacing={0.5}
                        marginBottom={1}
                        sx={{
                          alignItems: "center",
                        }}>
                        <Typography variant="subtitle2">Allergies</Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: "grey",
                          }}>
                          optional
                        </Typography>
                      </Stack>
                      <Autocomplete
                        multiple
                        options={allergies || []}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={fieldValues.allergies || []}
                        onChange={(event, newValue) => {
                          // newValue is an array of selected allergy objects
                          setFieldValues((prevValues) => ({
                            ...prevValues,
                            allergies: newValue,
                          }));
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Search allergies" />}
                      />
                    </Box>
                    <Box>
                      <Stack
                        direction="row"
                        spacing={0.5}
                        marginBottom={1}
                        sx={{
                          alignItems: "center",
                        }}>
                        <Typography variant="subtitle2">Dietaries</Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: "grey",
                          }}>
                          optional
                        </Typography>
                      </Stack>
                      <Autocomplete
                        multiple
                        options={dietaries || []}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={fieldValues.dietaries || []}
                        onChange={(event, newValue) => {
                          // newValue is an array of selected allergy objects
                          setFieldValues((prevValues) => ({
                            ...prevValues,
                            dietaries: newValue,
                          }));
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Search dietaries" />}
                      />
                    </Box>
                    <Box>
                      <Stack
                        direction="row"
                        spacing={0.5}
                        marginBottom={1}
                        sx={{
                          alignItems: "center",
                        }}>
                        <Typography variant="subtitle2">Cuisines</Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: "grey",
                          }}>
                          optional
                        </Typography>
                      </Stack>
                      <Autocomplete
                        multiple
                        options={cuisines || []}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={fieldValues.cuisines || []}
                        onChange={(event, newValue) => {
                          // newValue is an array of selected allergy objects
                          setFieldValues((prevValues) => ({
                            ...prevValues,
                            cuisines: newValue,
                          }));
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Search cuisines" />}
                      />
                    </Box>
                    <Divider />
                    <Box>
                      <Typography variant="subtitle2" marginBottom={1}>
                        Ingredients (per serving)
                      </Typography>
                      {ingredients.map((ingredient, index) => (
                        <Stack
                          direction="row"
                          spacing={2}
                          key={index}
                          marginBottom={2}
                          sx={{
                            alignItems: "center",
                          }}>
                          <Box>
                            <TextField
                              name="quantity"
                              type="number"
                              placeholder="Quantity"
                              value={fieldValues.ingredients[index]?.quantity}
                              onChange={(e) => handleIngredientChange(index, "quantity", e.target.value)}
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                          <Box minWidth="100px">
                            <Select
                              name="unit"
                              displayEmpty
                              // renderValue={
                              //     fieldValues
                              //         .ingredients[
                              //         index
                              //     ]?.unit !==
                              //     ""
                              //         ? undefined
                              //         : () =>
                              //               "Select"
                              // }
                              value={fieldValues.ingredients[index]?.unit || ""}
                              onChange={(e) => handleIngredientChange(index, "unit", e.target.value)}
                              fullWidth
                              width="100%"
                              sx={{
                                minWidth: "100px",
                              }}>
                              <MenuItem value="teaspoon">teaspoon</MenuItem>
                              <MenuItem value="tablespoon">tablespoon</MenuItem>
                              <MenuItem value="cup">cup</MenuItem>
                              <MenuItem value="pint">pint</MenuItem>
                              <MenuItem value="gal">gal</MenuItem>
                              <MenuItem value="ml">ml</MenuItem>
                              <MenuItem value="L">L</MenuItem>
                              <MenuItem value="oz">oz</MenuItem>
                              <MenuItem value="lb">lb</MenuItem>
                              <MenuItem value="g">g</MenuItem>
                              <MenuItem value="kg">kg</MenuItem>
                              <MenuItem value="unit">unit</MenuItem>
                            </Select>
                          </Box>
                          <Box>
                            <TextField
                              name="ingredient"
                              placeholder="Name"
                              value={fieldValues.ingredients[index]?.ingredient}
                              onChange={(e) => handleIngredientChange(index, "ingredient", e.target.value)}
                              variant="outlined"
                              fullWidth
                            />
                          </Box>
                          <IconButton
                            variant="contained"
                            color="primary"
                            onClick={() => removeIngredientField(index)}
                            sx={{
                              border: 1,
                              borderColor: teal[500],
                            }}>
                            <Remove />
                          </IconButton>
                        </Stack>
                      ))}
                      <IconButton
                        variant="contained"
                        onClick={addIngredientField}
                        sx={{
                          backgroundColor: teal[500],
                          color: "white",
                          "&:hover": {
                            backgroundColor: teal[800],
                          },
                        }}>
                        <Add />
                      </IconButton>
                    </Box>
                    <Divider />
                    <Box>
                      <Typography variant="subtitle2" marginBottom={1}>
                        Cooking Steps
                      </Typography>
                      {steps.map((step, index) => (
                        <Stack
                          direction="row"
                          spacing={2}
                          key={index}
                          marginBottom={2}
                          sx={{
                            alignItems: "center",
                          }}>
                          <Avatar
                            sx={{
                              background: teal[50],
                              color: teal[500],
                            }}>
                            {index + 1}
                          </Avatar>
                          <TextField name="step" value={fieldValues.cooking_steps[index]?.step} onChange={(e) => handleStepChange(index, e.target.value)} variant="outlined" fullWidth />

                          <IconButton
                            variant="contained"
                            color="primary"
                            onClick={() => removeStepField(index)}
                            sx={{
                              border: 1,
                              borderColor: teal[500],
                            }}>
                            <Remove />
                          </IconButton>
                        </Stack>
                      ))}
                      <IconButton
                        variant="contained"
                        onClick={addStepField}
                        sx={{
                          backgroundColor: teal[500],
                          color: "white",
                          "&:hover": {
                            backgroundColor: teal[800],
                          },
                        }}>
                        <Add />
                      </IconButton>
                    </Box>
                    <Divider />
                    <Box>
                      <Stack
                        direction="row"
                        spacing={0.5}
                        marginBottom={1}
                        sx={{
                          alignItems: "center",
                        }}>
                        <Typography variant="subtitle2">Cooking Tips</Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: "grey",
                          }}>
                          optional
                        </Typography>
                      </Stack>
                      <TextField name="cooking_tips" value={fieldValues.cooking_tips} onChange={handleChange} variant="outlined" fullWidth />
                    </Box>
                    <Box>
                      <Stack
                        direction="row"
                        spacing={0.5}
                        marginBottom={1}
                        sx={{
                          alignItems: "center",
                        }}>
                        <Typography variant="subtitle2">Author's Note</Typography>
                        <Typography
                          variant="caption"
                          sx={{
                            color: "grey",
                          }}>
                          optional
                        </Typography>
                      </Stack>
                      <TextField name="authors_note" value={fieldValues.authors_note} onChange={handleChange} variant="outlined" fullWidth />
                    </Box>
                    {validationErrors.length > 0 && (
                      <>
                        {validationErrors.map((error, index) => (
                          <Alert severity="error" key={index}>
                            <Typography variant="body2" fontWeight="bold">
                              {error}
                            </Typography>
                          </Alert>
                        ))}
                      </>
                    )}
                    {post.error?.details.errors.map((error, index) => (
                      <Alert severity="error" key={index}>
                        <Typography variant="body2" fontWeight="bold">
                          {error.message}
                        </Typography>
                      </Alert>
                    ))}
                  </Stack>
                  <Stack direction="row" spacing={1} marginTop={3} sx={{ justifyContent: "end" }}>
                    <Button type="submit" disabled={postProgress} variant="contained" disableElevation sx={{ whiteSpace: "nowrap" }}>
                      {postProgress ? <CircularProgress size={24} color="inherit" /> : !recipeName ? "Post" : "Update"}
                    </Button>
                  </Stack>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
