// React
import React, { useEffect, useState } from "react";
// State
import { useSelector, useDispatch } from "react-redux";
import { fetchNotifications } from "../../actions/notificationsActions";
import { updateProfile } from "../../actions/profileActions";
import { Link as RouterLink } from "react-router-dom";
import Cookies from "js-cookie";
// Libraries
import { Helmet } from "react-helmet";
// UI
import { formatDate } from "../../custom/utilities";
import { LinearProgress, Grid, Container, Card, Box, List, ListItem, ListItemText, ListItemAvatar, CardContent, Typography, Link, Avatar, IconButton, Divider, CircularProgress } from "@mui/material";
import { teal } from "@mui/material/colors";

export default function Notifications() {
  // Set dispathch
  const dispatch = useDispatch();

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // Authorization
  useEffect(() => {
    if (!userToken) {
      // Redirect to "/signin"
      window.location.href = "/signin";
    }
  }, [userToken]);

  // Get user state
  const user = useSelector((state) => state.user);

  // Get user state
  const notifications = useSelector((state) => state.notifications.data);

  // Pagination
  const [pagination, setPagination] = useState({ start: 0, limit: 100 });

  // Loading
  const [loading, setLoading] = useState(false);

  // Fetch notifications
  useEffect(() => {
    async function fetchData() {
      if (!loading) {
        try {
          setLoading(true);
          dispatch(fetchNotifications(pagination));
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
    }
    fetchData();
  }, [dispatch, loading, pagination]);

  // Infinite scrolling
  const handleScroll = () => {
    const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
    // Check if the user has reached the bottom (with a small buffer)
    if (scrollHeight - (scrollTop + clientHeight) < 100) {
      // Load more notifications
      setPagination((prev) => ({ ...prev, start: prev.start + prev.limit }));
    }
  };

  useEffect(() => {
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Helper function to group notifications
  const groupNotifications = (notifications, today) => {
    // Declare groups
    const groups = [
      { title: "Today", notifications: [] },
      { title: "Yesterday", notifications: [] },
      { title: "Last 7 Days", notifications: [] },
      { title: "Last 30 Days", notifications: [] },
    ];

    // Duplicate and sort the notifications array by createdAt in descending order
    const sortedNotifications = [...notifications].sort((a, b) => {
      const dateA = new Date(b.attributes.createdAt);
      const dateB = new Date(a.attributes.createdAt);
      return dateA - dateB;
    });

    sortedNotifications.forEach((notification) => {
      const publishedDate = new Date(notification.attributes.createdAt);
      const timeDiff = today - publishedDate;

      if (timeDiff < 24 * 60 * 60 * 1000) {
        groups[0].notifications.push(notification);
      } else if (timeDiff < 2 * 24 * 60 * 60 * 1000) {
        groups[1].notifications.push(notification);
      } else if (timeDiff < 7 * 24 * 60 * 60 * 1000) {
        groups[2].notifications.push(notification);
      } else if (timeDiff < 30 * 24 * 60 * 60 * 1000) {
        groups[3].notifications.push(notification);
      }
    });

    return groups;
  };

  // Group notifications based on time
  const today = new Date();
  const groupedNotifications = groupNotifications(notifications, today);

  useEffect(() => {
    const currentDateAndTime = new Date();
    // Keep record of when the user saw the notifications screen
    const userData = {
      notificationsTime: currentDateAndTime.toISOString(),
    };
    dispatch(updateProfile(userData));
  }, [dispatch]);

  // Progress bar
  if (!user) {
    return (
      <Box sx={{ flex: 1 }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`Notifications - Dishly`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Your Dishly activity in one place" />
        <meta property="og:title" content="Dishly • Notifications" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Your Dishly activity in one place" />
      </Helmet>
      {user && (
        <Container maxWidth="sm" sx={{ flex: 1, marginY: 4 }}>
          <Typography variant="h4">Notifications</Typography>
          <Link component={RouterLink} to={`/user/${user.data.username}`}>
            <Typography variant="h6" marginBottom={4}>
              @{user.data.username}
            </Typography>
          </Link>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent sx={{ px: 0 }}>
                  <List>
                    {groupedNotifications.map((group, index) => (
                      <React.Fragment key={index}>
                        {group.notifications.length > 0 && (
                          <>
                            <ListItem>
                              <Typography variant="h6">{group.title}</Typography>
                            </ListItem>
                            {group.notifications.map((notification, index) => {
                              const newNotification = new Date(notification.attributes.createdAt) > new Date(user.data.notificationsTime);
                              const formattedDate = formatDate(notification.attributes.createdAt);
                              return (
                                <React.Fragment key={index}>
                                  <ListItem alignItems="flex-start" sx={{ backgroundColor: newNotification ? teal[50] : "" }}>
                                    <ListItemAvatar sx={{ marginRight: 1 }}>
                                      <IconButton component={RouterLink} to={`/user/${notification.attributes.notification_sender}`}>
                                        <Avatar alt={notification.attributes.notification_sender} src={notification.attributes.notification_avatar} />
                                      </IconButton>
                                    </ListItemAvatar>
                                    <ListItemText
                                      primary={
                                        <>
                                          <strong>{notification.attributes.notification_sender}</strong> {notification.attributes.notification_title}
                                        </>
                                      }
                                      secondary={
                                        <>
                                          {notification.attributes.notification_link ? (
                                            <Link component={RouterLink} to={`/recipes/${notification.attributes.notification_link}`}>
                                              {notification.attributes.notification_message}
                                            </Link>
                                          ) : (
                                            notification.attributes.notification_message
                                          )}
                                          <Typography variant="caption" color="grey" sx={{ display: "block" }}>
                                            {formattedDate}
                                          </Typography>
                                        </>
                                      }
                                      // Limit length of summary and message
                                      sx={{
                                        "& .MuiTypography-body1, & .MuiTypography-body2": {
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          display: "-webkit-box",
                                          WebkitBoxOrient: "vertical",
                                          WebkitLineClamp: 2, // Number of lines to show
                                        },
                                      }}
                                    />
                                  </ListItem>
                                  {index !== group.notifications.length - 1 && <Divider />}
                                </React.Fragment>
                              );
                            })}
                          </>
                        )}
                      </React.Fragment>
                    ))}
                    {loading && (
                      <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        <CircularProgress />
                      </Box>
                    )}
                    {notifications.length === 0 && (
                      <Typography variant="h6" sx={{ px: 2 }}>
                        No notifications
                      </Typography>
                    )}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
