// React
import React, { useState } from "react";
// State
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { fetchUser } from "../../actions/userActions";
// Libraries
import { Helmet } from "react-helmet";
// UI
import { Container, Box, Card, Typography, InputLabel, TextField, OutlinedInput, Link, Checkbox, Divider, Alert, Button, IconButton, InputAdornment } from "@mui/material";
import { grey } from "@mui/material/colors";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function SignIn() {
  // Get user token
  const userToken = Cookies.get("session-cookie");

  // Redirect if user is already logged in
  if (userToken) {
    window.location.href = "/recipes";
  }

  // Variables
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  // Show password
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_HOSTNAME}/api/auth/local`, {
        identifier,
        password,
      });
      // Set cookie
      if (document.getElementById("consent").checked) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 365);
        const cookieValue = `session-cookie=${response.data?.jwt};expires=${expirationDate.toUTCString()};path=/`;
        document.cookie = cookieValue;
      } else {
        const cookieValue = `session-cookie=${response.data?.jwt};path=/`;
        document.cookie = cookieValue;
      }

      // Check if cookie has been stored before redirecting the user
      // Note: userToken variable doesn't inside this function, that's why we're using `Cookies.get("session-cookie")` instead. This needs further investigation.
      if (Cookies.get("session-cookie")) {
        // Fetch user data
        dispatch(fetchUser);
        // Get current host/domain
        const fromSameDomain = document.referrer.startsWith(window.location.origin);
        // Check if coming from notifications
        const fromNotifications = document.referrer.endsWith("/notifications");
        // Redirect
        if (fromSameDomain && !fromNotifications) {
          // Same domain but not from notifications
          window.history.back();
        } else if (fromNotifications) {
          // From notifications
          window.location.href = "/notifications";
        } else {
          // From somewhere else
          window.location.href = "/recipes";
        }
      }
    } catch (error) {
      if (error.response?.data?.error?.message) {
        setErrorMessage(error.response?.data?.error?.message);
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Sign in - Dishly`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Access your Dishly account." />
        <meta property="og:title" content="Dishly • Sign in" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Access your Dishly account." />
      </Helmet>
      <Container maxWidth="sm">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <Card variant="outlined" sx={{ width: "100%", maxWidth: "400px", padding: 4 }}>
            <Typography variant="h5" marginBottom={3} sx={{ textAlign: "center" }}>
              Sign in
            </Typography>
            <form onSubmit={handleSubmit}>
              <InputLabel htmlFor="username" sx={{ marginBottom: 1 }}>
                Username or E-mail
              </InputLabel>
              <TextField
                variant="outlined"
                type="text"
                id="username"
                name="username"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                sx={{ width: "100%", marginBottom: 3 }}
                required
              />
              <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ marginBottom: 1 }}>
                <InputLabel htmlFor="password">
                  <span>Password</span>
                </InputLabel>
                <Link component={RouterLink} to="/forgot-password" variant="body2" sx={{ cursor: "pointer" }}>
                  Forgot password?
                </Link>
              </Box>
              <OutlinedInput
                id="password"
                name="password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{
                  style: { WebkitBoxShadow: "0 0 0 1000px #fff inset" }, // Use the desired background color
                }}
                sx={{
                  width: "100%",
                  marginBottom: 3,
                }}
                required
              />
              <Box display="flex" alignItems="center" sx={{ marginBottom: 3 }}>
                <Checkbox id="consent" />
                <InputLabel
                  htmlFor="consent"
                  margin="dense"
                  sx={{
                    display: "inline-block",
                    whiteSpace: "unset",
                    lineHeight: "1",
                  }}>
                  <Typography variant="caption">Stay signed in?</Typography>
                </InputLabel>
              </Box>
              {errorMessage && (
                <Alert severity="error" sx={{ marginBottom: 3 }}>
                  {errorMessage}
                </Alert>
              )}
              <Button variant="contained" size="large" disableElevation type="submit" sx={{ width: "100%", marginBottom: 3 }}>
                Sign in
              </Button>
            </form>
            <Divider sx={{ marginBottom: 3 }}>
              <Typography variant="caption" color={grey[500]} sx={{ textAlign: "center" }}>
                New to Dishly?
              </Typography>
            </Divider>
            <Button component={RouterLink} to="/signup" variant="outlined" size="large" disableElevation sx={{ width: "100%" }}>
              Create your Dishly account
            </Button>
          </Card>
        </Box>
      </Container>
    </>
  );
}
