export function formatDate(dateString) {
  const date = new Date(dateString);
  const now = new Date();

  const timeDifference = now - date;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return "Just now";
  } else if (minutes === 1) {
    return "1 min ago";
  } else if (minutes < 60) {
    return `${minutes} mins ago`;
  } else if (hours === 1) {
    return "1 hr ago";
  } else if (hours < 24) {
    return `${hours} hrs ago`;
  } else if (days === 1) {
    return "Yesterday";
  } else if (date.getFullYear() === now.getFullYear()) {
    // If the date is within the same year as today
    const options = { day: "numeric", month: "short" };
    return date.toLocaleDateString("en-US", options);
  } else {
    // If the date is from a previous year
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
}

export function formatTime(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  let formattedTime = "";

  if (hours > 0) {
    formattedTime += `${hours}hrs `;
  }

  if (minutes > 0) {
    formattedTime += `${minutes}mins`;
  }

  return formattedTime;
}
