import {
  fetchNotificationsStart,
  fetchNotificationsSuccess,
  fetchNotificationsFailure,
} from "../reducers/notificationsReducer";
import Cookies from "js-cookie";
import axios from "axios";

// Get notifications
export const fetchNotifications = (pagination) => (dispatch) => {
  // Dispatch the start action
  dispatch(fetchNotificationsStart());

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // API call
  axios
    .get(
      `${process.env.REACT_APP_API_HOSTNAME}/api/notifications?populate=*&pagination[start]=${pagination.start}&pagination[limit]=${pagination.limit}&sort=createdAt:desc`,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    )
    .then((response) => {
      // Dispatch the success action
      dispatch(fetchNotificationsSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch the error action
      dispatch(fetchNotificationsFailure(error.message));
    });
};
