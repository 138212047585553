// Redux
import { fetchRecipesStart, fetchRecipesSuccess, fetchRecipesFailure } from "../reducers/recipesReducer";
// Libraries
import Cookies from "js-cookie";
import axios from "axios";

export const fetchRecipes = (search, category, pagination) => (dispatch) => {
  // Dispatch the start action
  dispatch(fetchRecipesStart());

  // Get the user token
  const userToken = Cookies.get("session-cookie");

  // API's endpoint
  let apiUrl = `${process.env.REACT_APP_API_HOSTNAME}/api/recipes?populate=*`;

  // API's search filters
  if (search) {
    // Split the search terms
    const searchTerms = search.split(" ");
    // Initialize the sequence number to -1 (to start from 0)
    let sequenceNumber = -1;
    // Loop to create a filter for each word
    const searchFilters = searchTerms
      .map((term) => {
        const termFilters = ["title", "intro", "author][username", "dietaries][name", "cuisines][name"].map((field) => {
          // Increment the sequence number for each field
          sequenceNumber++;
          return `&filters[$or][${sequenceNumber}][${field}][$containsi]=${encodeURIComponent(term)}`;
        });
        // Join the filters for each search term
        return termFilters.join("");
      })
      // Join all filters to generate final search parameters
      .join("");
    apiUrl += `${searchFilters}`;
  }

  if (category && category !== "discover") {
    //dispatch(clearRecipes());
    apiUrl += `&filters[category][$contains]=${category}`;
  }

  // API's sort and pagination
  if (pagination) {
    apiUrl += `&pagination[start]=${pagination.start}&pagination[limit]=${pagination.limit}&sort=createdAt:desc`;
  }

  // Axios call
  axios
    .get(apiUrl, {
      headers: userToken ? { Authorization: `Bearer ${userToken}` } : {},
    })
    .then((response) => {
      dispatch(fetchRecipesSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(fetchRecipesFailure(error.message));
    });
};
