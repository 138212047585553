// React
import React, { useState } from "react";
// State
import axios from "axios";
// Libraries
import { Helmet } from "react-helmet";
// UI
import { Container, Box, Card, Typography, InputLabel, TextField, Alert, Button } from "@mui/material";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [sucessMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_HOSTNAME}/api/auth/forgot-password`, { email });
      setSuccessMessage("An email has been sent to reset your password.");
    } catch (error) {
      if (error.response?.data?.error?.message) {
        setErrorMessage(error.response?.data?.error?.message);
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Forgot Password - Dishly`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Forgot your Dishly password? No problem." />
        <meta property="og:title" content="Dishly • Forgot Password" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Forgot your Dishly password? No problem." />
      </Helmet>
      <Container maxWidth="sm">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <Card variant="outlined" sx={{ width: "100%", maxWidth: "400px", padding: 4 }}>
            <Typography variant="h5" marginBottom={3} sx={{ textAlign: "center" }}>
              Forgot Password
            </Typography>
            <Typography variant="body2" marginBottom={3}>
              Enter the email associated with your Dishly account to reset your password.
            </Typography>
            <form onSubmit={handleSubmit}>
              <InputLabel htmlFor="email" sx={{ marginBottom: 1 }}>
                E-mail
              </InputLabel>
              <TextField variant="outlined" size="small" type="email" id="email" name="email" value={email} onChange={handleChange} sx={{ width: "100%", marginBottom: 3 }} required />
              {sucessMessage && (
                <Alert severity="success" sx={{ marginBottom: 3 }}>
                  {sucessMessage}
                </Alert>
              )}
              {errorMessage && (
                <Alert severity="error" sx={{ marginBottom: 3 }}>
                  {errorMessage}
                </Alert>
              )}
              <Button variant="contained" size="large" disableElevation type="submit" sx={{ width: "100%" }}>
                Reset my password
              </Button>
            </form>
          </Card>
        </Box>
      </Container>
    </>
  );
}
