// React
import React, { useEffect } from "react";
// State
import { useSelector, useDispatch } from "react-redux";
import { fetchUser } from "../../actions/userActions";
import Cookies from "js-cookie";
// Libraries
import { Helmet } from "react-helmet";
// Components
import IndexCardY from "../ui/IndexCardY";
// UI
import { Box, LinearProgress, Grid, Container, Typography } from "@mui/material";

export default function Saved() {
  // Set dispathch
  const dispatch = useDispatch();

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // Authorization
  useEffect(() => {
    if (!userToken) {
      // Redirect to "/signin"
      window.location.href = "/signin";
    }
  }, [userToken]);

  // Get user data
  useEffect(() => {
    if (userToken) {
      dispatch(fetchUser());
    }
  }, [dispatch, userToken]);

  // User saved recipes
  let recipes = useSelector((state) => state.user.data.saved_recipes);

  // Format JSON to match the recipes API
  recipes = recipes?.map((recipe) => {
    const { id, author, cover, ratings, allergies, dietaries, cuisines, ...otherAttributes } = recipe;

    // Create the "attributes" object excluding thumbnails, ratings, etc
    const attributes = { ...otherAttributes };

    // Create the "author" object with the specified structure
    const authorObject = {
      author: {
        data: {
          id: author?.id,
          attributes: {
            ...author,
            avatar: {
              data: {
                id: author?.avatar?.id,
                attributes: { ...author?.avatar },
              },
            },
          },
        },
      },
    };

    // Create the "cover" object with the specified structure
    const coverObject = {
      cover: {
        data: {
          id: cover?.id,
          attributes: { ...cover },
        },
      },
    };

    // Create the "ratings" object with the specified structure
    const ratingsObject = {
      ratings: {
        data: ratings?.map((rating) => ({
          id: rating.id,
          attributes: { ...rating },
        })),
      },
    };

    // Create the "allergies" object with the specified structure
    const allergiesObject = {
      allergies: {
        data: allergies?.map((allergy) => ({
          id: allergy.id,
          attributes: { ...allergy },
        })),
      },
    };

    // Create the "dietaries" object with the specified structure
    const dietariesObject = {
      dietaries: {
        data: dietaries?.map((dietary) => ({
          id: dietary.id,
          attributes: { ...dietary },
        })),
      },
    };

    // Create the "cuisines" object with the specified structure
    const cuisinesObject = {
      cuisines: {
        data: cuisines?.map((cuisine) => ({
          id: cuisine.id,
          attributes: { ...cuisine },
        })),
      },
    };

    // Combine "id", "attributes", "coverObject", and "ratingsObject"
    return {
      id,
      attributes: {
        ...attributes,
        ...authorObject,
        ...coverObject,
        ...ratingsObject,
        ...allergiesObject,
        ...dietariesObject,
        ...cuisinesObject,
      },
    };
  });

  // Check if recipe is null or data is not available yet
  if (!recipes) {
    return (
      <Box sx={{ flex: 1 }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`Saved Recipes - Dishly`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="All your favorite Dishly recipes in one place." />
        <meta property="og:title" content="Dishly • Saved Recipes" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="All your favorite Dishly recipes in one place." />
      </Helmet>
      <Container maxWidth="xxl" sx={{ flex: 1, marginY: 4 }}>
        <Typography variant="h4">Saved</Typography>
        <Typography variant="body2" marginBottom={4} sx={{ color: "#616161" }}>
          All your favorite recipes in one place
        </Typography>
        <Grid container spacing={4}>
          <IndexCardY data={recipes} />
        </Grid>
      </Container>
    </>
  );
}
