import { createSlice } from "@reduxjs/toolkit";

const postSlice = createSlice({
  name: "post",
  initialState: {
    data: [],
    isLoading: false,
    success: false,
    error: null,
  },
  reducers: {
    addPostStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    addPostSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.id = action.payload.id;
      state.success = true;
      state.error = null;
    },
    addPostFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    editPostStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    editPostSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.id = action.payload.id;
      state.success = true;
      state.error = null;
    },
    editPostFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    deletePostStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    deletePostSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.id = action.payload.id;
      state.success = true;
      state.error = null;
    },
    deletePostFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  addPostStart,
  addPostSuccess,
  addPostFailure,
  editPostStart,
  editPostSuccess,
  editPostFailure,
  deletePostStart,
  deletePostSuccess,
  deletePostFailure,
} = postSlice.actions;

export default postSlice.reducer;
