// React
import React, { useEffect, useState } from "react";
// Router
import { useLocation } from "react-router-dom";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { fetchProfile } from "../../actions/profileActions";
import { clearProfile } from "../../reducers/profileReducer";
import { fetchAuthorRecipes } from "../../actions/authorRecipesActions";
// Libraries
import { Helmet } from "react-helmet";
// Components
import EditProfile from "./EditProfile";
import FollowButton from "../ui/FollowButton";
import IndexCardY from "../ui/IndexCardY";
// UI
import { LinearProgress, CircularProgress, Container, Grid, Box, Stack, Avatar, Typography } from "@mui/material";

export default function Profile() {
  // Set dispatch
  const dispatch = useDispatch();

  // Get user data
  const user = useSelector((state) => state.user);

  // Author's username
  const author = useLocation().pathname.replace("/user/", "");

  // Fetch author's profile
  useEffect(() => {
    dispatch(fetchProfile(author));
    // Clear profile
    return () => {
      dispatch(clearProfile());
    };
  }, [author, dispatch]);

  // Profile data
  const profileData = useSelector((state) => state.profile.data);

  // Profile dialog
  const [profileDialog, setProfileDialog] = useState(false);
  const editDialog = () => {
    setProfileDialog(true);
  };

  // Recipes loading
  const [loading, setLoading] = useState(false);

  // Recipes pagination
  const [pagination, setPagination] = useState({ start: 0, limit: 25 });

  // Fetch recipes
  useEffect(() => {
    async function fetchData() {
      if (!loading) {
        try {
          // Show loading spinner while fetching more recipes
          setLoading(true);
          // Fetch recipes from this author
          dispatch(fetchAuthorRecipes(author, pagination));
          setLoading(false);
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
    }
    fetchData();
  }, [dispatch, loading, author, pagination]);

  // Infinite scrolling
  const handleScroll = () => {
    const { scrollHeight, scrollTop, clientHeight } = document.documentElement;
    // Check if the user has reached the bottom (with a small buffer)
    if (scrollHeight - (scrollTop + clientHeight) < 100) {
      // Load more notifications
      setPagination((prev) => ({ ...prev, start: prev.start + prev.limit }));
    }
  };

  useEffect(() => {
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Author's recipes
  const initialRecipes = useSelector((state) => state.recipes?.data);
  var [recipes, setRecipes] = useState([]);

  // Remove recipes from other authors
  useEffect(() => {
    if (initialRecipes && Array.isArray(initialRecipes)) {
      const recipesFromThisAuthor = initialRecipes.filter((recipe) => recipe?.attributes?.author?.data?.attributes?.username === author);
      setRecipes(recipesFromThisAuthor);
    }
  }, [initialRecipes, author]);

  // Calculate total likes
  const totalLikes = recipes?.reduce((accumulator, recipe) => {
    return accumulator + recipe?.attributes?.likes?.data?.length;
  }, 0);

  if (!profileData) {
    return (
      <Box sx={{ flex: 1 }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <>
      {profileData?.data?.map((profile) => {
        return (
          <>
            <Helmet>
              <title>{profile.name ? `${profile?.name} (@${profile?.username}) - Dishly` : `@${profile?.username} - Dishly`}</title>
              <link rel="canonical" href={window.location.href} />
              <meta name="description" content={profile?.bio ? profile.bio : `Welcome to the kitchen of ${profile?.username}, here you can find their recipes and more.`} />
              <meta property="og:title" content={profile?.name ? `${profile.name} (@${profile?.username}) • Dishly Profile` : `@${profile?.username} • Dishly Profile`} />
              <meta property="og:url" content={window.location.href} />
              {profile?.avatar?.formats?.small?.url ? <meta property="og:image" content={profile?.avatar?.formats?.small?.url} /> : ""}
              {profile?.avatar?.formats?.small?.url ? <meta property="og:image:alt" content={profile?.username} /> : ""}
              <meta property="og:description" content={profile.bio ? profile.bio : `Welcome to the kitchen of ${profile?.username}, here you can find their recipes and more.`} />
            </Helmet>
            <div key={profile.id} style={{ flex: 1 }}>
              <Box padding={3}>
                <Stack direction="row" alignItems="center" marginBottom={2}>
                  <Avatar
                    onClick={user.data.username === profile.username ? editDialog : null}
                    alt={profile?.username}
                    src={profile?.avatar?.formats.thumbnail.url}
                    sx={{
                      width: 72,
                      height: 72,
                      fontSize: "2rem",
                      marginRight: 2,
                      cursor: user.data.username === profile.username ? "pointer" : "default",
                    }}
                  />
                  <Box>
                    <Typography component="h1" variant="h4">
                      {profile.username}
                    </Typography>
                    <Typography variant="body1" marginBottom={0.5}>
                      {profile.name}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} marginBottom={2} sx={{ color: "grey", typography: "body1" }}>
                  {recipes?.length > 0 && (
                    <Box sx={{ color: "grey" }}>
                      {recipes?.length} {recipes?.length > 1 ? "Recipes" : "Recipe"}
                    </Box>
                  )}
                  {totalLikes > 0 && (
                    <Box>
                      {totalLikes} {totalLikes > 1 ? "Likes" : "Like"}
                    </Box>
                  )}
                  {profile?.followers?.length > 0 && (
                    <Box>
                      {profile?.followers?.length} {profile?.followers?.length > 1 ? "Followers" : "Follower"}
                    </Box>
                  )}
                  {profile?.following?.length > 0 && <Box>{profile?.following?.length} Following</Box>}
                </Stack>
                <Typography variant="body1" marginBottom={2}>
                  {profile.bio ? profile.bio : `Welcome to the kitchen of ${profile?.username}, here you can find their recipes and more.`}
                </Typography>
                <FollowButton account={profile} />
                {user.data.username === profile.username ? <EditProfile profileDialog={profileDialog} setProfileDialog={setProfileDialog} /> : ""}
              </Box>
              <Container maxWidth="xxl" sx={{ marginY: 4 }}>
                <Grid container spacing={4}>
                  <IndexCardY data={recipes} />
                </Grid>
              </Container>
              {recipes?.length > 0 && loading && (
                <Box sx={{ display: "flex", width: "100%", justifyContent: "center", marginY: 2 }}>
                  <CircularProgress />
                </Box>
              )}
            </div>
          </>
        );
      })}
    </>
  );
}
