import {
  fetchUserStart,
  fetchUserSuccess,
  fetchUserFailure,
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
  clearUserStart,
  clearUserSuccess,
  clearUserFailure,
} from "../reducers/userReducer";
import Cookies from "js-cookie";
import axios from "axios";

// Get user
export const fetchUser = () => (dispatch) => {
  // Dispatch the user action
  dispatch(fetchUserStart());

  // Get user token
  const userToken = Cookies.get("session-cookie");

  //API call
  axios
    .get(`${process.env.REACT_APP_API_HOSTNAME}/api/users/me?populate=*`, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
    .then((response) => {
      dispatch(fetchUserSuccess(response.data));
    })
    .catch((error) => {
      // handle error
      dispatch(fetchUserFailure(error.message));
    });
};

// Update user
export const updateUser = (userData) => (dispatch, getState) => {
  // dispatch the start action
  dispatch(updateUserStart());

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // Get user ID from Redux store
  const userId = getState().user.data.id;

  axios
    .put(`${process.env.REACT_APP_API_HOSTNAME}/api/users/${userId}?populate=*`, userData, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
    .then((response) => {
      // dispatch the success action
      dispatch(updateUserSuccess(response.data));
      dispatch({ type: "UPDATE_USER_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      // dispatch the failure action
      dispatch(updateUserFailure(error.message));
    });
};

// Clear user data
export const clearUser = () => async (dispatch) => {
  // Dispatch the start action
  dispatch(clearUserStart());

  try {
    Cookies.remove("session-cookie");
    dispatch(clearUserSuccess());
  } catch (error) {
    dispatch(clearUserFailure(error));
  }
};
