import React, { useEffect, useState } from "react";
// Router
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { clearUser } from "../../actions/userActions";
import { clearRecipes } from "../../reducers/recipesReducer";
// Libraries
import Cookies from "js-cookie";
// Components
import Filters from "../account/Filters";
import NotificationsButton from "../ui/NotificationsButton";
// UI
import {
  AppBar,
  Toolbar,
  Avatar,
  Stack,
  Button,
  Menu,
  MenuItem,
  Container,
  Box,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  Link,
} from "@mui/material";
import SlideUp from "../ui/Transitions";
import { Add } from "@mui/icons-material";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { grey } from "@mui/material/colors";

export default function TopBar() {
  // Set dispatch
  const dispatch = useDispatch();

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // Router nav
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const username = user.data.username;

  const [anchorEl, setAnchorEl] = useState(null);
  const openAccount = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeAccount = () => {
    setAnchorEl(null);
  };
  const [openFilters, setOpen] = React.useState(false);

  const handleOpenFilters = () => {
    if (userToken) {
      setOpen(true);
    } else {
      // Redirect to "/signin"
      navigate("/signin");
    }
  };

  const closeFilters = () => {
    setOpen(false);
  };

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    // Extract the search query from the URL
    const urlSearchParams = new URLSearchParams(window.location.search);
    const searchParam = urlSearchParams.get("search");
    setSearchValue(searchParam || "");
  }, []);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      // Prevent default form submission behavior
      event.preventDefault();
      // Clear cache recipes
      dispatch(clearRecipes());
      // Handle search
      handleSearch();
    }
  };

  const handleSearch = () => {
    const trimmedValue = searchValue.trim();
    if (trimmedValue !== "") {
      // Append the search query parameter to the recipes URL
      // TO DO: We need a generic search page instead of `recipes?search`, to show articles, restaurants, etc.
      navigate(`/recipes?search=${encodeURIComponent(trimmedValue)}`);
    } else {
      navigate("/recipes");
    }
  };

  const signOut = () => {
    if (userToken) {
      // Dispatch the clearUser action
      dispatch(clearUser());
      // Redirect to "/recipes"
      navigate("/recipes");
    }
  };

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        color: "teal",
        backgroundColor: "white",
        borderBottom: 1,
        borderColor: "grey.300",
      }}>
      <Container maxWidth="xxl">
        <Toolbar disableGutters={true}>
          <Link
            component={RouterLink}
            to="/recipes"
            sx={{
              marginRight: 2,
              display: { xs: "none", sm: "block" },
            }}>
            <svg height="32" width="112.2171" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 112.2171 32">
              <g id="D">
                <g>
                  <path
                    d="m13.16,0H4.27C1.69,0,0,1.02,0,4.27v23.47c0,3.24,1.69,4.27,4.27,4.27h8.76c9.73,0,15.38-5.87,15.38-16C28.4,3.47,21.11,0,13.16,0Zm1.01,25.95c-5.5,0-9.96-4.46-9.96-9.96S8.66,6.04,14.16,6.04s9.96,4.46,9.96,9.96-4.46,9.96-9.96,9.96Z"
                    fill="#178675"
                  />
                  <path
                    d="m14.12,9.36c7.73,1.54,7.79,11.69,0,13.27.87.17,1.8.12,2.69-.14,1.82-.52,3.37-1.92,4.14-3.66.79-1.77.79-3.89,0-5.66-1.11-2.58-4.05-4.36-6.83-3.81Z"
                    fill="#178675"
                  />
                </g>
                <g>
                  <path
                    d="m36.43,8.74c0-2.01,1.05-2.64,2.64-2.64h5.5c4.92,0,9.44,2.15,9.44,9.9,0,6.27-3.49,9.9-9.52,9.9h-5.42c-1.6,0-2.64-.63-2.64-2.64v-14.53Zm4.51,13.53h3.8c3.6,0,4.76-3.22,4.76-6.27,0-3.91-1.49-6.27-4.79-6.27h-3.77v12.54Z"
                    fill="#178675"
                  />
                  <path
                    d="m58.38,5.63c1.16,0,2.09.94,2.09,2.09s-.94,2.09-2.09,2.09-2.09-.94-2.09-2.09.94-2.09,2.09-2.09Zm-2.01,7.54c0-1.07.44-2.01,2.01-2.01s2.01.94,2.01,2.01v11.09c0,1.07-.44,2.01-2.01,2.01s-2.01-.94-2.01-2.01v-11.09Z"
                    fill="#178675"
                  />
                  <path
                    d="m68.53,11.16c3.05,0,6.13,1.21,6.13,3.05,0,.94-.63,1.76-1.68,1.76-1.57,0-1.62-1.84-4.15-1.84-1.4,0-2.31.39-2.31,1.24,0,.94.91,1.16,2.15,1.43l1.68.39c2.28.52,5.01,1.49,5.01,4.29,0,3.27-3.16,4.79-6.19,4.79-3.63,0-6.66-1.62-6.66-3.6,0-.88.5-1.68,1.62-1.68,1.73,0,1.9,2.48,4.87,2.48,1.43,0,2.34-.63,2.34-1.46,0-1.02-.88-1.18-2.31-1.54l-2.37-.58c-2.34-.58-4.15-1.54-4.15-4.24,0-3.27,3.25-4.48,6.02-4.48Z"
                    fill="#178675"
                  />
                  <path
                    d="m77.25,7.75c0-1.32.8-2.01,2.01-2.01s2.01.69,2.01,2.01v5.53h.06c.8-1.05,2.2-2.12,4.48-2.12,2.42,0,5.06,1.21,5.06,5.28v7.81c0,1.07-.44,2.01-2.01,2.01s-2.01-.94-2.01-2.01v-7.02c0-1.62-.8-2.78-2.53-2.78-1.4,0-3.05,1.16-3.05,3.16v6.63c0,1.07-.44,2.01-2.01,2.01s-2.01-.94-2.01-2.01V7.75Z"
                    fill="#178675"
                  />
                  <path
                    d="m93.62,7.75c0-1.32.8-2.01,2.01-2.01s2.01.69,2.01,2.01v16.51c0,1.32-.8,2.01-2.01,2.01s-2.01-.69-2.01-2.01V7.75Z"
                    fill="#178675"
                  />
                  <path
                    d="m105.72,22.05h.06l2.45-9.11c.28-1.02.83-1.79,1.93-1.79,1.6,0,2.06,1.32,2.06,1.82,0,.33-.11.74-.3,1.27l-4.65,13.18c-.96,2.75-2.06,4.18-4.59,4.18-1.79,0-2.53-.88-2.53-1.84,0-1.07.77-1.62,1.84-1.62h.41c.52,0,1.1-.03,1.4-1.27l-4.18-11.91c-.33-.96-.5-1.4-.5-1.87,0-1.1.88-1.93,1.95-1.93s1.82.61,2.06,1.51l2.59,9.38Z"
                    fill="#178675"
                  />
                </g>
              </g>
            </svg>
          </Link>
          <Link
            component={RouterLink}
            to="/recipes"
            sx={{
              marginTop: 0.75,
              marginRight: 1,
              display: { xs: "block", sm: "none" },
            }}>
            <svg height="32" width="28.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.3997 32">
              <g id="D">
                <g>
                  <path
                    d="m13.16,0H4.27C1.69,0,0,1.02,0,4.27v23.47c0,3.24,1.69,4.27,4.27,4.27h8.76c9.73,0,15.38-5.87,15.38-16C28.4,3.47,21.11,0,13.16,0Zm1.01,25.95c-5.5,0-9.96-4.46-9.96-9.96S8.66,6.04,14.16,6.04s9.96,4.46,9.96,9.96-4.46,9.96-9.96,9.96Z"
                    fill="#178675"
                  />
                  <path
                    d="m14.12,9.36c7.73,1.54,7.79,11.69,0,13.27.87.17,1.8.12,2.69-.14,1.82-.52,3.37-1.92,4.14-3.66.79-1.77.79-3.89,0-5.66-1.11-2.58-4.05-4.36-6.83-3.81Z"
                    fill="#178675"
                  />
                </g>
              </g>
            </svg>
          </Link>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block" },
            }}
          />
          <Box component="form" autoComplete="off" width={400} margin={0}>
            <OutlinedInput
              id="input-with-icon-adornment"
              variant="outlined"
              placeholder="Search recipes"
              size="small"
              fullWidth
              value={searchValue}
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyDown}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end" sx={{ margin: "-6px" }}>
                  <IconButton aria-label="search filters" onClick={handleOpenFilters} edge="end" size="small">
                    <TuneIcon />
                  </IconButton>
                </InputAdornment>
              }
              sx={{
                height: "40px",
                borderRadius: 48,
                paddingTop: "4px",
                paddingBottom: "4px",
                background: grey[100],
              }}
              required
            />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          {userToken ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box marginLeft={1} sx={{ display: { xs: "none", sm: "block" } }}>
                <Button
                  component={RouterLink}
                  to="/edit-recipe"
                  variant="contained"
                  disableElevation
                  startIcon={<Add />}>
                  Create
                </Button>
              </Box>
              <NotificationsButton />
              <IconButton onClick={(event) => handleClick(event)} aria-label="more options">
                <Avatar
                  alt={user?.data?.username}
                  src={user?.data?.avatar?.formats?.thumbnail?.url}
                  aria-label="more options"
                  sx={{ width: 32, height: 32 }}
                />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openAccount}
                onClose={closeAccount}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}>
                <MenuItem
                  onClick={closeAccount}
                  sx={{
                    color: "grey",
                  }}>
                  <PersonOutlineIcon
                    sx={{
                      marginRight: "8px",
                    }}
                  />
                  <Typography variant="body2">
                    <Link
                      component={RouterLink}
                      to={`/user/${username}`}
                      sx={{
                        color: "unset",
                        textDecoration: "none",
                      }}>
                      Profile
                    </Link>
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={closeAccount}
                  sx={{
                    color: "grey",
                  }}>
                  <SettingsOutlinedIcon
                    sx={{
                      marginRight: "8px",
                    }}
                  />
                  <Typography variant="body2">
                    <Link
                      component={RouterLink}
                      to="/account"
                      sx={{
                        color: "unset",
                        textDecoration: "none",
                      }}>
                      Account
                    </Link>
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={closeAccount}
                  sx={{
                    color: "grey",
                  }}>
                  <HelpOutlineOutlinedIcon
                    sx={{
                      marginRight: "8px",
                    }}
                  />
                  <Typography variant="body2">Help</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    signOut();
                    closeAccount();
                  }}
                  sx={{
                    color: "grey",
                  }}>
                  <LogoutIcon
                    sx={{
                      marginRight: "8px",
                    }}
                  />
                  <Typography variant="body2">Sign out</Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Stack direction="row" marginLeft={2} spacing={1}>
              <Button component={RouterLink} to="/signin" variant="outlined" sx={{ whiteSpace: "nowrap" }}>
                Sign in
              </Button>
              <Button
                component={RouterLink}
                to="/signup"
                variant="contained"
                disableElevation
                sx={{
                  whiteSpace: "nowrap",
                  display: { xs: "none", md: "flex" },
                }}>
                Create Free Account
              </Button>
            </Stack>
          )}
        </Toolbar>
      </Container>
      {userToken && (
        <Dialog
          open={openFilters}
          TransitionComponent={SlideUp}
          keepMounted
          onClose={closeFilters}
          aria-describedby="alert-dialog-slide-description">
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}>
            Food Filters
            <IconButton aria-label="close filters" onClick={closeFilters}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Filters closeFilters={closeFilters} />
          </DialogContent>
        </Dialog>
      )}
    </AppBar>
  );
}
