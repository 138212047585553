// React
import React, { useEffect, useState } from "react";
// State
import { useSelector, useDispatch } from "react-redux";
import { fetchRecipe } from "../../actions/recipeActions";
import { clearRecipe } from "../../reducers/recipeReducer";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
// Libraries
import { Helmet } from "react-helmet";
// Components
import FollowButton from "../ui/FollowButton";
import SocialBar from "../ui/SocialBar";
import Comments from "../ui/Comments";
// Utilities
import { formatDate } from "../../custom/utilities";
import { formatTime } from "../../custom/utilities";
// UI
import RelatedRecipes from "../recipes/RelatedRecipes";
import {
  LinearProgress,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Avatar,
  Typography,
  Button,
  IconButton,
  Box,
  Stack,
  Divider,
  Chip,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from "@mui/material";
import SlideUp from "../ui/Transitions";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CloseIcon from "@mui/icons-material/Close";
import { teal, red } from "@mui/material/colors";

export default function Recipe() {
  // Set dispatch
  const dispatch = useDispatch();

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // User state
  const user = useSelector((state) => state.user);

  // Recipe state
  const recipe = useSelector((state) => state.recipe?.data);

  // Comments dialog
  const [openComments, setOpen] = React.useState(false);
  const commentsDialog = () => {
    setOpen(true);
  };
  const closeComments = () => {
    setOpen(false);
  };

  // Calculate Servings
  const [servings, setServings] = useState(1);
  const handleServings = (event) => {
    const { value } = event.target;
    setServings(value);
  };

  // Get recipe name from URL using the Route's path
  const recipeName = useLocation().pathname.split("/").pop();

  useEffect(() => {
    dispatch(fetchRecipe(recipeName));
    // Unmount recipe
    return () => {
      dispatch(clearRecipe(recipeName));
    };
  }, [recipeName, dispatch]);

  // Check if recipe is null or the data property is not available yet
  if (!recipe?.attributes) {
    return (
      <Box sx={{ flex: 1 }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${recipe?.attributes?.title} - Dishly`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={recipe?.attributes?.intro ? recipe?.attributes?.intro : "Learn how to cook this delicious dish on Dishly"} />
        <meta property="og:title" content={recipe?.attributes?.title} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={recipe.attributes?.cover?.data?.attributes?.formats?.small?.url} />
        <meta property="og:image:alt" content={recipe?.attributes?.title} />
        <meta property="og:description" content={recipe?.attributes?.intro ? recipe?.attributes?.intro : "Learn how to cook this delicious dish on Dishly"} />
      </Helmet>
      <Container maxWidth="lg" sx={{ flex: 1, marginY: 4 }}>
        <Grid container spacing={4} justifyContent={"center"}>
          <Grid item xs={12} sm={12} md={8}>
            <Card variant="outlined" sx={{ marginBottom: 4 }}>
              <Stack direction="row" alignItems="center" padding={2}>
                {recipe?.attributes?.author?.data && (
                  <Box
                    as={RouterLink}
                    to={"/user/" + recipe.attributes.author.data.attributes.username}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      // Added flex property to make the username shrink
                      flex: "1 1 auto",
                      // Allow the container to shrink
                      minWidth: 0,
                      // Hide overflowing content
                      overflow: "hidden",
                      textDecoration: "none",
                    }}>
                    <Avatar
                      alt={recipe?.attributes?.author?.data?.attributes.username}
                      src={recipe?.attributes?.author?.data?.attributes?.avatar?.data?.attributes.formats.thumbnail.url}
                      sx={{
                        width: 40,
                        height: 40,
                        marginRight: 1,
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        // Allow the container to shrink
                        minWidth: 0,
                        // Hide overflowing content
                        overflow: "hidden",
                      }}>
                      <Typography
                        color="primary"
                        variant="subtitle2"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          marginBottom: 0,
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}>
                        {recipe.attributes.author.data.attributes.username}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="grey"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}>
                        {formatDate(recipe.attributes.createdAt)}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <FollowButton account={recipe.attributes.author.data} />
              </Stack>
              {recipe.attributes.cover && recipe.attributes.cover.data && recipe.attributes.cover.data.attributes && (
                <CardMedia
                  image={recipe.attributes.cover.data.attributes.url}
                  title={recipe.attributes.title}
                  alt={recipe.attributes.title}
                  sx={{
                    width: "100%",
                    aspectRatio: "2/1",
                  }}
                />
              )}
              <CardContent>
                <Typography component="h1" variant="h4" marginBottom={2}>
                  {recipe.attributes.title}
                </Typography>
                <Stack direction="row" alignItems="center" color="grey" spacing={2}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: { md: 14, xs: 12 },
                    }}>
                    {recipe.attributes &&
                      (() => {
                        const prepTime = recipe.attributes.prep_time_hrs * 60 + recipe.attributes.prep_time_mins;
                        const cookTime = recipe.attributes.cook_time_hrs * 60 + recipe.attributes.cook_time_mins;
                        const totalTime = prepTime + cookTime;

                        const formattedTotalTime = formatTime(totalTime);

                        return (
                          <div>
                            Ready in{" "}
                            <Box
                              sx={{
                                display: "inline-block",
                                whiteSpace: "nowrap",
                              }}>
                              {formattedTotalTime}
                            </Box>
                          </div>
                        );
                      })()}
                  </Box>
                  {/* Show difficulty only if data is available */}
                  {recipe.attributes.difficulty && (
                    <Stack direction="row" alignItems="center" color="grey" spacing={2}>
                      <Divider orientation="vertical" flexItem />
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          fontSize: {
                            md: 14,
                            xs: 12,
                          },
                        }}>
                        <div>
                          Difficulty:{" "}
                          <Box
                            sx={{
                              display: "inline-block",
                              whiteSpace: "nowrap",
                            }}>
                            {recipe.attributes.difficulty}
                          </Box>
                        </div>
                      </Box>
                    </Stack>
                  )}
                  {/* Show calories only if data is available */}
                  {recipe.attributes.calories && (
                    <Stack direction="row" alignItems="center" color="grey" spacing={2}>
                      <Divider orientation="vertical" flexItem />
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          fontSize: {
                            md: 14,
                            xs: 12,
                          },
                        }}>
                        <div>
                          <Box
                            sx={{
                              display: "inline-block",
                              whiteSpace: "nowrap",
                            }}>
                            {recipe.attributes.calories}
                          </Box>{" "}
                          Kcal{" "}
                          <Box
                            sx={{
                              display: "inline-block",
                              whiteSpace: "nowrap",
                            }}>
                            per serving
                          </Box>
                        </div>
                      </Box>
                    </Stack>
                  )}
                </Stack>
                {((recipe.attributes.allergies.data && recipe.attributes.allergies.data.length > 0) ||
                  (recipe.attributes.dietaries.data && recipe.attributes.dietaries.data.length > 0) ||
                  (recipe.attributes.cuisines.data && recipe.attributes.cuisines.data.length > 0)) && (
                  <Stack direction="row" sx={{ marginTop: 2, flexWrap: "wrap" }}>
                    {[
                      ...recipe.attributes.allergies.data.map((item) => ({
                        ...item,
                        type: "allergies",
                      })),
                      ...recipe.attributes.dietaries.data.map((item) => ({
                        ...item,
                        type: "dietaries",
                      })),
                      ...recipe.attributes.cuisines.data.map((item) => ({
                        ...item,
                        type: "cuisines",
                      })),
                    ].map((item, index) => (
                      <Chip
                        key={index}
                        label={item.attributes.name}
                        size="small"
                        sx={{
                          color: "gray",
                          marginTop: 0.5,
                          marginRight: 1,
                          marginBottom: 0.5,
                          background: item.type === "allergies" ? red[50] : item.type === "dietaries" ? teal[50] : undefined,
                        }}
                      />
                    ))}
                  </Stack>
                )}
                {recipe.attributes.intro && (
                  <Typography variant="body1" marginTop={2} sx={{ color: "#616161" }}>
                    {recipe.attributes.intro}
                  </Typography>
                )}
              </CardContent>
              <Divider />
              <SocialBar comments={commentsDialog} />
            </Card>
            {userToken && (recipe?.attributes?.premium ? user?.data?.role?.id === 11 : true) && (
              // This code checks three conditions:
              // 1. userToken must exist.
              // 2. If content is premium, then user must have a premium account.
              // 3. If content is not premium, then unlock content
              <Box>
                <Card variant="outlined" sx={{ marginBottom: 4 }}>
                  <CardContent>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="h6">Ingredients</Typography>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="subtitle2">Serving(s)</Typography>
                        <TextField name="servings" type="number" value={servings} onChange={handleServings} size="small" sx={{ width: 88 }} />
                      </Stack>
                    </Stack>
                    <Box>
                      <List
                        sx={{
                          listStyleType: "disc",
                          pl: 2,
                          color: "#616161",
                          width: "100%",
                        }}>
                        <Grid container>
                          {recipe.attributes.ingredients?.map((item, index) => (
                            <Grid item md={12 / 3} sm={12 / 2} xs={12 / 1} key={index}>
                              <ListItem
                                sx={{
                                  display: "list-item",
                                  pl: 0,
                                }}>
                                <ListItemText primary={`${item.quantity * servings} (${item.unit}) ${item.ingredient}`} />
                              </ListItem>
                            </Grid>
                          ))}
                        </Grid>
                      </List>
                    </Box>
                  </CardContent>
                </Card>
                <Card variant="outlined" sx={{ marginBottom: 4 }}>
                  <CardContent>
                    <Typography variant="h6">Cooking Steps</Typography>
                    <Box>
                      <List
                        sx={{
                          color: "#616161",
                          width: "100%",
                        }}>
                        {recipe.attributes.cooking_steps?.map((item, index) => (
                          <React.Fragment key={index}>
                            <ListItem
                              alignItems="flex-start"
                              sx={{
                                paddingLeft: 0,
                              }}>
                              <ListItemAvatar>
                                <Avatar
                                  sx={{
                                    color: teal[500],
                                    bgcolor: teal[50],
                                    width: 32,
                                    height: 32,
                                  }}>
                                  {index + 1}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={item.step} />
                            </ListItem>
                            {/* {index !== recipe.attributes.cooking_steps.length - 1 ? <Divider /> : null} */}
                          </React.Fragment>
                        ))}
                      </List>
                    </Box>
                  </CardContent>
                </Card>
                {recipe.attributes.cooking_tips && (
                  <Card variant="outlined" sx={{ marginBottom: 4 }}>
                    <CardContent>
                      <Typography variant="h6" marginBottom={2}>
                        Cooking Tips
                      </Typography>
                      <Typography variant="body1" color="#616161">
                        {recipe.attributes.cooking_tips}
                      </Typography>
                    </CardContent>
                  </Card>
                )}
                {recipe.attributes.authors_note && (
                  <Card variant="outlined" sx={{ marginBottom: 4 }}>
                    <CardContent>
                      <Typography variant="h6" marginBottom={2}>
                        Author's Note
                      </Typography>
                      <Typography variant="body1" color="#616161">
                        {recipe.attributes.authors_note}
                      </Typography>
                    </CardContent>
                  </Card>
                )}
                <Card variant="outlined" sx={{ display: "flex" }}>
                  <Comments />
                </Card>
              </Box>
            )}
            {!userToken && (
              <Card
                variant="outlined"
                sx={{
                  padding: { md: 2, xs: 0 },
                  marginBottom: 4,
                }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}>
                  <Box
                    marginBottom={1}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                    <AutoAwesomeIcon color="primary" sx={{ marginRight: 1 }} />
                    <Typography variant="h6" sx={{ fontSize: { xs: 18 } }}>
                      Free account. Endless dishes.
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    marginBottom={2}
                    sx={{
                      color: "#616161",
                      textAlign: "center",
                    }}>
                    Discover and share delicious recipes.
                    <br /> Plus, receive exclusive newsletters.
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Button component={RouterLink} to="/signin" variant="outlined" sx={{ whiteSpace: "nowrap" }}>
                      Sign in
                    </Button>
                    <Button component={RouterLink} to="/signup" variant="contained" disableElevation sx={{ whiteSpace: "nowrap" }}>
                      Create Free Account
                    </Button>
                  </Stack>
                </CardContent>
              </Card>
            )}
          </Grid>
          <RelatedRecipes />
        </Grid>
        {userToken && (recipe?.attributes?.premium ? user?.data?.role?.id === 11 : true) && (
          <Dialog open={openComments} TransitionComponent={SlideUp} keepMounted onClose={closeComments} fullWidth maxWidth="sm" aria-describedby="alert-dialog-slide-description">
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "end",
                padding: 2,
                marginBottom: -8,
              }}>
              <IconButton aria-label="close" onClick={closeComments}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Comments />
          </Dialog>
        )}
      </Container>
    </>
  );
}
