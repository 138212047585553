import { fetchRecipesStart, fetchRecipesSuccess, fetchRecipesFailure } from "../reducers/recipesReducer";
import Cookies from "js-cookie";
import axios from "axios";

export const fetchRelatedRecipes = (recipeId, recipeName, recipeAuthor, recipeCuisines) => (dispatch) => {
  // Dispatch the start action
  dispatch(fetchRecipesStart());

  // Get the user token
  const userToken = Cookies.get("session-cookie");

  // Prepare API URL
  let apiUrl = `${process.env.REACT_APP_API_HOSTNAME}/api/recipes?populate=*&pagination[start]=0&pagination[limit]=10`;

  // Set the sequence number count to 1
  let sequenceNumber = 0;

  let idFilters = "";
  const filters = [];

  // Exclude current recipe
  if (recipeId) {
    idFilters = `&filters[$and][0][id][$ne]=${recipeId}`;
  }

  // Add title filters
  if (recipeName) {
    // Remove non-alphanumeric characters
    const sanitizedName = recipeName.replace(/[^a-zA-Z0-9\s]/g, "");
    const nameFilters = sanitizedName
      .split(/\s+/)
      .map((word) => `[title][$containsi]=${encodeURIComponent(word.toLowerCase())}`);
    filters.push(...nameFilters);
  }

  // Add author filter
  if (recipeAuthor) {
    filters.push(`[author][username][$eq]=${encodeURIComponent(recipeAuthor)}`);
  }

  // Add cuisines filters
  if (recipeCuisines && recipeCuisines.length > 0) {
    const cuisinesFilters = recipeCuisines.map((cuisine) => `[cuisines][id][$eq]=${cuisine}`);
    filters.push(...cuisinesFilters); // Using spread operator to flatten
  }

  // Add a sequence number to each filter
  sequenceNumber++;
  const params = filters.map((filter, index) => `&filters[$or][${sequenceNumber + index}]${filter}`).join("");

  apiUrl += idFilters + params;

  // API call
  axios
    .get(apiUrl, {
      headers: userToken ? { Authorization: `Bearer ${userToken}` } : {},
    })
    .then((response) => {
      // Dispatch the success action
      dispatch(fetchRecipesSuccess(response.data.data));
    })
    .catch((error) => {
      // Dispatch the error action
      dispatch(fetchRecipesFailure(error.message));
    });
};
