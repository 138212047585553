import { combineReducers } from "redux";
import userReducer from "./userReducer";
import usersReducer from "./usersReducer";
import passwordReducer from "./passwordReducer";
import profileReducer from "./profileReducer";
import followReducer from "./followReducer";
import likesReducer from "./likesReducer";
import notificationsReducer from "./notificationsReducer";
import recipesReducer from "./recipesReducer";
import recipeReducer from "./recipeReducer";
import commentsReducer from "./commentsReducer";
import replyReducer from "./replyReducer";
import postReducer from "./postReducer";
import allergiesReducer from "./allergiesReducer";
import dietariesReducer from "./dietariesReducer";
import cuisinesReducer from "./cuisinesReducer";

const rootReducer = combineReducers({
  user: userReducer,
  users: usersReducer,
  password: passwordReducer,
  profile: profileReducer,
  follow: followReducer,
  likes: likesReducer,
  notifications: notificationsReducer,
  recipes: recipesReducer,
  recipe: recipeReducer,
  comments: commentsReducer,
  reply: replyReducer,
  post: postReducer,
  allergies: allergiesReducer,
  dietaries: dietariesReducer,
  cuisines: cuisinesReducer,
});

export default rootReducer;
