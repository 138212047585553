import { createSlice } from "@reduxjs/toolkit";

const dietariesSlice = createSlice({
  name: "dietaries",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    fetchDietariesStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchDietariesSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.id = action.payload.id;
    },
    fetchDietariesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchDietariesStart, fetchDietariesSuccess, fetchDietariesFailure } = dietariesSlice.actions;

export default dietariesSlice.reducer;
