// React
import * as React from "react";
// State
import { useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
// UI
import { Box, BottomNavigation, BottomNavigationAction } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";

export default function BottomNav() {
  // Get curret URL location
  const location = useLocation();

  // Set navigation values (null by default)
  const [value, setValue] = React.useState(null);

  // Set bottom navigation based on URL path
  React.useEffect(() => {
    switch (location.pathname) {
      case "/recipes":
        setValue(0);
        break;
      case "/edit-recipe":
        setValue(1);
        break;
      case "/saved":
        setValue(2);
        break;
      default:
        setValue(null);
        break;
    }
  }, [location]);

  // Switch navigation icons
  const renderIcon = (index) => {
    switch (index) {
      case 0:
        return value === 0 ? <HomeRoundedIcon /> : <HomeOutlinedIcon />;
      case 1:
        return value === 1 ? <AddCircleRoundedIcon /> : <AddCircleOutlineOutlinedIcon />;
      case 2:
        return value === 2 ? <BookmarkOutlinedIcon /> : <BookmarkBorderOutlinedIcon />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        position: "sticky",
        bottom: 0,
        width: "100%",
        borderTop: 1,
        borderColor: "grey.300",
        display: { xs: "block", sm: "none" },
      }}>
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}>
        <BottomNavigationAction label="Home" icon={renderIcon(0)} component={RouterLink} to="/" />
        <BottomNavigationAction label="Create" icon={renderIcon(1)} component={RouterLink} to="/edit-recipe" />
        <BottomNavigationAction label="Saved" icon={renderIcon(2)} component={RouterLink} to="/saved" />
      </BottomNavigation>
    </Box>
  );
}
