import {
  addPostStart,
  addPostSuccess,
  addPostFailure,
  editPostStart,
  editPostSuccess,
  editPostFailure,
  deletePostStart,
  deletePostSuccess,
  deletePostFailure,
} from "../reducers/postReducer";
import Cookies from "js-cookie";
import axios from "axios";

// Create post
export const createPost = (postData, files, navigate) => (dispatch) => {
  // Dispatch the start action
  dispatch(addPostStart());

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // Prepare files
  const formData = new FormData();
  formData.append("files", files);

  // Upload files first
  axios
    .post(`${process.env.REACT_APP_API_HOSTNAME}/api/upload`, formData, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
    .then((response) => {
      // Get media id
      const mediaId = response.data[0].id;

      // Set data for API
      let dataForApi = [];

      if (mediaId) {
        // Prepare data for API
        dataForApi = {
          data: {
            cover: mediaId,
            author: postData.author,
            title: postData.title,
            intro: postData.intro,
            prep_time_hours: postData.prep_time_hours,
            prep_time_mins: postData.prep_time_mins,
            cook_time_hours: postData.cook_time_hours,
            cook_time_mins: postData.cook_time_mins,
            difficulty: postData.difficulty,
            calories: postData.calories,
            allergies: postData.allergies,
            dietaries: postData.dietaries,
            cuisines: postData.cuisines,
            ingredients: postData.ingredients,
            cooking_steps: postData.cooking_steps,
            cooking_tips: postData.cooking_tips,
            authors_note: postData.authors_note,
          },
        };
      }

      // Add all data to new post
      axios
        .post(`${process.env.REACT_APP_API_HOSTNAME}/api/recipes`, dataForApi, {
          headers: { Authorization: `Bearer ${userToken}` },
        })
        .then((response) => {
          // Dispatch the success action
          dispatch(addPostSuccess(response.data));
          // Redirect user to new recipe
          if (response.data.data.attributes.slug) {
            navigate(`/recipes/${response.data.data.attributes.slug}`);
          }
        })
        .catch((error) => {
          // Dispatch the error action
          dispatch(addPostFailure(error.response.data.error));
        });
    })
    // File upload error
    .catch((error) => {
      // Dispatch the error action
      dispatch(addPostFailure(error.response.data.error));
    });
};

// Edit post
export const editPost = (recipeId, postData, files, navigate) => (dispatch) => {
  // Dispatch the start action
  dispatch(editPostStart());

  // Get user token
  const userToken = Cookies.get("session-cookie");

  if (files) {
    // Prepare files
    const formData = new FormData();
    formData.append("files", files);

    // Upload files first
    axios
      .post(`${process.env.REACT_APP_API_HOSTNAME}/api/upload`, formData, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        // Get media id
        const mediaId = response.data[0].id;

        // Set data for API
        let dataForApi = [];

        if (mediaId) {
          // Prepare data for API
          dataForApi = {
            data: {
              cover: mediaId,
              author: postData.author,
              title: postData.title,
              intro: postData.intro,
              prep_time_hours: postData.prep_time_hours,
              prep_time_mins: postData.prep_time_mins,
              cook_time_hours: postData.cook_time_hours,
              cook_time_mins: postData.cook_time_mins,
              difficulty: postData.difficulty,
              calories: postData.calories,
              allergies: postData.allergies,
              dietaries: postData.dietaries,
              cuisines: postData.cuisines,
              ingredients: postData.ingredients,
              cooking_steps: postData.cooking_steps,
              cooking_tips: postData.cooking_tips,
              authors_note: postData.authors_note,
            },
          };
        }

        // Add all data to existing post
        axios
          .put(`${process.env.REACT_APP_API_HOSTNAME}/api/recipes/${recipeId}`, dataForApi, {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          })
          .then((response) => {
            // Dispatch the success action
            dispatch(editPostSuccess(response.data));
            // Redirect user to edited recipe
            if (response.data.data.attributes.slug) {
              navigate(`/recipes/${response.data.data.attributes.slug}`);
            }
          })
          .catch((error) => {
            // Dispatch the error action
            dispatch(editPostFailure(error));
          });
      })
      // File upload error
      .catch((error) => {
        // Dispatch the error action
        dispatch(editPostFailure(error.response.data.error));
      });
  } else {
    // Set data for API
    let dataForApi = [];

    dataForApi = {
      // Prepare data for API
      data: {
        title: postData.title,
        intro: postData.intro,
        prep_time_hours: postData.prep_time_hours,
        prep_time_mins: postData.prep_time_mins,
        cook_time_hours: postData.cook_time_hours,
        cook_time_mins: postData.cook_time_mins,
        difficulty: postData.difficulty,
        calories: postData.calories,
        allergies: postData.allergies,
        dietaries: postData.dietaries,
        cuisines: postData.cuisines,
        ingredients: postData.ingredients,
        cooking_steps: postData.cooking_steps,
        cooking_tips: postData.cooking_tips,
        authors_note: postData.authors_note,
      },
    };

    // Add data to existing post
    axios
      .put(`${process.env.REACT_APP_API_HOSTNAME}/api/recipes/${recipeId}`, dataForApi, {
        headers: { Authorization: `Bearer ${userToken}` },
      })
      .then((response) => {
        // Dispatch the success action
        dispatch(editPostSuccess(response.data));
        // Redirect user to edited recipe
        if (response.data.data.attributes.slug) {
          navigate(`/recipes/${response.data.data.attributes.slug}`);
        }
      })
      .catch((error) => {
        // Dispatch the error action
        dispatch(editPostFailure(error.response.data.error));
      });
  }
};

// Delete post
export const deletePost = (postId) => (dispatch) => {
  // Dispatch start
  dispatch(deletePostStart());

  // Get user token
  const userToken = Cookies.get("session-cookie");

  axios
    .delete(`${process.env.REACT_APP_API_HOSTNAME}/api/recipes/${postId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    })
    .then((response) => {
      // Dispatch the success action
      dispatch(deletePostSuccess(response.data));
      // Refresh the page after successful delete
      window.location.reload();
    })
    .catch((error) => {
      // Dispatch the error actions
      dispatch(deletePostFailure(error.response.data.error));
    });
};
