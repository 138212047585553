// React
import React, { useState, useEffect } from "react";
// State
import { useSelector, useDispatch } from "react-redux";
import { fetchUsers } from "../../actions/usersActions";
import axios from "axios";
// Libraries
import { Helmet } from "react-helmet";
// UI
import {
  Container,
  Box,
  Card,
  Typography,
  InputLabel,
  TextField,
  OutlinedInput,
  Checkbox,
  Link,
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function RegisterUser() {
  // Set dispatch
  const dispatch = useDispatch();

  // Set variables
  const [username, setUsername] = useState("");
  const users = useSelector((state) => state.users.data);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userError, setUserError] = useState("");
  const [formValidation, setFormValidation] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  // Show password
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    // Define a regex pattern for username validation
    const usernamePattern = /^[a-z0-9_.]+$/;

    // Check if username is taken for username validation
    const isUsernameTaken = Array.isArray(users) && users.some((u) => u.username === username);

    // Validate the username
    if (username && !usernamePattern.test(username)) {
      setUserError('Invalid username format. Use lowercase letters, numbers, ".", or "_". No spaces allowed.');
    } else if (isUsernameTaken) {
      setUserError("Sorry. This username is already taken. Please try again.");
    } else {
      setUserError("");
    }

    // Set form validation based on errors
    if (!userError && email && password) {
      setFormValidation(true);
    } else {
      setFormValidation(false);
    }
  }, [username, users, userError, email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Form Validation
    await document.getElementById("join-dishly").checkValidity();
    // API Call
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_HOSTNAME}/api/auth/local/register`, {
        username,
        email,
        password,
      });
      // Success
      setConfirmation(true);
      return response.data;
      // Error
    } catch (error) {
      if (error.response?.data?.error?.message) {
        setApiError(error.response?.data?.error?.message);
      } else {
        setApiError(error.message);
      }
    }
  };

  // Terms & Conditions Dialog
  const [open, setOpen] = React.useState(false);
  const handleOpenDialog = (event) => {
    // Prevent the default behavior (e.g., form submission)
    event.preventDefault();
    // Open the terms and conditions dialog
    setOpen(true);
  };

  // Fetch users
  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{`Sign up - Dishly`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Free account. Endless dishes. Join the community now!" />
        <meta property="og:title" content="Dishly • Sign up" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Free account. Endless dishes. Join the community now!" />
      </Helmet>
      <Container maxWidth="sm">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <Card variant="outlined" sx={{ width: "100%", maxWidth: "400px", padding: 4 }}>
            {!confirmation ? (
              <Box>
                <Typography variant="h5" marginBottom={3} sx={{ textAlign: "center" }}>
                  Join Dishly
                </Typography>
                <form id="join-dishly" onSubmit={handleSubmit}>
                  <InputLabel htmlFor="username" sx={{ marginBottom: 1 }}>
                    Username
                  </InputLabel>
                  <TextField
                    variant="outlined"
                    type="text"
                    id="username"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    error={userError}
                    helperText={userError}
                    sx={{ width: "100%", marginBottom: 3 }}
                    required
                  />
                  <InputLabel htmlFor="email" sx={{ marginBottom: 1 }}>
                    E-mail
                  </InputLabel>
                  <TextField variant="outlined" type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} sx={{ width: "100%", marginBottom: 3 }} required />
                  <InputLabel htmlFor="password" sx={{ marginBottom: 1 }}>
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="password"
                    name="password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{
                      style: { WebkitBoxShadow: "0 0 0 1000px #fff inset" }, // Use the desired background color
                    }}
                    sx={{
                      width: "100%",
                      marginBottom: 3,
                    }}
                    required
                  />
                  <Box display="flex" alignItems="center" marginBottom={3}>
                    <Checkbox id="consent" required />
                    <InputLabel
                      htmlFor="consent"
                      margin="dense"
                      sx={{
                        display: "inline-block",
                        whiteSpace: "unset",
                        lineHeight: "1",
                      }}>
                      <Typography variant="caption">
                        Check to indicate that you agree to{" "}
                        <Link component="button" onClick={handleOpenDialog}>
                          Dishly's T&C
                        </Link>
                        .
                      </Typography>
                    </InputLabel>
                  </Box>
                  {apiError && (
                    <Alert severity="error" sx={{ marginBottom: 3 }}>
                      {apiError}
                    </Alert>
                  )}
                  <Button disabled={!formValidation} variant="contained" size="large" disableElevation type="submit" sx={{ width: "100%" }}>
                    Create account
                  </Button>
                </form>
              </Box>
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <Typography variant="h4" marginBottom={2}>
                  Welcome to Dishly! 🎉
                </Typography>
                <Typography variant="h6" marginBottom={2}>
                  Please verify your email address
                </Typography>
                <Typography variant="body1" sx={{ color: "#616161" }}>
                  Thanks for signing up. To start using your account, click on the verification link we've sent to <strong>{email}</strong>
                </Typography>
              </Box>
            )}
          </Card>
        </Box>
      </Container>
      <Dialog
        open={open}
        onClick={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Dishly Terms and Conditions</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              1. Parties
            </Typography>
            <Typography variant="body1" sx={{ marginY: 2 }}>
              This agreement is entered into by and between Dishly (referred to as "we," "our," or "us") and the user, hereinafter referred to as "you" or "the subscriber." The subscriber utilizes the
              Dishly web and mobile application and hereby agrees to abide by the terms and conditions outlined herein.
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              2. User Agreement
            </Typography>
            <Typography variant="body1" sx={{ marginY: 2 }}>
              By creating a Dishly account, you acknowledge and agree to the following terms and conditions set forth in this agreement. If you do not agree to these terms, please refrain from using
              Dishly.
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              3. Prohibited Usage
            </Typography>
            <Typography variant="body1" sx={{ marginY: 2 }}>
              You agree not to engage in any prohibited usage of the Dishly app, including but not limited to:
            </Typography>
            <ul>
              <li>Violating local laws and regulations.</li>
              <li>Engaging in spam activities or any form of unauthorized advertising.</li>
              <li>Collecting data or user information without explicit consent.</li>
              <li>Unauthorized editing or modification of Dishly's software.</li>
              <li>Infringing on any person or company's copyright.</li>
              <li>Sharing child pornography, explicit content, hate speech, racist comments, or threats to other users.</li>
              <li>Creating fake or bot accounts.</li>
              <li>Posting non-food-related content; such content will be removed from the platform.</li>
              <li>Engaging in fraudulent activities or attempting to deceive other users.</li>
              <li>Impersonating individuals or entities.</li>
              <li>Violating the intellectual property rights of Dishly or any third party.</li>
              <li>Distributing malware, viruses, or any harmful software.</li>
              <li>Attempting to access or interfere with other users' accounts without authorization.</li>
              <li>Engaging in activities that may compromise the security or integrity of Dishly's systems.</li>
            </ul>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              4. Privacy
            </Typography>
            <Typography variant="body1" sx={{ marginY: 2 }}>
              Dishly is committed to safeguarding user privacy. We do not share users' data with third parties for advertising purposes. Dishly utilizes user data solely for providing access to the
              app, enhancing user experience, and delivering relevant content within the Dishly app.
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              5. Allergies and Dietary Restrictions
            </Typography>
            <Typography variant="body1" sx={{ marginY: 2 }}>
              Dishly serves as a platform for users to create and share content, including recipes. It is crucial to note that Dishly is not the author of recipes shared by users. Dishly is not
              responsible if a user fails to indicate the presence of allergens in their recipe or incorrectly tags the dietary restrictions of a recipe, such as labeling it as vegan when it is, in
              reality, vegetarian.
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              6. Intellectual Property
            </Typography>
            <Typography variant="body1" sx={{ marginY: 2 }}>
              All intellectual property associated with Dishly, including names, images, symbols, designs, and other creations, is the exclusive property of Dishly. Users are prohibited from copying,
              reproducing, or using intellectual property without explicit authorization from Dishly.
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              7. Termination
            </Typography>
            <Typography variant="body1" sx={{ marginY: 2 }}>
              Failure to comply with the terms and conditions outlined in this agreement may result in the termination of your Dishly account. Dishly reserves the right to terminate accounts for any
              violation of acceptable usage or failure to adhere to the terms and conditions stipulated herein.
            </Typography>
            <Typography variant="body1" sx={{ marginY: 2 }}>
              By using Dishly, you acknowledge that you have read, understood, and agreed to these terms and conditions. Dishly reserves the right to modify these terms at any time, and it is the
              user's responsibility to stay informed about any updates.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
