import { createSlice } from "@reduxjs/toolkit";

const commentsSlice = createSlice({
  name: "comments",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    addCommentStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    addCommentSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.id = action.payload.id;
    },
    addCommentFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteCommentStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    deleteCommentSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.id = action.payload.id;
    },
    deleteCommentFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  addCommentStart,
  addCommentSuccess,
  addCommentFailure,
  deleteCommentStart,
  deleteCommentSuccess,
  deleteCommentFailure,
} = commentsSlice.actions;

export default commentsSlice.reducer;
