// React
import React, { useState, useEffect } from "react";
// State
import { useSelector, useDispatch } from "react-redux";
import { fetchUsers } from "../../actions/usersActions";
import { updateProfile } from "../../actions/profileActions";
// Libraries
import heic2any from "heic2any";
// UI
import {
  Box,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import SlideUp from "../ui/Transitions";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export default function EditProfile({ profileDialog, setProfileDialog }) {
  // Set dispatch
  const dispatch = useDispatch();

  // Prepare user data
  const user = useSelector((state) => state.user);
  const users = useSelector((state) => state.users.data);

  // Edit Profile Dialog
  const [editDialog, setOpen] = React.useState(false);

  const openEdit = () => {
    setOpen(true);
  };

  const closeEdit = () => {
    setOpen(false);
    resetFieldValues();
    // Reset the state in the parent component
    setProfileDialog(false);
  };

  // Profile Image
  const [files, setFiles] = useState();
  const [filePreview, setFilePreview] = useState(null);
  const [previewLoading, setPreviewLoading] = useState(false);

  // Async function to handle file change
  const handleFileChange = async (event) => {
    // Remove preview
    setFilePreview(null);

    // Set loading state to true
    setPreviewLoading(true);

    // Get the selected file
    const selectedFile = event.target.files[0];

    // Set postFile to null by default
    let postFile = null;

    // Check if the selected file is HEIC
    if (selectedFile.type === "image/heic") {
      // Get image as blob URL
      const blobURL = URL.createObjectURL(selectedFile);

      // Convert "fetch" the new blob URL
      const blobRes = await fetch(blobURL);

      // Convert response to blob
      const blob = await blobRes.blob();

      // Convert HEIC to JPG
      postFile = await heic2any({
        blob,
        toType: "image/jpeg",
        quality: 0.5,
      });
    } else {
      // Default to the original file
      postFile = selectedFile;
    }

    // Set loading state to false
    setPreviewLoading(false);

    // Set file
    setFiles(postFile);

    // Set preview
    setFilePreview(URL.createObjectURL(postFile));
  };

  // User Fields
  const [fieldValues, setFieldValues] = useState({
    username: user.data.username,
    name: user.data.name,
    bio: user.data.bio,
  });

  // Pre-validate form fields
  const [formValidation, setFormValidation] = useState(true);
  const [usernameError, setUsernameError] = useState("");

  useEffect(() => {
    // Check if form values are different from initial values
    const isChanged =
      files ||
      fieldValues.username !== user.data.username ||
      fieldValues.name !== user.data.name ||
      fieldValues.bio !== user.data.bio;

    // Update the state to enable/disable the submit button
    //setFormChanged(isChanged);

    // Define a regex pattern for username validation
    const usernamePattern = /^[a-z0-9_.]+$/;

    // Check if the new username already exists in the users array
    const isUsernameTaken =
      Array.isArray(users) &&
      users.some((u) => u.username === fieldValues.username && u.username !== user.data.username);

    // Validate the username
    if (!fieldValues.username) {
      setUsernameError("Username is required");
    } else if (fieldValues.username && !usernamePattern.test(fieldValues.username)) {
      setUsernameError('Invalid username format. Use lowercase letters, numbers, ".", or "_". No spaces allowed.');
    } else if (isUsernameTaken) {
      setUsernameError("Sorry. This username is already taken. Please try again.");
    } else {
      setUsernameError("");
    }

    // Set form validation based on errors
    if (isChanged && !usernameError) {
      setFormValidation(false);
    } else {
      setFormValidation(true);
    }
  }, [files, fieldValues, user, users, usernameError]);

  // Handle form submission
  const handleEdit = async (event) => {
    event.preventDefault();

    // Form Validation
    await document.getElementById("edit-profile").checkValidity();

    // Create a copy of fieldValues
    const userData = { ...fieldValues };

    // Send post data to API
    await dispatch(updateProfile(userData, files));

    // Close dialog
    // Note: Dialog will close until dispatch(createPost) is done :)
    setOpen(false);

    // Reset form fields
    resetFieldValues();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFieldValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const resetFieldValues = () => {
    setFieldValues({
      username: user.data.username,
      name: user.data.name,
      bio: user.data.bio,
    });
  };

  // Launch dialog from parent
  useEffect(() => {
    if (profileDialog) {
      setOpen(true);
    }
  }, [profileDialog]);

  // Set the initial image preview if it exists
  useEffect(() => {
    if (user?.data?.avatar?.formats?.medium) {
      setFilePreview(user?.data?.avatar?.formats?.medium?.url);
    } else if (user?.data?.avatar?.formats?.small) {
      setFilePreview(user?.data?.avatar?.formats?.small?.url);
    }
  }, [user]);

  // Fetch users
  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <>
      <Button variant="contained" disableElevation onClick={openEdit} startIcon={<EditOutlinedIcon />}>
        Edit Profile
      </Button>
      <Dialog
        component="form"
        id="edit-profile"
        onSubmit={handleEdit}
        open={editDialog}
        TransitionComponent={SlideUp}
        onClose={closeEdit}
        fullWidth
        maxWidth="xs"
        aria-describedby="edit-profile-dialog">
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            <Box>
              <Typography variant="subtitle2" marginBottom={1}>
                Profile Image
              </Typography>
              {previewLoading && (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    aspectRatio: "2/1",
                    objectFit: "cover", // Hide excess content
                  }}>
                  <CircularProgress />
                </Box>
              )}
              {filePreview && (
                <Box
                  component="img"
                  src={filePreview}
                  style={{
                    width: "100%",
                    aspectRatio: "1/1",
                    objectFit: "cover", // Hide excess content
                    borderRadius: "50%",
                  }}
                />
              )}
              <input name="files" type="file" accept="image/*, .HEIC" onChange={handleFileChange} required={filePreview === null}  />
            </Box>
            <Box>
              <Typography variant="subtitle2" marginBottom={1}>
                Username
              </Typography>
              <TextField
                name="username"
                value={fieldValues.username}
                onChange={handleChange}
                error={usernameError}
                helperText={usernameError}
                variant="outlined"
                fullWidth
                required
              />
            </Box>
            <Box>
              <Stack direction="row" spacing={0.5} marginBottom={1} sx={{ alignItems: "center" }}>
                <Typography variant="subtitle2">Name</Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: "grey",
                  }}>
                  optional
                </Typography>
              </Stack>
              <TextField
                name="name"
                value={fieldValues.name}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                required
              />
            </Box>
            <Box>
              <Stack direction="row" spacing={0.5} marginBottom={1} sx={{ alignItems: "center" }}>
                <Typography variant="subtitle2">Bio</Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: "grey",
                  }}>
                  optional
                </Typography>
              </Stack>
              <TextField name="bio" value={fieldValues.bio} onChange={handleChange} variant="outlined" fullWidth />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button onClick={closeEdit} variant="outlined" sx={{ whiteSpace: "nowrap" }}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={formValidation}
            variant="contained"
            disableElevation
            sx={{ whiteSpace: "nowrap" }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
