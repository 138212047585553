import { createSlice } from "@reduxjs/toolkit";

const recipesSlice = createSlice({
  name: "recipes",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    fetchRecipesStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchRecipesSuccess: (state, action) => {
      // Set loading to false
      state.isLoading = false;
      // API reponse
      const recipes = action.payload;
      // Check if any of the recipes already exist in the data array
      const existingIds = state.data.map((recipe) => recipe.id);
      const existingItems = recipes.filter((recipe) => existingIds.includes(recipe.id));
      if (existingItems.length > 0) {
        // Repeated pagination bulk - replace existing data with the new data
        state.data = recipes;
      } else {
        // New pagination bulk - simply add new items to the data array
        state.data.push(...recipes);
      }
    },
    fetchRecipesFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    clearRecipes: (state) => {
      state.data = [];
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const { fetchRecipesStart, fetchRecipesSuccess, fetchRecipesFailure, clearRecipes } = recipesSlice.actions;

export default recipesSlice.reducer;
