// React
import React, { useState, useEffect } from "react";
// State
import { useSelector, useDispatch } from "react-redux";
import { resetPassword } from "../../actions/passwordActions";
import { Link as RouterLink } from "react-router-dom";
// Libraries
import { Helmet } from "react-helmet";
// UI
import { Container, Stack, Card, Box, Typography, TextField, Alert, Button, Link } from "@mui/material";

export default function ResetPassword() {
  // Set dispatch
  const dispatch = useDispatch();

  // Get password state
  const password = useSelector((state) => state.password);

  // Reset code
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  // Set fields
  const [passwordValues, setPasswordValues] = useState({
    code: code,
    password: "",
    passwordConfirmation: "",
  });

  // Set error and success variables
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [resetSuccess, setResetSuccess] = useState("");
  const [resetError, setResetError] = useState("");

  // Handle changes
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;

    // Update passwordValues
    setPasswordValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    // Clear error if either field is empty
    if (value === "" || passwordValues[name === "password" ? "passwordConfirmation" : "password"] === "") {
      setConfirmPasswordError("");
      return;
    }

    // Check password confirmation
    if (name === "password") {
      const confirmPassword = passwordValues.passwordConfirmation;
      if (confirmPassword !== value) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
    } else if (name === "passwordConfirmation") {
      const password = passwordValues.password;
      if (password !== value) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  // Submit form
  const handleSubmit = (e) => {
    e.preventDefault();
    // Prepare fields
    const passwordFields = {
      code: passwordValues.code,
      password: passwordValues.password,
      passwordConfirmation: passwordValues.passwordConfirmation,
    };
    // API call
    dispatch(resetPassword(passwordFields));
  };

  // API Response
  useEffect(() => {
    // Response handling
    if (password.success) {
      // Success
      setResetError("");
      setResetSuccess("Your password has been changed.");
    } else if (password.error) {
      // Error
      setResetSuccess("");
      setResetError(password.error?.message);
    }
  }, [dispatch, password]);

  return (
    <>
      <Helmet>
        <title>{`Reset Password - Dishly`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Forgot your Dishly password? You can reset it here." />
        <meta property="og:title" content="Dishly • Reset Password" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Forgot your Dishly password? You can reset it here." />
      </Helmet>
      <Container maxWidth="sm">
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <Card variant="outlined" sx={{ width: "100%", maxWidth: "400px", padding: 4 }}>
            <Typography variant="h5" marginBottom={3} sx={{ textAlign: "center" }}>
              Reset Password
            </Typography>
            <Typography variant="body2" marginBottom={3}>
              Enter the email associated with your Dishly account to reset your password.
            </Typography>
            <form onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="subtitle2" marginBottom={1}>
                    New password
                  </Typography>
                  <TextField name="password" type="password" onChange={handlePasswordChange} variant="outlined" fullWidth required />
                </Box>
                <Box>
                  <Typography variant="subtitle2" marginBottom={1}>
                    Confirm new password
                  </Typography>
                  <TextField
                    name="passwordConfirmation"
                    type="password"
                    onChange={handlePasswordChange}
                    error={confirmPasswordError}
                    helperText={confirmPasswordError}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Box>
                {resetSuccess && (
                  <Alert severity="success">
                    {resetSuccess} <br />
                    <Link component={RouterLink} to="/signin">
                      Sign in here
                    </Link>
                  </Alert>
                )}
                {resetError && <Alert severity="error">{resetError}</Alert>}
                <Button
                  type="submit"
                  disabled={!passwordValues.password || !passwordValues.passwordConfirmation || !!confirmPasswordError}
                  disableElevation
                  variant="contained"
                  size="large"
                  sx={{ width: "100%" }}>
                  Reset my password
                </Button>
              </Stack>
            </form>
          </Card>
        </Box>
      </Container>
    </>
  );
}
