// React
import React, { useEffect, useState } from "react";
// State
import { useSelector, useDispatch } from "react-redux";
import { fetchUser, updateUser } from "../../actions/userActions";
import { changePassword } from "../../actions/passwordActions";
import { clearUser } from "../../actions/userActions";
import { Link as RouterLink } from "react-router-dom";
import Cookies from "js-cookie";
// Libraries
import { Helmet } from "react-helmet";
// UI
import { LinearProgress, Grid, Container, Card, Box, Stack, CardContent, Typography, Link, TextField, Button, Alert } from "@mui/material";

export default function Security() {
  // Set dispathch
  const dispatch = useDispatch();

  // Get user token
  const userToken = Cookies.get("session-cookie");

  // Authorization
  useEffect(() => {
    if (!userToken) {
      // Redirect to "/signin"
      window.location.href = "/signin";
    }
  }, [userToken]);

  // Get user state
  const user = useSelector((state) => state.user);

  // Get password state
  const password = useSelector((state) => state.password);

  // Change email
  const [email, setEmail] = useState("");
  const [emailSuccess, setEmailSuccess] = useState("");
  const [emailError, setEmailError] = useState("");

  // Change email form
  const handleEmail = async (event) => {
    event.preventDefault();

    // Form validation
    await document.getElementById("change-email").checkValidity();

    // Send email to API
    const userData = { email };
    dispatch(updateUser(userData));

    if (user.success) {
      // Action was successful
      setEmailSuccess("Your email has been changed. You will be redirected to sign in again using your new credentials.");
      setEmailError("");
      // Redirect to signin
      setTimeout(() => {
        dispatch(clearUser());
        window.location.href = "/signin";
      }, "3000");
    } else if (user.error) {
      // Action failed
      setEmailSuccess("");
      setEmailError(user.error);
    }
    // Reset messages when the component unmounts
    return () => {
      setEmailSuccess("");
      setEmailError("");
    };
  };

  // Handle email field changes
  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
  };

  // Change password
  const [passwordValues, setPasswordValues] = useState({
    currentPassword: "",
    password: "",
    passwordConfirmation: "",
  });
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordSuccess, setPasswordSuccess] = useState("");
  const [passwordError, setPasswordError] = useState("");

  // Handle password field changes
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;

    // Update passwordValues
    setPasswordValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    // Clear error if either field is empty
    if (value === "" || passwordValues[name === "password" ? "passwordConfirmation" : "password"] === "") {
      setConfirmPasswordError("");
      return;
    }

    // Check password confirmation
    if (name === "password") {
      const confirmPassword = passwordValues.passwordConfirmation;
      if (confirmPassword !== value) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
    } else if (name === "passwordConfirmation") {
      const password = passwordValues.password;
      if (password !== value) {
        setConfirmPasswordError("Passwords do not match");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  // Change password form
  const handleSubmit = (e) => {
    e.preventDefault();

    // Prepare fields
    const passwordFields = {
      currentPassword: passwordValues.currentPassword,
      password: passwordValues.password,
      passwordConfirmation: passwordValues.passwordConfirmation,
    };

    // API call
    dispatch(changePassword(passwordFields));
  };

  // Change password API response
  useEffect(() => {
    // Response handling
    if (password.success) {
      // Action was successful
      setPasswordError("");
      setPasswordSuccess("Your password has been changed. You will be redirected to sign in again using your new credentials.");
      // Redirect to signin
      setTimeout(() => {
        dispatch(clearUser());
        window.location.href = "/signin";
      }, 3000);
    } else if (password.error) {
      // Action failed
      setPasswordSuccess("");
      setPasswordError(password.error?.message);
    }
    // Reset messages when the component unmounts
    return () => {
      setPasswordSuccess("");
      setPasswordError("");
    };
  }, [dispatch, password]);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  // Progress bar
  if (!user) {
    return (
      <Box sx={{ flex: 1 }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`Saved Recipes - Dishly`}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Manage your Dishly security settings." />
        <meta property="og:title" content="Dishly • Security" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content="Manage your Dishly security settings." />
      </Helmet>
      {user && (
        <Container maxWidth="xs" sx={{ flex: 1, marginY: 4 }}>
          <Typography variant="h4">Security</Typography>
          <Link component={RouterLink} to={`/user/${user.data.username}`}>
            <Typography variant="h6" marginBottom={4}>
              @{user.data.username}
            </Typography>
          </Link>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <form id="change-email" onSubmit={handleEmail}>
                    <Stack spacing={2} marginBottom={2}>
                      <Typography variant="h6">Change your email</Typography>
                      <Box>
                        <Typography variant="subtitle2">New email address</Typography>
                        <Typography variant="body2" marginBottom={1} sx={{ color: "#616161" }}>
                          Use an address you'll always have access to
                        </Typography>
                        <TextField value={email} onChange={handleEmailChange} variant="outlined" fullWidth required />
                      </Box>
                      {emailSuccess && <Alert severity="success">{emailSuccess}</Alert>}
                      {emailError && <Alert severity="error">{emailError} </Alert>}
                    </Stack>
                    <Stack direction="row" sx={{ justifyContent: "end" }}>
                      <Button type="submit" variant="contained" disableElevation sx={{ whiteSpace: "nowrap" }}>
                        Save
                      </Button>
                    </Stack>
                  </form>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <form id="change-password" onSubmit={handleSubmit}>
                    <Stack spacing={2} marginBottom={2}>
                      <Typography variant="h6">Change your password</Typography>
                      <Box>
                        <Typography variant="subtitle2" marginBottom={1}>
                          Current password
                        </Typography>
                        <TextField name="currentPassword" type="password" onChange={handlePasswordChange} autoComplete="currentPassword" variant="outlined" fullWidth required />
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" marginBottom={1}>
                          New password
                        </Typography>
                        <TextField name="password" type="password" onChange={handlePasswordChange} autoComplete="password" variant="outlined" fullWidth required />
                      </Box>
                      <Box>
                        <Typography variant="subtitle2" marginBottom={1}>
                          Confirm new password
                        </Typography>
                        <TextField
                          name="passwordConfirmation"
                          type="password"
                          onChange={handlePasswordChange}
                          autoComplete="passwordConfirmation"
                          error={!!confirmPasswordError}
                          helperText={confirmPasswordError}
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Box>
                      {passwordSuccess && <Alert severity="success">{passwordSuccess}</Alert>}
                      {passwordError && <Alert severity="error">{passwordError} </Alert>}
                    </Stack>
                    <Stack direction="row" sx={{ justifyContent: "end" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={!passwordValues.currentPassword || !passwordValues.password || !passwordValues.passwordConfirmation || !!confirmPasswordError}
                        disableElevation
                        sx={{ whiteSpace: "nowrap" }}>
                        Save
                      </Button>
                    </Stack>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
